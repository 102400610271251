import axios from "axios";

const ENV = {
  LOCAL: "https://localhost:5001/api",
  QA: "https://membership.api.qa.midenas.rs/api",
  PROD: "https://membership.api.npddd.rs/api",
};

export const api = axios.create({
  baseURL: ENV.PROD,
});
