import {
  Container,
  Grid
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import Header from "../../features/header/Header";
import { AddUpdateMembers } from "../../features/smart-membership/add-update-member/addUpdateMember";
import SelectMemberSection from "../../features/smart-membership/select-member/SelectMemberSection";

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: 0,
    marginTop: 70,
    [theme.breakpoints.down("xs")]: {
      marginTop: "125px",
    },
  },
  grid: {
    marginTop: "40px",
  },
}));

const SmartMembershipPage = () => {
  const { classes } = useStyles();

  return (
    <Container className={classes.container}>
      <Grid>
        <Header />
      </Grid>
      <Grid style={{ marginTop: "20px" }} item xs={12} sm={12}>
        <SelectMemberSection />
        <AddUpdateMembers />
      </Grid>
    </Container>
  );
};

export default SmartMembershipPage;
