import { Container, Grid, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Header from "../../features/header/Header";
import UserManagement from "../../features/user-management/UserManagement";

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: 0,
    marginTop: 70,
    [theme.breakpoints.down("xs")]: {
      marginTop: "125px",
    },
  },
  grid: {
    marginTop: "40px",
  },
}));

const UserManagementPage = () => {
  const { classes } = useStyles();

  return (
    <Container className={classes.container}>
      <Grid>
        <Header />
      </Grid>

      <Grid style={{ marginTop: "20px" }} item xs={12} sm={12}>
        <UserManagement />
      </Grid>
    </Container>
  );
};

export default UserManagementPage;
