import { colors } from "../../../app/theme/colors";
import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useElectionPlaceDetailsModalStyle = makeStyles()(
  (theme: Theme) => ({
    label: {
      textAlign: "start",
      color: colors.sectionTitle,
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    labelInfo: {
      textAlign: "start",
      float: "left",
      color: colors.sectionTitle,
      textTransform: "uppercase",
    },
    dialogTitle: {
      cursor: "pointer",
      background: colors.primary,
    },
    grid: {
      position: "sticky",
      background: colors.primary,
      textAlign: "end",
      border: "none",
      outline: "none",
      color: "white",
    },
  })
);
