import { CSSProperties } from "@mui/material/styles/createMixins";
import spinner from "../assets/spinner.gif";

type Props = {
  style?: CSSProperties;
};

function Spinner(props: Props) {
  return props.style ? (
    <img src={spinner} alt="Loading..." style={{ ...props.style }} />
  ) : (
    <span>
      <img
        src={spinner}
        alt="Loading..."
        style={{ width: "100px", margin: "auto", display: "block" }}
      />
    </span>
  );
}

export default Spinner;
