import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  deleteMember as deleteMemberApi,
  getAvailableCardNumbers as getAvailableCardNumbersApi,
  getMemberBasicDetails as getMemberBasicDetailsApi,
  getMemberDetailsDashboard as getMemberDetailsDashboardApi,
  getMemberFullDetailsById as getMemberFullDetailsByIdApi,
  getNextCardNumber as getNextCardNumberApi,
  getStatistic as getStatisticApi,
  isAvailableCardNumber as isAvailableCardNumberApi,
  saveMember as saveMemberApi,
} from "../../app/redux/npdddApi";
import { RootState } from "../../app/redux/store";
import i18n from "../../i18n";
import { EmptyGuid, INIT_MEMBER } from "../../INITIAL_DATA/initialData";
import { Member } from "../../models/data/members/Member";
import { MemberPersonalData } from "../../models/data/members/MemberPersonalData";
import { MemberDetailsModel } from "../../models/members/memberDetailsModel";
import { FilterMembers } from "../../models/requests/filterMembers";
import { FilterMembersBasicDetails } from "../../models/requests/filterMembersBasicDetails";
import { MemberDataManipulationRequest } from "../../models/requests/members/memberDataManipulationRequest";
import { PagedList } from "../../models/Responses/dataManipulations/pagedList";
import { MemberBasicDetails } from "../../models/Responses/members/memberBasicDetails";
import { MemberDetailsDashboard } from "../../models/Responses/members/memberDetailsDashboard";
import { MemberFullDetails } from "../../models/Responses/members/MemberFullDetails";
import StatisticModel from "../../models/statistic/statisticModel";
import { APIData } from "../../models/types/api/APIData";
import { APIError } from "../../models/types/api/APIError";
import { APIResponse } from "../../models/types/api/APIResponse";
import { APIStatus } from "../../models/types/api/APIStatus";
import { execute, fulfilled, pending, rejected } from "../helpers/sliceHelpers";
import { MemberApi } from "./memberApi";

const INIT_API_DATA = {
  status: APIStatus.IDLE,
};

export type MemberState = {
  membersPagedList: PagedList<MemberDetailsModel> | null;
  memberDetailsDashboard: MemberDetailsDashboard[] | null;

  fetchedMemberBasicDetails: APIData<MemberBasicDetails[]>;
  membersFullDetails: APIData<MemberFullDetails>;

  savedMember: Member | null;
  deletedMember: MemberPersonalData | null;

  isAvailableCardNumber: APIData<boolean>;
  availableCardNumbers: APIData<number[]>;
  nextCardNumber: number | null;
  statistic: StatisticModel | null;

  selectedMember: Member | null;
  membersBasicDetails: MemberBasicDetails[];

  statuses: {
    membersPagedListStatus: string;
    saveMemberStatus: APIStatus;
    deleteMemberStatus: APIStatus;
    getNextCardNumberStatus: APIStatus;
    getMemberDetailsDashboard: APIStatus;
  };
};

const initialState: MemberState = {
  membersPagedList: null,
  memberDetailsDashboard: null,

  fetchedMemberBasicDetails: INIT_API_DATA,
  membersFullDetails: INIT_API_DATA,

  savedMember: null,
  deletedMember: null,

  isAvailableCardNumber: INIT_API_DATA,
  availableCardNumbers: INIT_API_DATA,
  nextCardNumber: null,
  statistic: null,

  selectedMember: null,
  membersBasicDetails: [],

  statuses: {
    membersPagedListStatus: APIStatus.IDLE,
    saveMemberStatus: APIStatus.IDLE,
    deleteMemberStatus: APIStatus.IDLE,
    getNextCardNumberStatus: APIStatus.IDLE,
    getMemberDetailsDashboard: APIStatus.IDLE,
  },
};

export const getMembersPagedList = createAsyncThunk(
  "Member/Get_Members_Paged_List",
  async (request: MemberDataManipulationRequest) => {
    const response = await MemberApi.GetMembersPagedList(request);
    return response.data;
  }
);

export const getMemberDetailsDashboard = createAsyncThunk(
  "Members/Get_Member_Details_Dashboard",
  async (filter: FilterMembers, { rejectWithValue }) => {
    try {
      const response = await getMemberDetailsDashboardApi(filter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error as APIError);
    }
  }
);

export const getMemberBasicDetails = createAsyncThunk<
  APIResponse<MemberBasicDetails[]>,
  FilterMembersBasicDetails,
  { rejectValue: APIError }
>(
  "Members/Get_Member_Basic_Details",
  async (filter: FilterMembersBasicDetails, { rejectWithValue }) =>
    execute(getMemberBasicDetailsApi(filter), rejectWithValue)
);

export const getMemberFullDetailsById = createAsyncThunk<
  APIResponse<Member>,
  string,
  { rejectValue: APIError }
>(
  "Members/Get_Member_Full_Details_By_Id",
  async (id: string, { rejectWithValue }) => {
    if (id !== EmptyGuid) {
      return execute(getMemberFullDetailsByIdApi(id), rejectWithValue);
    } else {
      const apiResponse: APIResponse<Member> = {
        data: INIT_MEMBER,
        httpStatusCode: 200,
        messages: [],
        succeeded: true,
      };
      return apiResponse;
    }
  }
);

export const getIsAvailableCardNumber = createAsyncThunk<
  APIResponse<boolean>,
  { cardNumber: number; memberCardId: string | null },
  { rejectValue: APIError }
>(
  "Members/Is_Available_Card_Number",
  async (
    {
      cardNumber,
      memberCardId,
    }: { cardNumber: number; memberCardId: string | null },
    { rejectWithValue }
  ) =>
    execute(isAvailableCardNumberApi(cardNumber, memberCardId), rejectWithValue)
);

export const getAvailableCardNumbers = createAsyncThunk<
  APIResponse<number[]>,
  void,
  { rejectValue: APIError }
>("Members/Get_Available_Card_Numbers", async (_: void, { rejectWithValue }) =>
  execute(getAvailableCardNumbersApi(), rejectWithValue)
);

export const getNextCardNumber = createAsyncThunk<
  number | null,
  void,
  { rejectValue: APIError }
>("Members/Get_Next_Card_Number", async (_: any, { rejectWithValue }) => {
  try {
    const response = await getNextCardNumberApi();
    return response.data;
  } catch (error) {
    return rejectWithValue(error as APIError);
  }
});

export const resetSelectedMember = createAsyncThunk(
  "Members/Reset_Selected_Member",
  async () => {
    return true;
  }
);

export const getAllStatistic = createAsyncThunk(
  "Members/Get_Statistic",
  async () => {
    const response = await getStatisticApi();
    return response;
  }
);

export const saveMember = createAsyncThunk(
  "Members/Save_Member",
  async (
    { member, type }: { member: Member; type: "create" | "update" },
    { rejectWithValue }
  ) => {
    try {
      const promise = saveMemberApi(member);

      const response = await toast.promise(
        promise,
        {
          pending: `${i18n.t(`notifications.${type}PersonalDataPending`)}...`,
          success: `${i18n.t(`notifications.${type}PersonalDataFulfilled`)}!`,
          error: {
            render({ data }) {
              const exception = data as APIError;
              return `${i18n.t(`errorNotifications.${exception.code}`)}.`;
            },
          },
        },
        {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000,
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error as APIError);
    }
  }
);

export const deleteMember = createAsyncThunk(
  "Members/Delete_Member",
  async (id: string, { rejectWithValue }) => {
    try {
      const promise = deleteMemberApi(id);

      const response = await toast.promise(
        promise,
        {
          pending: `${i18n.t(`notifications.deleteMemberPending`)}...`,
          success: `${i18n.t(`notifications.deleteMemberFulfilled`)}`,
          error: {
            render({ data }) {
              const exception = data as APIError;
              return `${i18n.t(`errorNotifications.${exception.code}`)}.`;
            },
          },
        },
        {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000,
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error as APIError);
    }
  }
);

export const memberSlice = createSlice({
  name: "members",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get Members Paged List
      .addCase(getMembersPagedList.pending, (state) => {
        state.statuses.membersPagedListStatus = APIStatus.PENDING;
      })
      .addCase(getMembersPagedList.fulfilled, (state, action) => {
        state.statuses.membersPagedListStatus = APIStatus.FULFILLED;
        state.membersPagedList = action.payload;
      })
      .addCase(getMembersPagedList.rejected, (state) => {
        state.statuses.membersPagedListStatus = APIStatus.REJECTED;
      })

      .addCase(resetSelectedMember.fulfilled, (state) => {
        state.selectedMember = null;
      })

      // isAvailableCardNumber
      .addCase(getIsAvailableCardNumber.pending, (state) =>
        pending(state.isAvailableCardNumber)
      )
      .addCase(getIsAvailableCardNumber.fulfilled, (state, action) => {
        fulfilled(state.isAvailableCardNumber, action);

        state.isAvailableCardNumber.status = APIStatus.FULFILLED;

        if (state.isAvailableCardNumber.response) {
          state.isAvailableCardNumber.response = action.payload;

          if (
            action.payload.data !== null &&
            action.payload.data !== undefined
          ) {
            state.isAvailableCardNumber.data = action.payload.data;
          }
        }
      })
      .addCase(getIsAvailableCardNumber.rejected, (state, action) =>
        rejected(state.isAvailableCardNumber, action)
      )

      // getAvailableCardNumbers
      .addCase(getAvailableCardNumbers.pending, (state) =>
        pending(state.availableCardNumbers)
      )
      .addCase(getAvailableCardNumbers.fulfilled, (state, action) =>
        fulfilled(state.availableCardNumbers, action)
      )
      .addCase(getAvailableCardNumbers.rejected, (state, action) =>
        rejected(state.availableCardNumbers, action)
      )

      // Get Next Card Number
      .addCase(getNextCardNumber.pending, (state, action) => {
        state.nextCardNumber = null;
        state.statuses.getNextCardNumberStatus = APIStatus.PENDING;
      })
      .addCase(getNextCardNumber.fulfilled, (state, action) => {
        state.nextCardNumber = action.payload;
        state.statuses.getNextCardNumberStatus = APIStatus.FULFILLED;
      })
      .addCase(getNextCardNumber.rejected, (state, action) => {
        state.nextCardNumber = null;
        state.statuses.getNextCardNumberStatus = APIStatus.REJECTED;
      })

      .addCase(getAllStatistic.fulfilled, (state, { payload }) => {
        state.statistic = payload;
      })

      // Get Member Details Dashboard
      .addCase(getMemberDetailsDashboard.pending, (state) => {
        state.memberDetailsDashboard = null;
        state.statuses.getMemberDetailsDashboard = APIStatus.PENDING;
      })
      .addCase(getMemberDetailsDashboard.fulfilled, (state, action) => {
        state.memberDetailsDashboard = action.payload;
        state.statuses.getMemberDetailsDashboard = APIStatus.FULFILLED;
      })
      .addCase(getMemberDetailsDashboard.rejected, (state, action) => {
        state.memberDetailsDashboard = null;
        state.statuses.getMemberDetailsDashboard = APIStatus.REJECTED;
      })

      // getMemberDetailsDashboard
      // .addCase(getMemberDetailsDashboard.pending, (state) =>
      //   pending(state.fetchedMemberDetailsDashboard)
      // )
      // .addCase(getMemberDetailsDashboard.fulfilled, (state, action) =>
      //   fulfilled(state.fetchedMemberDetailsDashboard, action)
      // )
      // .addCase(getMemberDetailsDashboard.rejected, (state, action) =>
      //   rejected(state.fetchedMemberDetailsDashboard, action)
      // )

      // getMemberBasicDetails
      .addCase(getMemberBasicDetails.pending, (state) =>
        pending(state.fetchedMemberBasicDetails)
      )
      .addCase(getMemberBasicDetails.fulfilled, (state, action) => {
        // fulfilled(state.fetchedMemberBasicDetails, action);

        state.membersBasicDetails = action.payload.data || [];
      })
      .addCase(getMemberBasicDetails.rejected, (state, action) =>
        rejected(state.fetchedMemberBasicDetails, action)
      )

      // getMemberFullDetailsById
      .addCase(getMemberFullDetailsById.pending, (state) =>
        pending(state.membersFullDetails)
      )
      .addCase(getMemberFullDetailsById.fulfilled, (state, action) => {
        fulfilled(state.membersFullDetails, action);
        state.selectedMember = action.payload.data;
      })
      .addCase(getMemberFullDetailsById.rejected, (state, action) =>
        rejected(state.membersFullDetails, action)
      )

      // Save Member
      .addCase(saveMember.pending, (state, action) => {
        state.savedMember = null;
        state.statuses.saveMemberStatus = APIStatus.PENDING;
      })
      .addCase(saveMember.fulfilled, (state, action) => {
        state.savedMember = action.payload;
        state.statuses.saveMemberStatus = APIStatus.FULFILLED;
      })
      .addCase(saveMember.rejected, (state, action) => {
        state.savedMember = null;
        state.statuses.saveMemberStatus = APIStatus.REJECTED;
      })

      // Delete Member
      .addCase(deleteMember.pending, (state, action) => {
        state.deletedMember = null;
        state.statuses.deleteMemberStatus = APIStatus.PENDING;
      })
      .addCase(deleteMember.fulfilled, (state, action) => {
        state.deletedMember = action.payload;
        state.statuses.deleteMemberStatus = APIStatus.FULFILLED;
      })
      .addCase(deleteMember.rejected, (state, action) => {
        state.deletedMember = null;
        state.statuses.deleteMemberStatus = APIStatus.REJECTED;
      });
  },
});

export const selectMembersPagedList = (state: RootState) =>
  state.members.membersPagedList;

export const selectMembersPagedListStatus = (state: RootState) =>
  state.members.statuses.membersPagedListStatus;

export const selectStatistic = (state: RootState) => state.members.statistic;

export const selectSavedMember = (state: RootState) =>
  state.members.savedMember;

export const selectSaveMemberStatus = (state: RootState) =>
  state.members.statuses.saveMemberStatus;

export const selectDeleteMemberStatus = (state: RootState) =>
  state.members.statuses.deleteMemberStatus;

export const selectSelectedMember = (state: RootState) =>
  state.members.selectedMember;

export const selectNextCardNumber = (state: RootState) =>
  state.members.nextCardNumber;

export const selectNextCardNumberStatus = (state: RootState) =>
  state.members.statuses.getNextCardNumberStatus;

export const selectMemberDetailsDashboard = (state: RootState) =>
  state.members.memberDetailsDashboard;

export const selectStatusGetMemberDetailsDashboard = (state: RootState) =>
  state.members.statuses.getMemberDetailsDashboard;

export default memberSlice.reducer;
