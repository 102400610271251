import { AnyAction, createAsyncThunk } from "@reduxjs/toolkit";
import { toast, ToastPromiseParams } from "react-toastify";
import { useAppDispatch } from "../../app/redux/hooks";
import i18n from "../../i18n";
import { APIError } from "../../models/types/api/APIError";
import { APIStatus } from "../../models/types/api/APIStatus";

type ArgumentTypes<F extends CallableFunction> = F extends (
  ...args: infer A
) => any
  ? A[0]
  : never;

const withToast = <T = AnyAction | typeof createAsyncThunk>(
  action: T,
  { pending, error, success }: ToastPromiseParams<T>,
  autoClose?: number | false | undefined
) => {
  return (
    dispatch: ReturnType<typeof useAppDispatch>,
    actionParams?: ArgumentTypes<T & CallableFunction> | void
  ) => {
    const promise = dispatch(
      (action as CallableFunction)(actionParams as any)
    ).unwrap();
    toast.promise(
      promise,
      {
        pending,
        error,
        success,
      },
      { position: toast.POSITION.BOTTOM_LEFT, autoClose: autoClose }
    );
  };
};

export const execute = async (
  promise: Promise<any>,
  rejectWithValue: (value: APIError) => any
) => {
  try {
    const response = await promise;
    return response;
  } catch (error) {
    return rejectWithValue(error as APIError);
  }
};

export const executeWithToast = <T = AnyAction | typeof createAsyncThunk>(
  action: T,
  pendingMessage: string,
  successMessage: string,
  autoClose?: number | false | undefined
) => {
  return withToast(
    action,
    {
      pending: pendingMessage,
      error: {
        render: (error: any) => {
          const apiError: APIError = error.data;

          return i18n.t(`errorNotifications.${apiError.code}`);
        },
      },
      success: successMessage,
    },
    autoClose === false ? autoClose : 3000
  );
};

export const pending = (apiData: any) => {
  apiData.status = APIStatus.PENDING;
};

export const fulfilled = (apiData: any, action: any) => {
  apiData.status = APIStatus.FULFILLED;

  apiData.response = action.payload;

  const tempResult = action.payload.data;

  if (tempResult) {
    apiData.data = tempResult;
  }
};

export const rejected = (apiData: any, action: any) => {
  apiData.status = APIStatus.REJECTED;
  apiData.error = action.payload;
};
