import {
  FormControl,
  FormControlLabel,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import InputMask from "react-text-mask";
import BasicInput from "../../../component/input/basic-input/BasicInput";
import { Member } from "../../../models/data/members/Member";
import MemberSectionType from "../../../models/types/memberSectionTypes/memberSectionType";
import { usePersonalDataSectionStyles } from "./personalDataSectionStyles";

interface Props {
  member: Member;
  onChange: any;
}

export const MemberPersonalData = ({ member, onChange }: Props) => {
  const { t } = useTranslation();
  const { classes } = usePersonalDataSectionStyles();

  const [jmbgValue, setJmbgValue] = useState("");

  const formattedDate = () =>
    member.personalData.birthDate &&
    member.personalData.birthDate.substring(0, 10);

  return (
    <Grid container spacing={2}>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.personalDataFirstName")}
          value={member.personalData.firstName}
          onChange={(e) =>
            onChange(
              e.target.value,
              "firstName",
              MemberSectionType.memberPersonalData
            )
          }
          error={
            member.personalData?.firstName !== null
              ? member.personalData.firstName.length < 3
              : true
          }
          errorMessage={t(
            "memberManagementPage.personalDataErrorMessageFirstName"
          )}
        />
      </Grid>

      <Grid item lg={4} md={4} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.personalDataParentName")}
          value={member.personalData.parentName}
          onChange={(e) =>
            onChange(
              e.target.value,
              "parentName",
              MemberSectionType.memberPersonalData
            )
          }
        />
      </Grid>

      <Grid item lg={4} md={4} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.personalDataLastName")}
          value={member.personalData.lastName}
          onChange={(e) =>
            onChange(
              e.target.value,
              "lastName",
              MemberSectionType.memberPersonalData
            )
          }
          error={
            member.personalData?.lastName?.length !== undefined
              ? member.personalData.lastName.length < 3
              : true
          }
          errorMessage={t(
            "memberManagementPage.personalDataErrorMessageLastName"
          )}
        />
      </Grid>

      <Grid item lg={4} md={4} sm={12} xs={12}>
        <BasicInput
          type="date"
          label={t("memberManagementPage.personalDataBirthDate")}
          value={formattedDate()}
          onChange={(e) =>
            onChange(
              e.target.value,
              "birthDate",
              MemberSectionType.memberPersonalData
            )
          }
          error={
            member.personalData.birthDate !== null
              ? member.personalData.birthDate.length < 10
              : true
          }
          errorMessage={t(
            "memberManagementPage.personalDataErrorMessageDateofbirth"
          )}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.personalDataBirthPlace")}
          value={member.personalData.birthPlace}
          onChange={(e) =>
            onChange(
              e.target.value,
              "birthPlace",
              MemberSectionType.memberPersonalData
            )
          }
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.personalDataIdNumber")}
          value={member.personalData.idNumber}
          onChange={(e) =>
            onChange(
              e.target.value,
              "idNumber",
              MemberSectionType.memberPersonalData
            )
          }
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          fullWidth
        >
          <Typography variant="body1" className={classes.inputTitle}>
            {t("memberManagementPage.personalDataUin")}:
          </Typography>
          <OutlinedInput
            autoComplete="off"
            fullWidth
            type={"text"}
            className={
              jmbgValue.length > 0 && jmbgValue.length < 12
                ? classes.inputError
                : classes.input
            }
            value={member.personalData.jmbg || ""}
            placeholder="0 0 0 0 0 0 0 0 0 0 0 0 0"
            onChange={(e) => {
              const value = e.target.value.replace(/\s/g, "").replace(/_/g, "");
              if (value.length == 13 || value.length == 0) {
                onChange(value, "jmbg", MemberSectionType.memberPersonalData);
              }
            }}
            style={{ background: "white" }}
            inputComponent={(props) => (
              <InputMask
                {...props}
                mask={[
                  /\d/,
                  " ",
                  /\d/,
                  " ",
                  /\d/,
                  " ",
                  /\d/,
                  " ",
                  /\d/,
                  " ",
                  /\d/,
                  " ",
                  /\d/,
                  " ",
                  /\d/,
                  " ",
                  /\d/,
                  " ",
                  /\d/,
                  " ",
                  /\d/,
                  " ",
                  /\d/,
                  " ",
                  /\d/,
                ]}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item display="flex" alignItems="end">
        <FormControl>
          <RadioGroup name="gender">
            <Grid container>
              <Grid item>
                <FormControlLabel
                  value={
                    member.personalData.gender !== null &&
                    member.personalData.gender === "Muško"
                      ? true
                      : false
                  }
                  checked={
                    member.personalData.gender !== null &&
                    member.personalData.gender === "Muško"
                      ? true
                      : false
                  }
                  control={
                    <Radio
                      className={classes.radioButton}
                      onClick={(e) =>
                        onChange(
                          "Muško",
                          "gender",
                          MemberSectionType.memberPersonalData
                        )
                      }
                    />
                  }
                  label={
                    <Typography variant="body1" color="textSecondary">
                      {t("memberManagementPage.personalDataMale")}
                    </Typography>
                  }
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 14,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  value={
                    member.personalData.gender !== null &&
                    member.personalData.gender === "Žensko"
                      ? true
                      : false
                  }
                  checked={
                    member.personalData.gender !== null &&
                    member.personalData.gender === "Žensko"
                      ? true
                      : false
                  }
                  control={
                    <Radio
                      className={classes.radioButton}
                      onClick={(e) =>
                        onChange(
                          "Žensko",
                          "gender",
                          MemberSectionType.memberPersonalData
                        )
                      }
                    />
                  }
                  label={
                    <Typography variant="body1" color="textSecondary">
                      {t("memberManagementPage.personalDataFemale")}
                    </Typography>
                  }
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 14,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};
