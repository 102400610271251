import { useTranslation } from "react-i18next";
import { TableHeadNamesType } from "../features/table/data/tableHeadNames";
import { MemberColumnsDto } from "../models/data/members/columns/memberColumnsDto";

type Props = {
  tableHeadNames: TableHeadNamesType;
};

export const useGetColumnsForReport = ({ tableHeadNames }: Props) => {
  const { t } = useTranslation();

  const columnsForDefault: MemberColumnsDto = {
    personalData: {
      firstName: t(tableHeadNames.personalData[0].firstName),
      lastName: t(tableHeadNames.personalData[1].lastName),
      birthDate: t(tableHeadNames.personalData[4].birthDate),
    },
    address: {
      cityResidenceName: t(tableHeadNames.address[3].cityResidenceName),
      streetResidenceName: t(tableHeadNames.address[4].streetResidenceName),
      homeNumberResidence: t(tableHeadNames.address[5].homeNumberResidence),
    },
    contact: {
      mobilePhone: t(tableHeadNames.contact[1].mobilePhone),
    },
    otherData: {
      recommendation: t(tableHeadNames.otherData[3].recommendation),
      note1: t(tableHeadNames.otherData[4].note1),
    },
  };

  const columnsForCity: MemberColumnsDto = {
    personalData: {
      firstName: t(tableHeadNames.personalData[0].firstName),
      lastName: t(tableHeadNames.personalData[1].lastName),
      birthDate: t(tableHeadNames.personalData[4].birthDate),
    },
    address: {
      cityResidenceName: t(tableHeadNames.address[3].cityResidenceName),
      streetResidenceName: t(tableHeadNames.address[4].streetResidenceName),
      homeNumberResidence: t(tableHeadNames.address[5].homeNumberResidence),
    },
    contact: {
      mobilePhone: t(tableHeadNames.contact[1].mobilePhone),
    },
    otherData: {
      recommendation: t(tableHeadNames.otherData[3].recommendation),
      note1: t(tableHeadNames.otherData[4].note1),
    },
  };

  const columnsForLocalCommunity: MemberColumnsDto = {
    personalData: {
      firstName: t(tableHeadNames.personalData[0].firstName),
      lastName: t(tableHeadNames.personalData[1].lastName),
    },
    address: {
      cityResidenceName: t(tableHeadNames.address[3].cityResidenceName),
      streetResidenceName: t(tableHeadNames.address[4].streetResidenceName),
      homeNumberResidence: t(tableHeadNames.address[5].homeNumberResidence),
      localCommunityName: t(tableHeadNames.address[9].localCommunityName),
    },
    contact: {
      mobilePhone: t(tableHeadNames.contact[1].mobilePhone),
    },
    otherData: {
      recommendation: t(tableHeadNames.otherData[3].recommendation),
      note1: t(tableHeadNames.otherData[4].note1),
    },
  };

  const columnsForElectionPlace: MemberColumnsDto = {
    personalData: {
      firstName: t(tableHeadNames.personalData[0].firstName),
      lastName: t(tableHeadNames.personalData[1].lastName),
    },
    address: {
      cityResidenceName: t(tableHeadNames.address[3].cityResidenceName),
      streetResidenceName: t(tableHeadNames.address[4].streetResidenceName),
      homeNumberResidence: t(tableHeadNames.address[5].homeNumberResidence),
      electionPlaceName: t(tableHeadNames.address[7].electionPlaceName),
      electionPlaceAddress: t(tableHeadNames.address[8].electionPlaceAddress),
    },
    contact: {
      mobilePhone: t(tableHeadNames.contact[1].mobilePhone),
    },
    otherData: {
      recommendation: t(tableHeadNames.otherData[3].recommendation),
      note1: t(tableHeadNames.otherData[4].note1),
    },
  };

  return {
    columnsForDefault,
    columnsForCity,
    columnsForLocalCommunity,
    columnsForElectionPlace,
  };
};
