import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RootState } from "../../app/redux/store";
import { EmptyGuid } from "../../features/smart-membership/initialData";
import i18n from "../../i18n";
import { Role } from "../../models/data/accounts/Role";
import { SaveAccountModel } from "../../models/requests/accounts/saveAccountModel";
import { SetAccountStatus } from "../../models/requests/accounts/setAccountStatus";
import { AccountResponse } from "../../models/Responses/accounts/accountResponse";
import { APIError } from "../../models/types/api/APIError";
import { APIStatus } from "../../models/types/api/APIStatus";
import { AccountApi } from "./accountApi";

type AccountState = {
  savedAccount: AccountResponse | null;
  selectedAccount: AccountResponse | null;

  accounts: AccountResponse[] | null;
  settedStatus: SetAccountStatus | null;
  roles: Role[] | null;

  statuses: {
    accountsStatus: string;
    saveAccountStatus: APIStatus;
  };

  exceptions: {
    saveAccountException: number | null;
  };
};

const initialState: AccountState = {
  savedAccount: null,
  selectedAccount: null,

  accounts: null,
  settedStatus: null,
  roles: null,

  statuses: {
    accountsStatus: APIStatus.IDLE,
    saveAccountStatus: APIStatus.IDLE,
  },

  exceptions: {
    saveAccountException: null,
  },
};

export const getAccounts = createAsyncThunk(
  "Account/Get_All_Accounts",
  async () => {
    const response = await AccountApi.GetAllAccounts();
    return response.data;
  }
);

export const saveAccount = createAsyncThunk<
  SaveAccountModel | null,
  SaveAccountModel,
  { rejectValue: APIError }
>(
  "Account/SaveAccount",
  async (request: SaveAccountModel, { rejectWithValue }) => {
    try {
      const promise = AccountApi.SaveAccount(request);

      const response = await toast.promise(
        promise,
        {
          pending: `${i18n.t(
            `notifications.${
              request.id === EmptyGuid ? "create" : "update"
            }AccountPending`
          )}...`,
          success: `${i18n.t(
            `notifications.${
              request.id === EmptyGuid ? "create" : "update"
            }AccountFulfilled`
          )}!`,
          error: {
            render({ data }) {
              const exception = data as APIError;
              return `${i18n.t(`errorNotifications.${exception.code}`)}.`;
            },
          },
        },
        {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000,
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error as APIError);
    }
    // try {
    //   const response = await AccountApi.SaveAccount(request);
    //   return response.data;
    // } catch (error) {
    //   return rejectWithValue(error as APIError);
    // }
  }
);

export const setAccountStatus = createAsyncThunk(
  "Account/Set_Status",
  async (request: SetAccountStatus) => {
    const response = await AccountApi.SetStatus(request);
    return response.data;
  }
);

export const getRoles = createAsyncThunk("Account/Get_All_Roles", async () => {
  const response = await AccountApi.GetAllRoles();
  return response.data;
});

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    selectAccount: (state, action) => {
      console.log(action.payload);
      state.selectedAccount = action.payload;
    },
    clearSelectedAccount: (state) => {
      state.selectedAccount = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Accounts
      .addCase(getAccounts.pending, (state) => {
        state.statuses.accountsStatus = APIStatus.PENDING;
      })
      .addCase(getAccounts.fulfilled, (state, action) => {
        state.statuses.accountsStatus = APIStatus.FULFILLED;
        state.accounts = action.payload;
      })
      .addCase(getAccounts.rejected, (state) => {
        state.statuses.accountsStatus = APIStatus.REJECTED;
      })

      // Create Account
      .addCase(saveAccount.pending, (state) => {
        state.savedAccount = null;
        state.statuses.saveAccountStatus = APIStatus.PENDING;
      })
      .addCase(saveAccount.fulfilled, (state, action) => {
        state.savedAccount = action.payload;
        state.statuses.saveAccountStatus = APIStatus.FULFILLED;
      })
      .addCase(saveAccount.rejected, (state, action) => {
        state.savedAccount = null;
        if (action.payload) {
          state.exceptions.saveAccountException = action.payload.code;
        }
        state.statuses.saveAccountStatus = APIStatus.REJECTED;
      })

      // Set Status
      .addCase(setAccountStatus.pending, (state) => {})
      .addCase(setAccountStatus.fulfilled, (state, action) => {
        state.settedStatus = action.payload;
      })
      .addCase(setAccountStatus.rejected, (state) => {})

      // Get Roles
      .addCase(getRoles.pending, (state) => {})
      .addCase(getRoles.fulfilled, (state, action) => {
        state.roles = action.payload;
      })
      .addCase(getRoles.rejected, (state) => {});
  },
});
export const selectSavedAccount = (state: RootState) =>
  state.account.savedAccount;
export const selectStatusSavedAccount = (state: RootState) =>
  state.account.statuses.saveAccountStatus;
export const selectExceptionSavedAccount = (state: RootState) =>
  state.account.exceptions.saveAccountException;

export const selectAccounts = (state: RootState) => state.account.accounts;

export const selectRoles = (state: RootState) => state.account.roles;

export const selectAccountsStatus = (state: RootState) =>
  state.account.statuses.accountsStatus;
export const selectSettedStatus = (state: RootState) =>
  state.account.settedStatus;

export const selectSelectedAccount = (state: RootState) =>
  state.account.selectedAccount;

export const { selectAccount, clearSelectedAccount } = accountSlice.actions;

export default accountSlice.reducer;
