export enum Role {
  SuperUser = "SuperUser",
  Administrator = "Administrator",
  User = "User",
}

export enum RoleOrdinal {
  SuperUser = 1,
  Administrator = 2,
  User = 3,
}
