import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../../app/theme/colors";

export const useBasicInputStyles = makeStyles()((theme: Theme) => ({
  inputTitle: { color: colors.inputLabel, textAlign: "start" },
  input: {
    borderRadius: "5px",
    height: "2rem",
    backgroundColor: "white",
  },
  formControl: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: colors.sectionTitle,
      },
    },
  },
  formControlError: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: colors.validationErrorMessage,
      },
    },
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderColor: colors.validationErrorMessage,
      },
    },
  },
  errorMessage: {
    textAlign: "start",
    color: colors.validationErrorMessage,
    fontSize: 12,
  },
  requiredSymbolError: {
    color: colors.validationErrorMessage,
  },
}));
