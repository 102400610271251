import { Button, Typography } from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createMixins";
import styled, { keyframes } from "styled-components";

type Props = {
  label: any;
  onClick?: any;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  disabled?: boolean;
  color: string;
  hover?: string;
  float?: any;
  width?: string;
  variant?: any;
  mt?: string;
  ref?: any;
};

const fadeAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const AnimatedButton = styled(Button)`
  animation: ${fadeAnimation} 0.5s ease-out;
`;

const AppButton = (props: Props) => {
  const buttonStyles: CSSProperties = {
    textTransform: "none",
    backgroundColor: "#fff",
    width: props.width,
    borderRadius: "5px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    float: props.float,
    color: /*props.disabled ? "gray" :*/ props.color,
    marginBottom: "5px",
    fontWeight: "bold",
    marginTop: props.mt,
  };

  const buttonSx = {
    "&:hover": {
      backgroundColor: props.hover,
      color: "white",
    },
    "&:disabled": {
      boxShadow: "none",
    },
    ...buttonStyles,
  };

  return (
    <AnimatedButton
      ref={props.ref}
      sx={buttonSx}
      onClick={props.onClick}
      disabled={props.disabled}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      variant={props.variant}
    >
      <Typography variant="body1">{props.label}</Typography>
    </AnimatedButton>
  );
};

export default AppButton;
