import { colors } from "../../app/theme/colors";
import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStatisticStyles = makeStyles()((theme: Theme) => ({
  alert: {
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tabs: {
    background: colors.primary,
  },
  typography: {
    cursor: "pointer",
  },
}));
