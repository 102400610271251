import { DisplayLanguage } from "../../../models/enums/displayLanguage";

export const isAllVisible = (array: any[]): boolean => {
  const result = array.findIndex((item) => item?.visible === false);
  return result >= 0 ? false : true;
};

export const isAllHidden = (array: any[]): boolean => {
  const result = array.findIndex((item) => item?.visible === true);
  return result >= 0 ? false : true;
};

export const isAtLeastOneVisible = (array: any[]): boolean => {
  const result = array.findIndex((item) => item?.visible === true);
  return result >= 0 ? true : false;
};

const isDate = (str: string): boolean => {
  const regExp = new RegExp(
    "^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$"
  );
  return regExp.test(str);
};

export const formatResult = (result: any) => {
  if (
    result === null ||
    result === undefined ||
    result === "" ||
    result === " " ||
    result === " "
  )
    return "/";

  if (isDate(result)) {
    const date = new Date(result);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };

    return date.toLocaleDateString("sr-RS", options);
  }

  if (result === true) {
    const localStorageTranslation = localStorage.getItem("i18nextLng");
    switch (localStorageTranslation) {
      case "rs":
        return "Da";

      case "rsCyrl":
        return "Да";

      case "en":
        return "Yes";

      case "en-US":
        return "Yes";

      default:
        return "Da";
    }
  }
  if (result === false) {
    const localStorageTranslation = localStorage.getItem("i18nextLng");
    switch (localStorageTranslation) {
      case "rs":
        return "Ne";

      case "rsCyrl":
        return "Не";

      case "en":
        return "No";

      case "en-US":
        return "No";

      default:
        return "Ne";
    }
  }

  return result;
};

export const base64Download = (filename: any, base64Result: string) => {
  const element = document.createElement("a");
  element.setAttribute("href", base64Result);
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
