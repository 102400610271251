import { colors } from "../../../app/theme/colors";
import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useCardSectionStyles = makeStyles()((theme: Theme) => ({
  card: {
    boxShadow: "0 0 10px rgb(0 0 0 / 0.3)",
    borderRadius: "1rem",
  },
  sectionTitle: {
    textAlign: "start",
    color: colors.sectionTitle,
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: "1rem",
  },
  sectionTitleError: {
    textAlign: "start",
    color: "red",
    textTransform: "uppercase",
    fontWeight: 700,
    marginBottom: "1rem",
  },
  inputContainer: {
    marginBottom: "1rem",
  },
  inputTitle: { color: colors.inputLabel, textAlign: "start" },
  radioButton: {
    "&.MuiRadio-root": {
      color: colors.primary,
    },
    "&.Mui-checked": {
      color: colors.primary,
    },
  },
  radioButtonTitle: {
    color: colors.inputLabel,
  },
  checkboxLabel: {
    textAlign: "start",
    "& .MuiFormControlLabel-label": {
      color: colors.inputLabel,
    },
    "& .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label": {
      fontSize: "0.9rem",
    },
  },
  checkbox: {
    color: colors.primary,
    "&.Mui-checked": {
      color: colors.primary,
    },
  },
}));
