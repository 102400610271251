import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  CircularProgress,
  Container,
  createTheme,
  CssBaseline,
  FormLabel,
  Grid,
  IconButton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BasicInput from "../../component/input/basic-input/BasicInput";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import LanguageSwitcher from "../../component/languageSwitcher/languageSwitcher";
import { LoginRequest } from "../../models/requests/auth/loginRequest";
import { LoginResponse } from "../../models/Responses/accounts/loginResponse";
import { APIStatus } from "../../models/types/api/APIStatus";
import {
  login,
  resetResetRequestPassword,
  selectLoginStatus,
} from "../../slices/auth/authSlice";
import { getEmailFromToken } from "../../slices/auth/authUtils";
import RequestForgotPasswordModal from "../modals/forgotPasswordModal/RequestForgotPasswordModal";
import "./Login.css";
import { useLoginStyles } from "./LoginStyles";
import ResetPasswordOnFirstLoginModal from "./resetPasswordOnFirstLogin/ResetPasswordOnFirstLoginModal";
import AppButton from "../../component/button/AppButton";
import { colors } from "../../app/theme/colors";
import LoginIcon from "@mui/icons-material/Login";

const theme = createTheme({
  palette: {
    secondary: {
      main: colors.primary,
    },
  },
});

export default function Login() {
  const { t } = useTranslation();
  const { classes } = useLoginStyles();

  const dispatch = useAppDispatch();

  const loginStatus = useAppSelector(selectLoginStatus);

  const [requestForgotPasswordModalOpen, setRequestForgotPasswordModalOpen] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [
    isOpenResetPasswordOnFirstLoginModal,
    setIsOpenResetPasswordOnFirstLoginModal,
  ] = useState<boolean>(false);
  const [emailFirstLogin, setEmailFirstLogin] = useState<string>("");
  const [tempOldPassFirstLogin, setTempOldPassFirstLogin] =
    useState<string>("");
  const [loginResponse, setLoginResponse] = useState<LoginResponse>();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [loginRequest, setLoginRequest] = useState<LoginRequest>({
    email: "",
    password: "",
  });

  const loginButtonDisabled = loginStatus === APIStatus.PENDING;

  const onLogin = async () => {
    const response = await dispatch(
      login({
        ...loginRequest,
      })
    );

    const isError = (response as any).error;

    if (isError) {
      return;
    }

    const loginResponse = response.payload as LoginResponse;
    // Cases:

    // Case 1: Exception
    if (!loginResponse) {
      return;
    }

    setLoginResponse(loginResponse);

    // Case 2: firstLogin = false
    if (loginResponse.isFirstLogin === false) {
    }

    // Case 3: firstLogin = true
    else {
      setIsOpenResetPasswordOnFirstLoginModal(true);

      const email = getEmailFromToken(loginResponse.accessToken);
      if (email) {
        setEmailFirstLogin(email);
        setTempOldPassFirstLogin(loginRequest.password);
      }
    }
  };

  const handleRequestForgotPasswordCloseModal = (): void => {
    setRequestForgotPasswordModalOpen(false);
    dispatch(resetResetRequestPassword());
  };

  const handleRequestForgotPasswordOpenModal = (): void => {
    setRequestForgotPasswordModalOpen(true);
  };

  const onEnterClicked = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (
        loginRequest.email.trim().length > 0 &&
        loginRequest.password.trim().length > 0
      ) {
        if (loginStatus !== APIStatus.PENDING) {
          await onLogin();
        }
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ResetPasswordOnFirstLoginModal
        isOpen={isOpenResetPasswordOnFirstLoginModal}
        handleClose={() => setIsOpenResetPasswordOnFirstLoginModal(false)}
        email={emailFirstLogin ?? ""}
        oldPass={tempOldPassFirstLogin ?? ""}
        loginResponse={loginResponse}
      />

      <Container component="main" maxWidth="xs" className="mt-5">
        <CssBaseline />
        <Grid container spacing={1} direction="column" alignItems="center">
          <img style={{ width: "250px" }} alt="logo" src={"/Logo.png"} />
        </Grid>
        <Grid container spacing={1} direction="column" alignItems="center">
          <Typography
            component="span"
            variant="h6"
            style={{
              color: "gray",
              marginTop: "15px",
            }}
          >
            {t("loginPage.loginHeader")}
          </Typography>
          <LanguageSwitcher />
        </Grid>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            mt={1}
            className={classes.gridBasicInput}
          >
            <BasicInput
              type="email"
              name="email"
              label={t("loginPage.loginEmail")}
              onChange={(e) =>
                setLoginRequest({ ...loginRequest, email: e.target.value })
              }
              value={loginRequest.email}
              height="35px"
              onKeyDown={onEnterClicked}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            mt={1}
            className={classes.gridBasicInput}
          >
            <BasicInput
              type={showPassword ? "text" : "password"}
              name="password"
              label={t("loginPage.loginPassword")}
              value={loginRequest.password}
              onChange={(e) =>
                setLoginRequest({ ...loginRequest, password: e.target.value })
              }
              endAdornment={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              }
              height="35px"
              onKeyDown={onEnterClicked}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            container
            spacing={1}
            direction="column"
            alignItems="center"
            justifyContent="center"
            mb={3}
            mt={3}
          >
            <FormLabel
              onClick={handleRequestForgotPasswordOpenModal}
              style={{ cursor: "pointer" }}
            >
              {t("loginPage.loginForgotPassword")}
            </FormLabel>
          </Grid>
          <Grid item md={12} xs={12} sm={12} lg={12}>
            <AppButton
              onClick={onLogin}
              color={colors.primary}
              label={
                loginButtonDisabled
                  ? t("messages.login.pending")
                  : t("buttons.loginButton")
              }
              hover={colors.primary}
              width="100%"
              startIcon={
                loginButtonDisabled ? (
                  <CircularProgress
                    size={"20px"}
                    className={classes.circularProgress}
                  />
                ) : (
                  <LoginIcon />
                )
              }
              disabled={loginStatus === APIStatus.PENDING}
            />
          </Grid>
        </Grid>
        <RequestForgotPasswordModal
          open={requestForgotPasswordModalOpen}
          closeModalFn={handleRequestForgotPasswordCloseModal}
        />
      </Container>
    </ThemeProvider>
  );
}
