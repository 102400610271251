import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useElectionPlaceDetailsModalStyle } from "./electionPlaceDetailsModelStyle";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import ElectionPlace from "../../../models/data/addresses/electionPlaceModel";
import { fontSize } from "@mui/system";

interface Props {
  open: boolean;
  onClose: any;
  electionPlace: ElectionPlace | null;
}

export default function ElectionPlaceDetailsModal({
  onClose,
  open,
  electionPlace,
}: Props) {
  const { t } = useTranslation();
  const { classes } = useElectionPlaceDetailsModalStyle();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle} variant="caption">
        <Grid className={classes.grid}>
          <CloseIcon onClick={(e) => onClose(e)} />
        </Grid>
        <Grid>
          <Typography
            variant="h5"
            style={{ color: "white", fontWeight: "bold", textAlign: "center" }}
          >
            {t("memberManagementPage.addressesElectionPlaceDetailsModalTitle")}
          </Typography>
        </Grid>
      </DialogTitle>
      <Grid container style={{ justifyContent: "center" }}>
        <DialogContent>
          <Grid container spacing={2} justifyContent="center">
            <Grid item md={12} sm={12} lg={12} xs={12}>
              <Typography variant="body1" className={classes.labelInfo}>
                {t(
                  "memberManagementPage.addressesElectionPlaceDetailsModalNumber"
                )}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {electionPlace?.electionPlaceNumber}
                </span>
              </Typography>
            </Grid>

            <Grid item md={12} sm={12} lg={12} xs={12}>
              <Typography variant="body1" className={classes.labelInfo}>
                {t(
                  "memberManagementPage.addressesElectionPlaceDetailsModalName"
                )}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {electionPlace?.name}
                </span>
              </Typography>
            </Grid>

            <Grid item md={12} sm={12} lg={12} xs={12}>
              <Typography variant="body1" className={classes.labelInfo}>
                {t(
                  "memberManagementPage.addressesElectionPlaceDetailsModalAddress"
                )}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {electionPlace?.address}
                </span>
              </Typography>
            </Grid>

            <Grid item md={12} sm={12} lg={12} xs={12}>
              <Typography variant="body1" className={classes.labelInfo}>
                {t(
                  "memberManagementPage.addressesElectionPlaceDetailsModalPlace"
                )}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {electionPlace?.cityName}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Grid>
    </Dialog>
  );
}
