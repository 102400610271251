import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import { colors } from "../../app/theme/colors";
import AppButton from "../../component/button/AppButton";
import { SaveAccountModel } from "../../models/requests/accounts/saveAccountModel";
import { SetAccountStatus } from "../../models/requests/accounts/setAccountStatus";
import { APIStatus } from "../../models/types/api/APIStatus";
import {
  clearSelectedAccount,
  saveAccount,
  selectAccount,
  selectSelectedAccount,
  setAccountStatus,
} from "../../slices/accounts/accountSlice";
import { selectDisplayLanguage } from "../../slices/displayLanguages/displayLanguageSlice";
import { useGetAccounts, useGetRoles } from "./hooks/userManagementHooks";
import UserManagementModal from "./modal/UserManagementModal";
import UserManagementTable from "./table/UserManagementTable";
import { useUserManagementStyle } from "./userManagementStyle";

const EmptyGuid = "00000000-0000-0000-0000-000000000000";

const INIT_ACCOUNT: SaveAccountModel = {
  id: EmptyGuid,
  firstName: "",
  lastName: "",
  email: "",
  roleName: "",
  phone: "",
  isActive: false,
  isDeleted: false,
};

export enum UserFormState {
  Create = "Create",
  Edit = "Edit",
  View = "View",
  None = "None",
}

const UserManagement = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { classes } = useUserManagementStyle();
  const { accounts, refetchAccounts, accountsStatus } = useGetAccounts();
  const { roles } = useGetRoles();
  const [account, setAccount] = useState<SaveAccountModel>(INIT_ACCOUNT);
  const [userFormState, setUserFormState] = useState<UserFormState>(
    UserFormState.None
  );
  const displayLanguage = useAppSelector(selectDisplayLanguage);

  const selectedAccount = useAppSelector(selectSelectedAccount);

  // useEffect(() => {
  //   console.log("selectSelectedAccount: ", selectedAccount);
  // }, [selectedAccount]);

  const OnSave = async () => {
    if (
      !(
        userFormState === UserFormState.Create ||
        userFormState === UserFormState.Edit
      )
    ) {
      return;
    }

    const response = await dispatch(saveAccount(account));
    await refetchAccounts();

    const isError = (response as any).error;

    if (isError) {
      return;
    }

    const savedAccount = response.payload as SaveAccountModel;

    console.log("savedAccount: ", savedAccount);

    dispatch(selectAccount(savedAccount));
    setAccount(savedAccount);
  };

  const OnToggleIsActive = async (acc: SaveAccountModel) => {
    const { id, isActive, firstName, lastName, isDeleted } = acc;

    const canDelete = window.confirm(
      `${t("userTablePage.userTableAlert")} ${
        isActive
          ? t("userTablePage.userTableAlertDeactivate")
          : t("userTablePage.userTableAlertActivate")
      } ${t("userTablePage.userTableAlertUser")} "${firstName} ${lastName}"?`
    );

    if (!canDelete) {
      return;
    }

    const request: SetAccountStatus = {
      id,
      isActive: !isActive,
      isDeleted: isDeleted,
    };

    await dispatch(setAccountStatus(request));
    refetchAccounts();
  };

  const OnToggleIsDeleted = async (acc: SaveAccountModel) => {
    const { id, isActive, firstName, lastName, isDeleted } = acc;

    const canDelete = window.confirm(
      `${t("userTablePage.userTableAlert")} ${
        isDeleted
          ? t("userTablePage.userTableAlertGetBack")
          : t("userTablePage.userTableAlertRemove")
      } ${t("userTablePage.userTableAlertUser")} "${firstName} ${lastName}"?`
    );

    if (!canDelete) {
      return;
    }

    const request: SetAccountStatus = {
      id,
      isActive: isActive,
      isDeleted: !isDeleted,
    };

    await dispatch(setAccountStatus(request));
    refetchAccounts();
  };

  // Refetch on change display language
  useEffect(() => {
    if (!displayLanguage) {
      return;
    }

    refetchAccounts();
  }, [displayLanguage]);

  return (
    <>
      <AppButton
        onClick={() => {
          dispatch(clearSelectedAccount());
          setAccount(INIT_ACCOUNT);
          setUserFormState(UserFormState.Create);
        }}
        startIcon={<PersonAddIcon fontSize="small" />}
        color={colors.primary}
        hover={colors.primary}
        label={t("buttons.createNewUser")}
        float="left"
      />
      {accountsStatus === APIStatus.PENDING ? (
        <Grid style={{ padding: "20px" }}>
          <span>
            <CircularProgress size={70} className={classes.circularProgress} />
          </span>
        </Grid>
      ) : (
        <UserManagementTable
          setAccount={setAccount}
          accounts={accounts}
          setUserFormState={setUserFormState}
          onToggleIsActive={OnToggleIsActive}
          onToggleIsDeleted={OnToggleIsDeleted}
        />
      )}
      <UserManagementModal
        account={account}
        setAccount={setAccount}
        accountStatus={accountsStatus}
        userFormState={userFormState}
        setUserFormState={setUserFormState}
        onSave={OnSave}
        roles={roles}
      />
    </>
  );
};

export default UserManagement;
