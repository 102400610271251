import Modal from "react-modal";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../app/theme/colors";

const tableModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  backgroundColor: "#f5f5f5",
  boxShadow: "3px 3px 5px 6px #777",
  p: 4,
  borderRadius: "25px",
  height: "fit-content",
};

export const tableModalStyles: Modal.Styles | undefined = {
  content: { ...(tableModalStyle as any) },
  overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
};

export const useTableModalStyles = makeStyles()(() => ({
  root: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    "&.Mui-expanded": {
      margin: "5px 0",
    },
  },
  accordionTitle: {
    // backgroundColor: '#D3D3D3',
    // color: '#fff',
    fontWeight: "bold",
    // padding: '12px',
  },
  accordionContent: {
    backgroundColor: "rgba(47,68,84, 0.05)",
    color: "#000",
    padding: "15px",
    borderRadius: "10px",
  },
  title: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  buttonWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  span_parentPropertyName: {
    width: "100%",
    textAlign: "start",
    cursor: "pointer",
  },
  arrowBackIcon_wrapper: {
    color: colors.primary,
    textTransform: "none",
    borderRadius: "5px",
    borderColor: "transparent",
    marginTop: "15px",
  },
  textCenter: { textAlign: "center" },
  closeBtnWrapper: { textAlign: "center", marginTop: "10px" },
  closeBtn: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    color: colors.primary,
    "&:hover": {
      background: colors.primary,
      color: "white",
    },
  },
  visibilityOutlined: {
    color: colors.primary,
  },
  label: {
    backgroundColor: "#ddd",
    color: colors.primary,
    textTransform: "none",
    borderRadius: "5px",
    marginTop: "1rem",
    fontSize: "1.2em",
    width: "80%",
    fontWeight: "bold",
    padding: ".75rem",
  },
  dialogTitle: {
    cursor: "pointer",
    background: colors.primary,
    color: "white",
  },
  grid: {
    position: "sticky",
    background: colors.primary,
    textAlign: "end",
    border: "none",
    outline: "none",
  },
  h4: {
    color: colors.primary,
    fontWeight: "bold",
  },
}));
