import { colors } from "./../../app/theme/colors";
import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useBasicAutocompleteStyles = makeStyles()((theme: Theme) => ({
  inputTitle: { color: colors.inputLabel, textAlign: "start" },
  autocompleteTextField: {
    "& .MuiInputBase-input": {
      marginTop: "-0.2rem",
    },

    "& .MuiInputBase-root": {
      borderRadius: "5px",
      height: "2rem",
    },
    backgroundColor:"white"
  },
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: colors.sectionTitle,
      },
    },
  },
}));
