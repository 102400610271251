import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/redux/hooks";
import { MemberDataManipulationRequest } from "../../models/requests/members/memberDataManipulationRequest";
import {
  deleteMember,
  getMemberFullDetailsById,
} from "../../slices/members/memberSlice";

interface Props {
  memberId: string | undefined;
  setIdForDelete: Dispatch<SetStateAction<string>>;
  handleToggleEditModal: () => void;
  handleToggleViewModal: () => void;
  dataManipulation: MemberDataManipulationRequest;
  fetchMembersPagedList: any;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));

export default function TableActionRows({
  memberId,
  setIdForDelete,
  handleToggleEditModal,
  handleToggleViewModal,
  dataManipulation,
  fetchMembersPagedList,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleOpenViewModal = () => {
    if (memberId && memberId !== "") {
      dispatch(getMemberFullDetailsById(memberId)).then(() => {
        handleToggleViewModal();
      });
    }
  };

  const handleDeleteMember = async (e: any) => {
    if (memberId && memberId.length > 0) {
      setIdForDelete(memberId);
      await dispatch(deleteMember(memberId));
      await fetchMembersPagedList({ ...dataManipulation });
    } else {
      setIdForDelete("");
    }
  };

  const handleOpenEditModal = () => {
    if (memberId && memberId !== "") {
      dispatch(getMemberFullDetailsById(memberId)).then(() => {
        handleToggleEditModal();
      });
    }
  };

  return (
    <div>
      <LightTooltip
        title={t("tooltips.tableActionRowsEdit")}
        placement="left"
        arrow
      >
        <CreateOutlinedIcon
          onClick={handleOpenEditModal}
          name="createIcon"
          style={{ color: "#00B2FF", cursor: "pointer" }}
        />
      </LightTooltip>
      <LightTooltip
        title={t("tooltips.tableActionRowsView")}
        placement="top"
        arrow
      >
        <VisibilityOutlinedIcon
          style={{
            color: "#3C7F9C",
            marginLeft: "10px",
            marginRight: "10px",
            cursor: "pointer",
          }}
          className="accountTable_visibility_icon"
          onClick={handleOpenViewModal}
        />
      </LightTooltip>
      <LightTooltip
        title={t("tooltips.tableActionRowsDelete")}
        placement="right"
        arrow
      >
        <DeleteOutlineOutlinedIcon
          style={{ color: "#DE2C2C", cursor: "pointer" }}
          onClick={(e) => handleDeleteMember(e)}
        />
      </LightTooltip>
    </div>
  );
}
