import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/redux/hooks";
import {
  getUserByEmail,
  selectEmail,
  selectLoggedInUser,
  selectToken,
} from "../slices/auth/authSlice";
import {
  getMemberBasicDetails,
  getNextCardNumber,
} from "../slices/members/memberSlice";

export const useLoggedInUser = () => {
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector(selectLoggedInUser);
  const token = useAppSelector(selectToken);
  const email = useAppSelector(selectEmail);

  useEffect(() => {
    if (!loggedInUser && email) {
      (async () => {
        await dispatch(getUserByEmail(email));
      })();

      dispatch(getMemberBasicDetails({ data: "" }));
      dispatch(getNextCardNumber());
    }
  }, [email]);

  return { loggedInUser, token };
};
