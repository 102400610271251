import { SupervisedUserCircle } from "@mui/icons-material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import TableViewIcon from "@mui/icons-material/TableView";
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import { appRoutes } from "../../app/router/appRoutes";
import LanguageSwitcher from "../../component/languageSwitcher/languageSwitcher";
import { RoleOrdinal } from "../../models/enums/roles/roles";
import {
  getUserByEmail,
  logout,
  selectEmail,
  selectLoggedInUser,
  selectRole,
} from "../../slices/auth/authSlice";
import { selectDisplayLanguage } from "../../slices/displayLanguages/displayLanguageSlice";
import {
  getAllStatistic,
  getNextCardNumber,
  resetSelectedMember,
} from "../../slices/members/memberSlice";
import MyProfileModal from "../modals/myProfileModal/myProfileModal";
import "./header.css";
import { useHeaderStyles } from "./headerStyles";

type Anchor = "left";

export default function Header() {
  const { t } = useTranslation();

  const theme = useTheme();

  const loggedInUser = useAppSelector(selectLoggedInUser);
  const emailOfLoggedInUser = useAppSelector(selectEmail);
  const role = useAppSelector(selectRole);

  const { classes } = useHeaderStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [openMyProfileModal, setOpenMyProfileModal] = useState(false);

  const displayLanguage = useAppSelector(selectDisplayLanguage);

  const open = Boolean(anchorEl);

  const handleMyProfileModalOpen = () => {
    setOpenMyProfileModal(true);
  };

  const handleMyProfileModalClose = () => {
    setOpenMyProfileModal(false);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (): void => {
    dispatch(logout());
  };

  const handleDashboardPage = () => {
    dispatch(getNextCardNumber());
    navigate(appRoutes.dashboard);
  };

  const handleSmartMembershipPage = async () => {
    await dispatch(resetSelectedMember());
    dispatch(getNextCardNumber());
    navigate(appRoutes.smartMembership);
  };

  const handleTableMemberPage = () => {
    navigate(appRoutes.reviewExportPrintTable);
  };

  const handleStatisticPage = () => {
    dispatch(getAllStatistic());
    navigate(appRoutes.statisticPage);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  // Refetch on change display language
  useEffect(() => {
    if (!displayLanguage) {
      return;
    }

    dispatch(getAllStatistic());

    if (emailOfLoggedInUser) {
      dispatch(getUserByEmail(emailOfLoggedInUser));
    }
  }, [displayLanguage]);

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button onClick={handleDashboardPage}>
          <ListItemIcon>
            <DashboardIcon className={classes.sidebarIcon} />
          </ListItemIcon>
          <ListItemText>{t("sidebar.sidebarDashboard")}</ListItemText>
        </ListItem>
        <ListItem button onClick={handleSmartMembershipPage}>
          <ListItemIcon>
            <GroupAddIcon className={classes.sidebarIcon} />
          </ListItemIcon>
          <ListItemText>{t("sidebar.sidebarAddMembers")}</ListItemText>
        </ListItem>
        <ListItem button onClick={handleTableMemberPage}>
          <ListItemIcon>
            <TableViewIcon className={classes.sidebarIcon} />
          </ListItemIcon>
          <ListItemText>{t("sidebar.sidebarMemberTable")}</ListItemText>
        </ListItem>
      </List>

      {role !== RoleOrdinal.User && (
        <>
          <Divider />

          <List>
            <ListItem button onClick={handleStatisticPage}>
              <ListItemIcon>
                <QueryStatsIcon className={classes.sidebarIcon} />
              </ListItemIcon>
              <ListItemText>{t("sidebar.sidebarStatistic")}</ListItemText>
            </ListItem>

            <ListItem button onClick={() => navigate(appRoutes.userManagement)}>
              <ListItemIcon>
                <SupervisedUserCircle className={classes.sidebarIcon} />
              </ListItemIcon>
              <ListItemText>{t("sidebar.userManagement")}</ListItemText>
            </ListItem>
          </List>
        </>
      )}

      <Divider />

      <List>
        <ListItem button onClick={handleLogout} disabled={loading === true}>
          <ListItemIcon>
            <ExitToAppIcon className={classes.sidebarIcon} />
          </ListItemIcon>
          <ListItemText>{t("sidebar.sidebarLogout")}</ListItemText>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Grid container>
            <Grid item md={4} xs={3}>
              {(["left"] as Anchor[]).map((anchor) => (
                <React.Fragment key={anchor}>
                  <MenuIcon
                    id="header_menu-icon"
                    style={{ marginTop: "12px" }}
                    onClick={toggleDrawer(anchor, true)}
                    className={classes.menuIcon}
                  />
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </Grid>

            {isDesktop && (
              <Grid item md={4} xs={4} display={"flex"} alignItems={"center"}>
                <Typography variant="h6" className={classes.title}>
                  {t("header.headerTitle")}
                </Typography>
              </Grid>
            )}

            {isMobile && (
              <Grid item md={4} xs={5} display={"flex"} alignItems={"center"}>
                <Typography variant="body1" className={classes.title}>
                  {t("header.headerTitle")}
                </Typography>
              </Grid>
            )}

            <Grid item md={4} xs={4}>
              {loggedInUser && (
                <div>
                  <IconButton
                    className={classes.iconButton}
                    style={{ float: "right" }}
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <Typography>
                      {loggedInUser?.firstName} {loggedInUser?.lastName}
                    </Typography>
                    <AccountCircle style={{ marginLeft: "5px" }} />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    <Grid container alignItems="center" spacing={2}>
                      <Grid
                        item
                        md={12}
                        xs={12}
                        className={classes.menuGridIcon}
                      >
                        <PersonIcon className={classes.icon} />
                      </Grid>
                      <Grid
                        item
                        md={12}
                        xs={12}
                        className={classes.menuGridTypography}
                      >
                        <Typography className={classes.menuTypographyName}>
                          {loggedInUser?.firstName} {loggedInUser?.lastName}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.menuTypographyRole}
                        >
                          {loggedInUser?.role}
                        </Typography>
                        <LanguageSwitcher />
                      </Grid>
                    </Grid>
                    <Divider className={classes.dividerMargin} />
                    <MenuItem
                      onClick={handleMyProfileModalOpen}
                      className={classes.menuItem}
                    >
                      <PersonIcon className={classes.icon} />
                      {t("myProfile.myProfileButton")}
                    </MenuItem>
                    <Divider className={classes.divider} />
                    {loading === true && (
                      <MenuItem
                        onClick={handleLogout}
                        disabled
                        className={classes.menuItem}
                      >
                        <ExitToAppIcon className={classes.icon} />
                        {t("myProfile.logoutButton")}
                      </MenuItem>
                    )}
                    {loading === false && (
                      <MenuItem
                        onClick={handleLogout}
                        className={classes.menuItem}
                      >
                        <ExitToAppIcon className={classes.icon} />
                        {t("myProfile.logoutButton")}
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <MyProfileModal
        modalClose={handleMyProfileModalClose}
        open={openMyProfileModal}
        currentUser={loggedInUser}
      />
    </div>
  );
}
