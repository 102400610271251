import jwt_decode from "jwt-decode";
import { AccessTokenClaims } from "../../models/app/auth/accessTokenClaims";
import { Token } from "../../models/enums/auth/token";
import { Role, RoleOrdinal } from "../../models/enums/roles/roles";

export const getEmailFromToken = (accessTokenParam?: string): string | null => {
  const access = accessTokenParam || localStorage.getItem(Token.AccessToken);

  if (!access) {
    return null;
  }

  try {
    const parsed = jwt_decode(access) as AccessTokenClaims;

    if (!parsed) {
      return null;
    }

    return parsed.email;
  } catch (err) {
    return null;
  }
};

export const getRoleFromToken = (
  accessTokenParam?: string
): RoleOrdinal | null => {
  const access = accessTokenParam || localStorage.getItem(Token.AccessToken);

  if (!access) {
    return null;
  }

  try {
    const parsed = jwt_decode(access) as AccessTokenClaims;

    if (!parsed) {
      return null;
    }

    switch (parsed.role) {
      case Role.SuperUser:
        return RoleOrdinal.SuperUser;
      case Role.Administrator:
        return RoleOrdinal.Administrator;
      case Role.User:
        return RoleOrdinal.User;
      default:
        return RoleOrdinal.User;
    }
  } catch (err) {
    return null;
  }
};
