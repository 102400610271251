import { RoleOrdinal } from "./../../models/enums/roles/roles";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RootState } from "../../app/redux/store";
import i18n from "../../i18n";
import { Tokens } from "../../models/app/users/tokens";
import { Account } from "../../models/auth/account";
import { User } from "../../models/data/accounts/user";
import { ForgotPasswordRequest } from "../../models/requests/auth/forgotPasswordRequest";
import { LoginRequest } from "../../models/requests/auth/loginRequest";
import { RefreshTokenRequest } from "../../models/requests/auth/refreshTokenRequest";
import { RequestForgotPasswordRequest } from "../../models/requests/auth/requestForgotPasswordRequest";
import { ResetPasswordRequest } from "../../models/requests/auth/resetPasswordRequest";
import { APIError } from "../../models/types/api/APIError";
import { APIResponse } from "../../models/types/api/APIResponse";
import { APIStatus } from "../../models/types/api/APIStatus";
import { execute } from "../helpers/sliceHelpers";
import { AuthApi } from "./authApi";
import { getEmailFromToken, getRoleFromToken } from "./authUtils";
import {
  forgotPassword as forgotPasswordApi,
  requestForgotPassword as requestForgotPasswordApi,
} from "../../app/redux/npdddApi";
import { APIData } from "../../models/types/api/APIData";
import { Token } from "../../models/enums/auth/token";
import { Role } from "../../models/enums/roles/roles";

const INIT_API_DATA = {
  status: APIStatus.IDLE,
};

interface authState {
  accessToken: string | null;
  refreshToken: string | null;

  email: string | null;
  role: RoleOrdinal | null;
  loggedInUser: User | null;

  requestForgotPasswordResponse: APIData<string>;
  forgotPasswordResponse: APIData<Account>;

  statuses: {
    loginStatus: APIStatus;
    getUserByEmailStatus: APIStatus;
  };

  exceptions: {
    loginException: APIError | null;
    getUserByEmailException: APIError | null;
  };
}

const initialState: authState = {
  accessToken: localStorage.getItem(Token.AccessToken) || null,
  refreshToken: localStorage.getItem(Token.RefreshToken) || null,

  email: getEmailFromToken(),
  role: getRoleFromToken(),
  loggedInUser: null,

  requestForgotPasswordResponse: INIT_API_DATA,
  forgotPasswordResponse: INIT_API_DATA,

  statuses: {
    loginStatus: APIStatus.IDLE,
    getUserByEmailStatus: APIStatus.IDLE,
  },

  exceptions: {
    loginException: null,
    getUserByEmailException: null,
  },
};

export const login = createAsyncThunk(
  "auth/login",
  async (request: LoginRequest, { rejectWithValue }) => {
    try {
      const promise = AuthApi.Login(request);

      const result = await toast.promise(
        promise,
        {
          pending: `${i18n.t(`messages.login.pending`)}...`,
          success: `${i18n.t(`messages.login.success`)}!`,
          error: {
            render({ data }) {
              const exception = data as APIError;
              return `${i18n.t(`errorNotifications.${exception.code}`)}.`;
            },
          },
        },
        {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000,
        }
      );

      return result.data;
    } catch (error: any) {
      return rejectWithValue(error as APIError);
    }
  }
);

export const refresh = createAsyncThunk(
  "auth/refresh",
  async (request: RefreshTokenRequest, { rejectWithValue }) => {
    try {
      const response = await AuthApi.RefreshToken(request);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error as APIError);
    }
  }
);

export const getUserByEmail = createAsyncThunk(
  "user/get-user-by-email",
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await AuthApi.GetUserByEmail(email);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error as APIError);
    }
  }
);

export const resetPasswordOnFirstLogin = createAsyncThunk(
  "user/reset-password-on-first-login",
  async (request: ResetPasswordRequest, { rejectWithValue }) => {
    try {
      const promise = AuthApi.ResetPassword(request);

      const response = await toast.promise(
        promise,
        {
          pending: `${i18n.t(`messages.resetPasswordOnFirstLogin.pending`)}...`,
          success: `${i18n.t(`messages.resetPasswordOnFirstLogin.success`)}!`,
          error: {
            render({ data }) {
              const exception = data as APIError;
              return `${i18n.t(`errorNotifications.${exception.code}`)}.`;
            },
          },
        },
        {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000,
        }
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error as APIError);
    }
  }
);
export const resetResetRequestPassword = createAsyncThunk(
  "auth/resetResetRequestPassword",
  async () => {
    return INIT_API_DATA;
  }
);

export const requestForgotPassword = createAsyncThunk<
  APIResponse<string>,
  RequestForgotPasswordRequest,
  { rejectValue: APIError }
>(
  "Auth/Request_Forgot_Password",
  async (request: RequestForgotPasswordRequest, { rejectWithValue }) =>
    execute(requestForgotPasswordApi(request), rejectWithValue)
);

export const forgotPassword = createAsyncThunk<
  APIResponse<Account>,
  ForgotPasswordRequest,
  { rejectValue: APIError }
>(
  "Auth/Forgot_Password",
  async (request: ForgotPasswordRequest, { rejectWithValue }) =>
    execute(forgotPasswordApi(request), rejectWithValue)
);

const onSetTokens = (
  state: authState,
  action: { payload: any; type?: string }
) => {
  const { accessToken, refreshToken } = action.payload as Tokens;

  state.accessToken = accessToken;
  state.refreshToken = refreshToken;
  state.email = getEmailFromToken(accessToken);
  state.role = getRoleFromToken(accessToken);

  localStorage.setItem(Token.AccessToken, accessToken);
  localStorage.setItem(Token.RefreshToken, refreshToken);
};

const onLogout = (state: authState) => {
  localStorage.removeItem(Token.AccessToken);
  localStorage.removeItem(Token.RefreshToken);

  state.accessToken = null;
  state.refreshToken = null;

  state.email = null;
  state.role = null;
  state.loggedInUser = null;

  state.statuses.loginStatus = APIStatus.IDLE;
  state.exceptions.loginException = null;
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      onLogout(state);
    },
    setTokens: (state, action) => {
      onSetTokens(state, action);
    },
  },
  extraReducers: (builder) => {
    builder

      // Login
      .addCase(login.pending, (state) => {
        state.exceptions.loginException = null;
        state.statuses.loginStatus = APIStatus.PENDING;
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload?.isFirstLogin === false) {
          onSetTokens(state, action);
        }
        state.exceptions.loginException = null;
        state.statuses.loginStatus = APIStatus.FULFILLED;
      })
      .addCase(login.rejected, (state, action) => {
        // if (action.payload) {
        //   state.exceptions.loginException = { ...action.payload };
        // }
        state.statuses.loginStatus = APIStatus.REJECTED;
      })

      // Refresh
      .addCase(refresh.fulfilled, (state, action) => {
        onSetTokens(state, action);
        state.exceptions.loginException = null;
      })

      // Get User By Email
      .addCase(getUserByEmail.pending, (state, action) => {
        state.exceptions.getUserByEmailException = null;
        state.statuses.getUserByEmailStatus = APIStatus.PENDING;
      })
      .addCase(getUserByEmail.fulfilled, (state, action) => {
        state.loggedInUser = action.payload;
        state.statuses.getUserByEmailStatus = APIStatus.FULFILLED;
      })
      .addCase(getUserByEmail.rejected, (state, action) => {
        // if (action.payload) {
        //   state.exceptions.getUserByEmailException = { ...action.payload };
        // }
        state.statuses.getUserByEmailStatus = APIStatus.REJECTED;
      })

      // requestForgotPassword
      .addCase(requestForgotPassword.fulfilled, (state, action) => {
        state.requestForgotPasswordResponse.status = APIStatus.FULFILLED;
        state.requestForgotPasswordResponse.response = { ...action.payload };
      })
      .addCase(requestForgotPassword.pending, (state, action) => {
        state.requestForgotPasswordResponse.status = APIStatus.PENDING;
      })
      .addCase(requestForgotPassword.rejected, (state, action) => {
        state.requestForgotPasswordResponse.status = APIStatus.REJECTED;
      })

      .addCase(resetResetRequestPassword.fulfilled, (state, action) => {
        state.requestForgotPasswordResponse.status = APIStatus.IDLE;
      })

      // forgotPassword
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgotPasswordResponse.status = APIStatus.FULFILLED;
        state.forgotPasswordResponse.response = { ...action.payload };
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.forgotPasswordResponse.status = APIStatus.REJECTED;
      });
  },
});

export const { logout, setTokens } = authSlice.actions;

export const selectLoginStatus = (state: RootState) =>
  state.auth.statuses.loginStatus;
export const selectGetUserByEmailStatus = (state: RootState) =>
  state.auth.statuses.getUserByEmailStatus;
export const selectToken = (state: RootState) => state.auth.accessToken;
export const selectLoggedInUser = (state: RootState) => state.auth.loggedInUser;
export const selectEmail = (state: RootState) => state.auth.email;
export const selectRole = (state: RootState) => state.auth.role;

export default authSlice.reducer;
