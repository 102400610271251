import Layout from "./app/theme/Layout";
import "./App.css";
import AppRouter from "./app/router/AppRouter";

function App() {
  return (
    <Layout>
      <AppRouter />
    </Layout>
  );
}

export default App;
