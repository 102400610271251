import { Container, Grid, Theme } from "@mui/material";
import Header from "../../features/header/Header";
import NotFound from "../../features/notFound/NotFound";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: 0,
    marginTop: 70,
    [theme.breakpoints.down("xs")]: {
      marginTop: "125px",
    },
  },
  grid: {
    marginTop: "40px",
  },
  cardNumber: {
    textAlign: "right",
    color: "GrayText",
  },
}));

export default function NotFoundPage() {
  const { classes } = useStyles();

  return (
    <Container className={classes.container}>
      <Grid>
        <Header />
      </Grid>
      <Grid style={{ marginTop: "20px" }} item xs={12} sm={12}>
        <NotFound />
      </Grid>
    </Container>
  );
}
