import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../app/theme/colors";

export const useMemberListStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: "1rem",
  },
  heading: {
    fontSize: "23px",
    fontWeight: 700,
    color: "white",
  },
  header: {
    backgroundColor: "#2f4454",
    borderRadius: "5px",
    height: "20px",
  },
  typographyText: {
    fontSize: "18px",
    fontWeight: 700,
    color: "#5a5a5a",
    border: "none",
    background: "transparent",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  cardNumber: {
    textAlign: "center",
    color: "GrayText",
    background: "whitesmoke",
    alignContent: "center",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0.5rem",
  },
  inputWithMargin: {
    borderRadius: "5px",
    marginRight: "5px",
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
  },
  circularProgress: {
    color: colors.primary,
    display: "block",
    margin: "auto",
  },
}));
