import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { MemberBasicDetails } from "../../../models/Responses/members/memberBasicDetails";
import { selectDisplayLanguage } from "../../../slices/displayLanguages/displayLanguageSlice";
import {
  getMemberBasicDetails,
  selectSelectedMember,
} from "../../../slices/members/memberSlice";

export const useSelectMember = () => {
  const dispatch = useAppDispatch();

  const selectedMember = useAppSelector(selectSelectedMember);
  const displayLanguage = useAppSelector(selectDisplayLanguage);

  const [value, setValue] = useState<MemberBasicDetails | null>(null); // Actual selected member object
  const [displayValue, setDisplayValue] = useState<string>(""); // Display (input) value of selected member object

  // When fetch member full details by id, set value
  useEffect(() => {
    // console.log("selectedMember: ", selectedMember);

    if (
      !selectedMember ||
      !selectedMember.personalData ||
      !selectedMember.personalData.id
    ) {

      setValue(null);
      return;
    }

    const memberBasicDetailsMapped: MemberBasicDetails = {
      id: selectedMember.personalData.id ?? "",
      firstName: selectedMember.personalData.firstName ?? "",
      lastName: selectedMember.personalData.lastName ?? "",
      birthDate: selectedMember.personalData.birthDate ?? "",
    };

    setValue(memberBasicDetailsMapped);
  }, [selectedMember]);

  // When search in autocomplete, fetch after 2 characters
  useEffect(() => {
    // console.log("Display value: ", displayValue);

    fetchBasicDetails();
  }, [displayValue]);

  // Refetch on change display language
  useEffect(() => {
    if (!displayLanguage) {
      return;
    }

    fetchBasicDetails();
  }, [displayLanguage]);

  const fetchBasicDetails = async () => {
    if (displayValue.length > 2) {
      await dispatch(getMemberBasicDetails({ data: displayValue }));
    } else if (displayValue.length === 0) {
      await dispatch(getMemberBasicDetails({ data: "" }));
    }
  };

  return { value, setValue, displayValue, setDisplayValue };
};
