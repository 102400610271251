import { makeStyles } from "tss-react/mui";
import { colors } from "../../app/theme/colors";

export const useMemberItemCardStyles = makeStyles()({
  root: {
    borderRadius: "5px",
    paddingBottom: 0,
    transition: "transform .2s",
    cursor: "pointer",

    "&:hover": {
      msTransform: "scale(1.05)",
      WebkitTransform: "scale(1.05)",
      transform: "scale(1.01)",
    },
  },
  div: {
    backgroundColor: colors.primary,
  },
  cardHeader: {
    backgroundColor: colors.primary,
    color: "White",
    padding: "5px",
    marginLeft: "15px",
  },
  cardHeaderText: {
    fontWeight: "bolder",
    fontSize: "20px",
  },
  cardNumber: {
    backgroundColor: colors.primary,
    color: "White",
    marginLeft: "15px",
  },
  creditCard: {
    color: "White",
  },
  creditCardIcon: {
    color: "White",
    marginRight: "5px",
  },
  typographyText: {
    fontSize: "18px",
    fontWeight: 700,
    color: "#5a5a5a",
    padding: "10px",
  },
  cardIcon: {
    color: colors.primary,
    marginRight: "10px",
  },
  cardContentText: {
    color: "#5a5a5a",
  },
  cardContent: {
    padding: "10px",
    marginBottom: "-20px",
  },
});
