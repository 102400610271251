import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  Container,
  DialogTitle,
  Grid,
  Popover,
  PopoverOrigin,
  TextField,
  Typography,
} from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createMixins";
import { useTranslation } from "react-i18next";
import { colors } from "../../app/theme/colors";
import AppButton from "../../component/button/AppButton";
import BasicInput from "../../component/input/basic-input/BasicInput";
import { DataTypeEnum } from "../../models/app/table-container/dataTypeEnum";
import { MemberDataManipulationRequest } from "../../models/requests/members/memberDataManipulationRequest";
import { useSelectMemberSectionStyles } from "../smart-membership/select-member/selectMemberSectionStyles";
import { useTableFiltersStyle } from "./tableFilterStyle";

type Props = {
  filterPopover: any;
  memberProperties: any;
  filter: any;
  setFilter: any;
  filterProperty: any;
  setFilterProperty: any;
  addFilter: any;
  filterValue: any;
  setFilterValue: any;
  refetchMemberPagedList: (request: MemberDataManipulationRequest) => any;
  dataManipulation: any;
};

const TableFilters = (props: Props) => {
  const { t } = useTranslation();
  const { classes: selectMemberSectionClasses } =
    useSelectMemberSectionStyles();
  const { classes } = useTableFiltersStyle();

  const dataType = props.filterProperty?.dataType;

  const popoverProps = {
    id: props.filterPopover.id,
    open: props.filterPopover.isOpen,
    anchorEl: props.filterPopover.anchorEl,
    onClose: props.filterPopover.handleClose,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    } as PopoverOrigin,
  };

  const containerStyles: CSSProperties = {
    padding: "2rem",
    minHeight: "100px",
    minWidth: "300px",
  };

  const propertyNameTitleStyles: CSSProperties = {
    color: colors.inputLabel,
    textAlign: "start",
  };

  return (
    <Popover {...popoverProps}>
      <DialogTitle className={classes.popoverTitle} textAlign="center">
        <Typography>{t("icons.filters")}</Typography>
      </DialogTitle>
      <Container style={{ ...containerStyles }}>
        <Grid container style={{ marginBottom: "1rem" }}>
          <Grid item xs>
            <Typography variant="body1" style={{ ...propertyNameTitleStyles }}>
              {t("common.selectProperty")}:
            </Typography>
            <Autocomplete
              options={props.memberProperties}
              value={props.filterProperty}
              onChange={(e, newValue) => {
                props.setFilterProperty(newValue);
              }}
              groupBy={(option) => option.parentLabel}
              getOptionLabel={(option) => option.childLabel ?? ""}
              isOptionEqualToValue={(opt, value) =>
                opt.parent === value.parent && opt.child === value.child
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoComplete="off"
                  color="secondary"
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={selectMemberSectionClasses.autocompleteTextField}
                />
              )}
              className={selectMemberSectionClasses.autocomplete}
              id="autocomplete-property-name"
            />
          </Grid>
        </Grid>

        <Grid container justifyContent={"space-between"}>
          {dataType && (
            <Grid item md={12} xs={12}>
              {dataType === DataTypeEnum.string ? (
                <>
                  <BasicInput
                    type="text"
                    label={t("common.value")}
                    value={props.filterValue.string ?? ""}
                    onChange={(e) => {
                      props.setFilterValue({
                        ...props.filterValue,
                        string: e.target.value,
                      });
                    }}
                  />
                </>
              ) : (
                <>
                  <BasicInput
                    type={dataType}
                    label={t("common.from") + " / " + t("common.equals")}
                    value={
                      dataType === DataTypeEnum.date
                        ? props.filterValue.minDate ?? ""
                        : props.filterValue.minNumber ?? ""
                    }
                    onChange={(e) => {
                      const updatedFilterValue = { ...props.filterValue };

                      if (dataType === DataTypeEnum.date) {
                        updatedFilterValue.minDate = e.target.value;
                      } else {
                        updatedFilterValue.minNumber = +e.target.value;
                      }

                      props.setFilterValue({
                        ...updatedFilterValue,
                      });
                    }}
                  />

                  <BasicInput
                    type={dataType}
                    label={t("common.to")}
                    value={
                      dataType === DataTypeEnum.date
                        ? props.filterValue.maxDate ?? ""
                        : props.filterValue.maxNumber ?? ""
                    }
                    onChange={(e) => {
                      const updatedFilterValue = { ...props.filterValue };

                      if (dataType === DataTypeEnum.date) {
                        updatedFilterValue.maxDate = e.target.value;
                      } else {
                        updatedFilterValue.maxNumber = +e.target.value;
                      }

                      props.setFilterValue({
                        ...updatedFilterValue,
                      });
                    }}
                  />
                </>
              )}
            </Grid>
          )}
        </Grid>

        <Grid
          container
          spacing={3}
          justifyContent={"space-between"}
          style={{ marginTop: "1rem" }}
        >
          <Grid item></Grid>

          <Grid item>
            <AppButton
              label={t("common.add")}
              onClick={props.addFilter}
              color={colors.green}
              startIcon={<Add fontSize="small" />}
              disabled={props.filterProperty === null}
            />
          </Grid>
        </Grid>
      </Container>
    </Popover>
  );
};

export default TableFilters;
