import {
  FormControlLabel,
  Radio,
  Grid,
  FormControl,
  Typography,
  OutlinedInput,
  RadioGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import BasicInput from "../../../component/input/basic-input/BasicInput";
import { Member } from "../../../models/data/members/Member";
import MemberSectionType from "../../../models/types/memberSectionTypes/memberSectionType";
import { useEducationSectionStyles } from "./educationSectionStyles";

interface Props {
  member: Member;
  onChange: any;
}

export const MemberEducationData = ({ member, onChange }: Props) => {
  const { t } = useTranslation();
  const { classes } = useEducationSectionStyles();

  return (
    <Grid container spacing={2}>
      <Grid item lg={8} md={8} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.educationElementarySchool")}
          value={member?.education?.elementarySchool}
          onChange={(e) =>
            onChange(
              e.target.value,
              "elementarySchool",
              MemberSectionType.memberEducation
            )
          }
        />
      </Grid>

      {/*HIGH SCHOOL */}
      <Grid item lg={12} md={12} sm={12}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="body1" className={classes.inputTitle}>
            {t("memberManagementPage.educationHighSchool")}:
          </Typography>
        </Grid>
        <Grid container>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <OutlinedInput
                fullWidth
                value={member?.education?.highSchool}
                onChange={(e) =>
                  onChange(
                    e.target.value,
                    "highSchool",
                    MemberSectionType.memberEducation
                  )
                }
                className={classes.input}
              />
            </FormControl>
          </Grid>
          <Grid item lg={4}>
            <FormControl>
              <RadioGroup name="highSchoolYear">
                <Grid container>
                  <Grid item className={classes.radioButton}>
                    <FormControlLabel
                      value={
                        member?.education?.highSchoolYear !== null &&
                        member?.education?.highSchoolYear === 3
                          ? true
                          : false
                      }
                      checked={
                        member?.education?.highSchoolYear !== null &&
                        member?.education?.highSchoolYear === 3
                          ? true
                          : false
                      }
                      control={
                        <Radio
                          className={classes.radioButton}
                          onClick={(e) =>
                            onChange(
                              3,
                              "highSchoolYear",
                              MemberSectionType.memberEducation
                            )
                          }
                        />
                      }
                      label={
                        <Typography variant="body1" color="textSecondary">
                          {t("memberManagementPage.educationLevelThird")}
                        </Typography>
                      }
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 14,
                        },
                      }}
                    />
                    <FormControlLabel
                      value={
                        member?.education?.highSchoolYear !== null &&
                        member?.education?.highSchoolYear === 4
                          ? true
                          : false
                      }
                      checked={
                        member?.education?.highSchoolYear !== null &&
                        member?.education?.highSchoolYear === 4
                          ? true
                          : false
                      }
                      control={
                        <Radio
                          className={classes.radioButton}
                          onClick={(e) =>
                            onChange(
                              4,
                              "highSchoolYear",
                              MemberSectionType.memberEducation
                            )
                          }
                        />
                      }
                      label={
                        <Typography variant="body1" color="textSecondary">
                          {t("memberManagementPage.educationLevelFourth")}
                        </Typography>
                      }
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 14,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      {/*BASIC VOCATINAL SCHOOL*/}
      <Grid item lg={8} md={8} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.educationBasicVocationalSchool")}
          value={member?.education?.basicVocationalStudies}
          onChange={(e) =>
            onChange(
              e.target.value,
              "basicVocationalStudies",
              MemberSectionType.memberEducation
            )
          }
        />
      </Grid>

      {/*SPECIALIST VOCATINAL SCHOOL */}
      <Grid item lg={8} md={8} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.educationSpecialistVocationalSchool")}
          value={member?.education?.specialVocationalStudies}
          onChange={(e) =>
            onChange(
              e.target.value,
              "specialVocationalStudies",
              MemberSectionType.memberEducation
            )
          }
        />
      </Grid>

      {/*ACADEMIC STUDIES */}
      <Grid item lg={12} md={12} sm={12}>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Typography variant="body1" className={classes.inputTitle}>
            {t("memberManagementPage.educationAcademicStudies")}:
          </Typography>
        </Grid>
        <Grid container>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <OutlinedInput
                fullWidth
                value={member?.education?.academicStudies}
                onChange={(e) =>
                  onChange(
                    e.target.value,
                    "academicStudies",
                    MemberSectionType.memberEducation
                  )
                }
                className={classes.input}
              />
            </FormControl>
          </Grid>
          <Grid item lg={4} md={4} sm={12}>
            <FormControl>
              <Grid container>
                <Grid item className={classes.radioButton}>
                  <FormControlLabel
                    value={
                      member?.education?.academicStudiesYear !== null &&
                      member?.education?.academicStudiesYear === 3
                        ? true
                        : false
                    }
                    checked={
                      member?.education?.academicStudiesYear !== null &&
                      member?.education?.academicStudiesYear === 3
                        ? true
                        : false
                    }
                    control={
                      <Radio
                        className={classes.radioButton}
                        onClick={(e) =>
                          onChange(
                            3,
                            "academicStudiesYear",
                            MemberSectionType.memberEducation
                          )
                        }
                      />
                    }
                    label={
                      <Typography variant="body1" color="textSecondary">
                        {t("memberManagementPage.educationLevelThird")}
                      </Typography>
                    }
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 14,
                      },
                    }}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value={
                      member?.education?.academicStudiesYear !== null &&
                      member?.education?.academicStudiesYear === 4
                        ? true
                        : false
                    }
                    checked={
                      member?.education?.academicStudiesYear !== null &&
                      member?.education?.academicStudiesYear === 4
                        ? true
                        : false
                    }
                    control={
                      <Radio
                        className={classes.radioButton}
                        onClick={(e) =>
                          onChange(
                            4,
                            "academicStudiesYear",
                            MemberSectionType.memberEducation
                          )
                        }
                      />
                    }
                    label={
                      <Typography variant="body1" color="textSecondary">
                        {t("memberManagementPage.educationLevelFourth")}
                      </Typography>
                    }
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 14,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Typography variant="body1" className={classes.inputTitle}>
            {t("memberManagementPage.educationMasterAcademicStudies")}:
          </Typography>
        </Grid>
        <Grid container>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <OutlinedInput
                fullWidth
                value={member?.education?.masterAcademicStudies}
                onChange={(e) =>
                  onChange(
                    e.target.value,
                    "masterAcademicStudies",
                    MemberSectionType.memberEducation
                  )
                }
                className={classes.input}
              />
            </FormControl>
          </Grid>
          <Grid item lg={4} md={4} sm={12}>
            <FormControl>
              <Grid container>
                <Grid item className={classes.radioButton}>
                  <FormControlLabel
                    value={
                      member?.education?.masterAcademicStudiesYear !== null &&
                      member?.education?.masterAcademicStudiesYear === 3
                        ? true
                        : false
                    }
                    checked={
                      member?.education?.masterAcademicStudiesYear !== null &&
                      member?.education?.masterAcademicStudiesYear === 3
                        ? true
                        : false
                    }
                    control={
                      <Radio
                        className={classes.radioButton}
                        onClick={(e) =>
                          onChange(
                            3,
                            "masterAcademicStudiesYear",
                            MemberSectionType.memberEducation
                          )
                        }
                      />
                    }
                    label={
                      <Typography variant="body1" color="textSecondary">
                        {t("memberManagementPage.educationLevelThird")}
                      </Typography>
                    }
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 14,
                      },
                    }}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value={
                      member?.education?.masterAcademicStudiesYear !== null &&
                      member?.education?.masterAcademicStudiesYear === 4
                        ? true
                        : false
                    }
                    checked={
                      member?.education?.masterAcademicStudiesYear !== null &&
                      member?.education?.masterAcademicStudiesYear === 4
                        ? true
                        : false
                    }
                    control={
                      <Radio
                        className={classes.radioButton}
                        onClick={(e) =>
                          onChange(
                            4,
                            "masterAcademicStudiesYear",
                            MemberSectionType.memberEducation
                          )
                        }
                      />
                    }
                    label={
                      <Typography variant="body1" color="textSecondary">
                        {t("memberManagementPage.educationLevelFourth")}
                      </Typography>
                    }
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 14,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.educationSpecialistAcademicStudies")}
          value={member?.education?.specialAcademicStudies}
          onChange={(e) =>
            onChange(
              e.target.value,
              "specialAcademicStudies",
              MemberSectionType.memberEducation
            )
          }
        />
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.educationDoctoralAcademicStudies")}
          value={member?.education?.doctoralAcademicStudies}
          onChange={(e) =>
            onChange(
              e.target.value,
              "doctoralAcademicStudies",
              MemberSectionType.memberEducation
            )
          }
        />
      </Grid>
      <Grid item lg={12} sm={8}>
        <Grid item lg={8} sm={12} xs={12}>
          <Typography variant="h6" className={classes.inputTitle}>
            {t("memberManagementPage.educationComputerSkills")}:
          </Typography>
        </Grid>
        <Grid item lg={4}>
          <FormControl>
            <Grid container>
              <Grid item>
                <FormControlLabel
                  style={{ alignContent: "center" }}
                  value={
                    member?.education?.workOnComputer !== null &&
                    member?.education?.workOnComputer === true
                      ? true
                      : false
                  }
                  checked={
                    member?.education?.workOnComputer !== null &&
                    member?.education?.workOnComputer === true
                      ? true
                      : false
                  }
                  control={
                    <Radio
                      className={classes.radioButton}
                      onClick={(e) =>
                        onChange(
                          true,
                          "workOnComputer",
                          MemberSectionType.memberEducation
                        )
                      }
                    />
                  }
                  label={
                    <Typography variant="body1" color="textSecondary">
                      {t("memberManagementPage.educationYes")}
                    </Typography>
                  }
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 14,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  value={
                    member?.education?.workOnComputer !== null &&
                    member?.education?.workOnComputer === false
                      ? true
                      : false
                  }
                  checked={
                    member?.education?.workOnComputer !== null &&
                    member?.education?.workOnComputer === false
                      ? true
                      : false
                  }
                  control={
                    <Radio
                      className={classes.radioButton}
                      onClick={(e) =>
                        onChange(
                          false,
                          "workOnComputer",
                          MemberSectionType.memberEducation
                        )
                      }
                    />
                  }
                  label={
                    <Typography variant="body1" color="textSecondary">
                      {t("memberManagementPage.educationNo")}
                    </Typography>
                  }
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 14,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};
