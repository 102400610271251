import CakeIcon from "@mui/icons-material/Cake";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceIcon from "@mui/icons-material/Place";
import { Avatar, CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Col, InputGroup, Row } from "react-bootstrap";
import { colors } from "../../app/theme/colors";
import { MemberShortDetails } from "../../models/Responses/members/MemberShortDetails";
import { useMemberItemCardStyles } from "./memberItemCardStyles";

interface Props {
  member: MemberShortDetails;
  onClick: () => void;
}

export default function MemberItemCard({ member, onClick }: Props) {
  const { classes } = useMemberItemCardStyles();

  const birthDateFormatted = () => {
    if (member?.birthDate) {
      const date = new Date(member?.birthDate);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };

      return date.toLocaleDateString("sr-RS", options);
    }
  };

  const getInitialsFromFullName = (input: string): string => {
    var names: string[] = input.split(" ");
    let initials: string = "";
    if (names.length > 0) {
      initials =
        names.length === 1
          ? names?.[0][0]?.toUpperCase()
          : names?.[0][0]?.toUpperCase() +
            names?.[names.length - 1][0]?.toUpperCase();
    }

    return initials;
  };

  return (
    <Card className={classes.root} onClick={onClick}>
      <div className={classes.div}>
        <CardHeader
          className={classes.cardHeader}
          title={
            <Typography
              className={classes.cardHeaderText}
            >{`${member?.firstName} ${member?.lastName}`}</Typography>
          }
          avatar={
            <Avatar
              sx={{ bgcolor: "White", color: colors.primary }}
              aria-label="recipe"
            >
              {getInitialsFromFullName(
                `${member?.firstName} ${member?.lastName}`
              )}
            </Avatar>
          }
          subheader={
            <InputGroup>
              <CreditCardIcon className={classes.creditCardIcon} />
              <Typography className={classes.creditCard}>
                {member?.memberCardNumber}
              </Typography>
            </InputGroup>
          }
        />
      </div>
      <CardContent className={classes.cardContent}>
        <Row>
          <Col md="12" sm="12">
            <InputGroup className="mb-3">
              <PlaceIcon className={classes.cardIcon} />
              <Typography className={classes.cardContentText}>
                {member?.address}
              </Typography>
            </InputGroup>
            <InputGroup className="mb-3">
              <PhoneIcon className={classes.cardIcon} />
              <Typography className={classes.cardContentText}>
                {member?.mobilePhone}
              </Typography>
            </InputGroup>
            <InputGroup className="mb-3">
              <CakeIcon className={classes.cardIcon} />
              <Typography className={classes.cardContentText}>
                {birthDateFormatted()}
              </Typography>
            </InputGroup>
          </Col>
        </Row>
      </CardContent>
    </Card>
  );
}
