import { t } from "i18next";
import { TableHeadNamesType } from "../features/table/data/tableHeadNames";

export const InitTableHeader: TableHeadNamesType = {
  actions: [{ action: "memberTablePage.tableActionTitle", visible: true }],
  ordinalNumber: [
    {
      ordinalNumber: "memberTablePage.tableOrdinalNumberTitle",
      visible: true,
    },
  ],
  personalData: [
    {
      firstName: "memberTablePage.tableFirstNameTitle",
      visible: true,
    },
    {
      lastName: "memberTablePage.tableLastNameTitle",
      visible: true,
    },
    {
      parentName: "memberTablePage.tableLastNameParentName",
      visible: false,
    },
    {
      jmbg: "memberTablePage.tableUinTitle",
      visible: false,
    },
    {
      birthDate: "memberTablePage.tableBirthDateTitle",
      visible: true,
    },
    {
      age: "memberTablePage.tableAgeTitle",
      visible: true,
    },
    {
      birthPlace: "memberTablePage.tableBirthPlaceTitle",
      visible: false,
    },
    {
      idNumber: "memberTablePage.tableIdNumberTitle",
      visible: false,
    },
    {
      gender: "memberTablePage.tableGenderTitle",
      visible: false,
    },
  ],
  address: [
    {
      cityName: "memberTablePage.tableCityTitle",
      visible: false,
    },
    {
      streetName: "memberTablePage.tableStreetTitle",
      visible: false,
    },
    {
      homeNumber: "memberTablePage.tableHomeNumberTitle",
      visible: false,
    },
    {
      cityResidenceName: "memberTablePage.tableResidenceCityTitle",
      visible: true,
    },
    {
      streetResidenceName: "memberTablePage.tableResidenceStreetTitle",
      visible: true,
    },
    {
      homeNumberResidence: "memberTablePage.tableResidenceHomeNumberTitle",
      visible: true,
    },
    {
      electionPlaceNumber: "memberTablePage.tableElectionPlaceNumberTitle",
      visible: false,
    },
    {
      electionPlaceName: "memberTablePage.tableElectionPlaceNameTitle",
      visible: false,
    },
    {
      electionPlaceAddress: "memberTablePage.tableElectionPlaceAddressTitle",
      visible: false,
    },
    {
      localCommunityName: "memberTablePage.tableLocalCommunityNameTitle",
      visible: false,
    },
  ],
  card: [
    {
      number: "memberTablePage.tableIdCardNumberTitle",
      visible: false,
    },
    {
      isPublished: "memberTablePage.tableIsCardPublishedTitle",
      visible: false,
    },
    {
      publishingCity: "memberTablePage.tableMemberCardCityTitle",
      visible: false,
    },
    {
      establishedDate: "memberTablePage.tableMemberCardDateTitle",
      visible: false,
    },
  ],
  contact: [
    {
      landPhone: "memberTablePage.tableLandingPhoneTitle",
      visible: false,
    },
    {
      mobilePhone: "memberTablePage.tableMobilePhoneTitle",
      visible: false,
    },
    {
      workPhone: "memberTablePage.tableBusinessPhoneTitle",
      visible: false,
    },
    {
      email: "memberTablePage.tableEmailTitle",
      visible: false,
    },
    {
      facebook: "memberTablePage.tableFacebookTitle",
      visible: false,
    },
    {
      instagram: "memberTablePage.tableInstagramTitle",
      visible: false,
    },
    {
      twiter: "memberTablePage.tableTwitterTitle",
      visible: false,
    },
    {
      other: "memberTablePage.tableOtherContactTitle",
      visible: false,
    },
  ],
  education: [
    {
      elementarySchool: "memberTablePage.tableElementarySchoolTitle",
      visible: false,
    },
    {
      highSchool: "memberTablePage.tableHighSchoolTitle",
      visible: false,
    },
    {
      highSchoolYear: "memberTablePage.tableHighSchoolLevelTitle",
      visible: false,
    },
    {
      basicVocationalStudies: "memberTablePage.tableVocationalStudiesTitle",
      visible: false,
    },
    {
      specialVocationalStudies:
        "memberTablePage.tableSpecialistVocationalStudiesTitle",
      visible: false,
    },
    {
      academicStudies: "memberTablePage.tableBasicAcademicStudiesTitle",
      visible: false,
    },
    {
      academicStudiesYear:
        "memberTablePage.tableBasicAcademicStudiesLevelTitle",
      visible: false,
    },
    {
      masterAcademicStudies: "memberTablePage.tableMasterAcademicStudiesTitle",
      visible: false,
    },
    {
      masterAcademicStudiesYear:
        "memberTablePage.tableMasterAcademicStudiesLevelTitle",
      visible: false,
    },
    {
      specialAcademicStudies:
        "memberTablePage.tableSpecialAcademicStudiesTitle",
      visible: false,
    },
    {
      doctoralAcademicStudies:
        "memberTablePage.tableDoctoralAcademicStudiesTitle",
      visible: false,
    },
    {
      workOnComputer: "memberTablePage.tableWorkOnComputerTitle",
      visible: false,
    },
  ],
  employment: [
    {
      isEmployed: "memberTablePage.tableIsEmployedTitle",
      visible: false,
    },
    {
      company: "memberTablePage.tableCompanyTitle",
      visible: false,
    },
    {
      workPlace: "memberTablePage.tableWorkPlaceTitle",
      visible: false,
    },
    {
      permanentEmployed: "memberTablePage.tableTemporaryEmployed",
      visible: false,
    },
    {
      other: "memberTablePage.tableOtherEmployment",
      visible: false,
    },
  ],
  languages: [
    {
      language: "memberTablePage.tableLanguageSubtitleLanguage",
      visible: false,
    },
    {
      level: "memberTablePage.tableLanguageSubtitleLevel",
      visible: false,
    },
    {
      activeSpeaking: "memberTablePage.tableLanguageSubtitleActive",
      visible: false,
    },
  ],
  otherData: [
    {
      hobby: "memberTablePage.tableHobbyTitle",
      visible: false,
    },
    {
      saintDay: "memberTablePage.tableSaintDay",
      visible: false,
    },
    {
      saintDayDate: "memberTablePage.tableSaintDayDate",
      visible: false,
    },
    {
      recommendation: "memberTablePage.tableRecommendationTitle",
      visible: false,
    },
    {
      note1: "memberTablePage.tableNoteFirstTitle",
      visible: false,
    },
    {
      note2: "memberTablePage.tableNoteSecondTitle",
      visible: false,
    },
    {
      note3: "memberTablePage.tableNoteThirdTitle",
      visible: false,
    },
  ],
};
