import { Container, Grid } from "@mui/material";
import { useState } from "react";
import Header from "../../features/header/Header";
import TableContainer from "../../features/table-container/TableContainer";

const TablePage = () => {
  const [page, setPage] = useState<number>(0);

  return (
    <Container
      style={{
        padding: 0,
        marginTop: "100px",
      }}
    >
      <Grid>
        <Header />
      </Grid>
      <Grid>
        <TableContainer page={page} setPage={setPage} />
      </Grid>
    </Container>
  );
};

export default TablePage;
