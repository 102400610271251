import {
  CssBaseline,
  Typography,
  ThemeProvider,
  Container,
  Grid,
  createTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { colors } from "../../app/theme/colors";
import AppButton from "../../component/button/AppButton";
import { Home } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { appRoutes } from "../../app/router/appRoutes";
import imageLogo from "../../images/Npddd_Logo.png";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#2f4454",
    },
  },
});

export default function NotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" className="mt-5">
        <CssBaseline />

        <Grid container spacing={1} direction="column" alignItems="center">
          <img style={{ width: "250px" }} alt="logo" src={imageLogo} />
        </Grid>

        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            container
            spacing={1}
            direction="column"
            alignItems="center"
            justifyContent="center"
            mb={3}
            mt={5}
          >
            <Typography variant="h6">Stranica nije pronađena.</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            container
            spacing={1}
            direction="column"
            alignItems="center"
            justifyContent="center"
            mb={3}
          >
            <Typography variant="h6">
              <AppButton
                color={colors.primary}
                hover={colors.primary}
                startIcon={<Home />}
                label="Idi na početnu"
                onClick={() => navigate(appRoutes.dashboard)}
              />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
