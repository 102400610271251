import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/redux/hooks";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../component/button/AppButton";
import BasicInput from "../../../component/input/basic-input/BasicInput";
import { Role } from "../../../models/data/accounts/Role";
import { SaveAccountModel } from "../../../models/requests/accounts/saveAccountModel";
import { APIStatus } from "../../../models/types/api/APIStatus";
import { selectStatusSavedAccount } from "../../../slices/accounts/accountSlice";
import { UserFormState } from "../UserManagement";
import { useUserManagementSaveModalStyles } from "./userManagementModalStyles";
import { PersonAdd } from "@mui/icons-material";
import Spinner from "../../../component/spinner/Spinner";

type Props = {
  userFormState: UserFormState;
  setUserFormState: Dispatch<SetStateAction<UserFormState>>;
  account: SaveAccountModel;
  setAccount: Dispatch<SetStateAction<SaveAccountModel>>;
  onSave: () => any;
  roles: Role[] | null;
  accountStatus: any;
};

const UserManagementModal = (props: Props) => {
  const { t } = useTranslation();
  const { classes } = useUserManagementSaveModalStyles();

  const statusSaveAccount = useAppSelector(selectStatusSavedAccount);

  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  const isView = props.userFormState === UserFormState.View;

  const isSaving = statusSaveAccount === APIStatus.PENDING;

  useEffect(() => {
    setValue(props.account.roleName);
  }, [props.account]);

  return (
    <Dialog
      open={props.userFormState !== UserFormState.None}
      onClose={() => props.setUserFormState(UserFormState.None)}
    >
      <DialogTitle className={classes.dialogTitle}>
        <h3 className={classes.title}>
          {t(`userTablePage.userFormState${props.userFormState.toString()}`)}{" "}
          {t("userTablePage.account")}
        </h3>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1} mt={1}>
          <Grid item md={6} xs={12}>
            <BasicInput
              label={t("userTablePage.userTableHeaderFirstName")}
              value={props.account?.firstName}
              onChange={(e) =>
                props.setAccount({
                  ...props.account,
                  firstName: e.target.value,
                })
              }
              readOnly={isView}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <BasicInput
              label={t("userTablePage.userTableHeaderLastName")}
              value={props.account?.lastName}
              onChange={(e) =>
                props.setAccount({ ...props.account, lastName: e.target.value })
              }
              readOnly={isView}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <BasicInput
              label={t("userTablePage.userTableHeaderEmail")}
              value={props.account?.email}
              onChange={(e) =>
                props.setAccount({ ...props.account, email: e.target.value })
              }
              readOnly={isView}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <BasicInput
              label={t("userTablePage.userTableHeaderPhone")}
              value={props.account?.phone}
              onChange={(e) =>
                props.setAccount({ ...props.account, phone: e.target.value })
              }
              readOnly={isView}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            {isView && (
              <BasicInput
                label={t("userTablePage.userTableHeaderRole")}
                value={props.account?.roleName}
                onChange={() => {}}
                readOnly={isView}
              />
            )}
            {!isView && (
              <>
                <Typography variant="body1" className={classes.inputTitle}>
                  {t("userTablePage.userTableHeaderRole")}:
                </Typography>
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue || "");
                      props.setAccount({
                        ...props.account,
                        roleName: newValue || "",
                      });
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    options={props.roles?.map((role) => role.name) || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        className={classes.autocompleteTextField}
                        size="small"
                      />
                    )}
                  />
                </FormControl>
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        className={classes.closeBtnWrapper}
        style={{ float: "right" }}
      >
        <AppButton
          onClick={() => {
            props.setUserFormState(UserFormState.None);
          }}
          label={t("buttons.close")}
          color={colors.primary}
          hover={colors.primary}
        />
        <AppButton
          onClick={props.onSave}
          label={t("buttons.save")}
          startIcon={
            isSaving ? (
              <Spinner style={{ fontSize: "20px", width: "20px" }} />
            ) : (
              <PersonAdd />
            )
          }
          color={colors.green}
          hover={colors.green}
          disabled={isView || isSaving}
        />
      </DialogActions>
    </Dialog>
  );
};

export default UserManagementModal;
