import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../app/theme/colors";

export const useCustomTooltipStyle = makeStyles()((theme: Theme) => ({
    tooltip: {
        padding: theme.spacing(1),
        backgroundColor: '#f5f5f5',
        borderRadius: "10px",
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        pointerEvents: 'none',
        maxWidth: "250px",
        position: 'relative',
        '&:before': {
            content: "''",
            position: 'absolute',
            borderStyle: 'solid',
            borderWidth: '8px 8px 8px 0',
            borderColor: 'transparent #f5f5f5 transparent transparent',
            top: '8px',
            left: '-8px',
        },
    },
    popper: {
        zIndex: theme.zIndex.tooltip,
    },
    paper: {
        padding: theme.spacing(1),
        backgroundColor: colors.primary,
        color: theme.palette.common.white,
        maxWidth: "300px"
    },
    iconButton: {
        top: "-5px",
        left: "-5px"
    },
    paperTitle: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        fontSize: '16px'
    }
}));
