import { api } from "../../app/redux/api";
import { MemberDetailsModel } from "../../models/members/memberDetailsModel";
import { MemberDataManipulationRequest } from "../../models/requests/members/memberDataManipulationRequest";
import { PagedList } from "../../models/Responses/dataManipulations/pagedList";
import { APIResponse } from "../../models/types/api/APIResponse";

const getMembersPagedList = async (request: MemberDataManipulationRequest) => {
  return api.post("/member/list", request) as Promise<
    APIResponse<PagedList<MemberDetailsModel>>
  >;
};

export const MemberApi = {
  GetMembersPagedList: getMembersPagedList,
};
