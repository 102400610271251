enum MemberSectionType {
  memberPersonalData = 10,
  memberAddress = 20,
  memberCard = 30,
  memberContact = 40,
  memberEducation = 50,
  memberEmpoyment = 60,
  memberLanguage = 70,
  memberOtherData = 80,
}

export default MemberSectionType;
