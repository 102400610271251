import {
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { TableHeadNamesType } from "../data/tableHeadNames";
import { isAllVisible, isAtLeastOneVisible } from "../helpers/helperMethods";

type Props = {
  array: any[];
  arrayName: string;
  tableHeadNames: TableHeadNamesType;
  setTableHeadNames: Dispatch<SetStateAction<TableHeadNamesType>>;
  selectAllLabelName: string;
};

const ColumnCheckBoxes = (props: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={isAllVisible(props.array)}
            indeterminate={
              !isAllVisible(props.array) && isAtLeastOneVisible(props.array)
            }
            onChange={(e) => {
              const updTableHeadNames = props.array.map((element) => {
                element.visible = e.target.checked;

                return element;
              });

              props.setTableHeadNames({
                ...props.tableHeadNames,
                [props.arrayName]: updTableHeadNames as typeof props.array,
              });
            }}
          />
        }
        label={`${props.selectAllLabelName}`}
      />

      {props.array.map((type, i) =>
        Object.values(type).map(
          (item, j) =>
            typeof item === "string" && (
              <Grid item xs={12} md={6} key={`${props.arrayName}-${i}-${j}`}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={type.visible}
                        onChange={(e) => {
                          const updTableHeadNames = props.array.map(
                            (element) => {
                              if (element[Object.keys(type)[0]]) {
                                element.visible = e.target.checked;
                              }

                              return element;
                            }
                          );

                          props.setTableHeadNames({
                            ...props.tableHeadNames,
                            [props.arrayName]:
                              updTableHeadNames as typeof props.array,
                          });
                        }}
                      />
                    }
                    label={t(item)}
                  />
                </FormGroup>
              </Grid>
            )
        )
      )}
    </>
  );
};

export default ColumnCheckBoxes;
