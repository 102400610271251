import { Account } from "../../models/auth/account";
import { Role } from "../../models/data/accounts/Role";
import { City } from "../../models/data/addresses/City";
import ElectionPlace from "../../models/data/addresses/electionPlaceModel";
import ElectionPlaceRegion from "../../models/data/addresses/electionPlaceRegion";
import { Street } from "../../models/data/addresses/Street";
import { Member } from "../../models/data/members/Member";
import { MemberPersonalData } from "../../models/data/members/MemberPersonalData";
import { ForgotPasswordRequest } from "../../models/requests/auth/forgotPasswordRequest";
import { RequestForgotPasswordRequest } from "../../models/requests/auth/requestForgotPasswordRequest";
import { FindElectionPlaceRequest } from "../../models/requests/election-places/FindElectionPlaceRequest";
import { FilterMembersBasicDetails } from "../../models/requests/filterMembersBasicDetails";
import { UpdateAccountRequest } from "../../models/requests/updateAccountRequest";
import { MemberBasicDetails } from "../../models/Responses/members/memberBasicDetails";
import { MemberDetailsDashboard } from "../../models/Responses/members/memberDetailsDashboard";
import { MemberShortDetails } from "../../models/Responses/members/MemberShortDetails";
import StatisticModel from "../../models/statistic/statisticModel";
import { APIResponse } from "../../models/types/api/APIResponse";
import { UserAccountDetails } from "./../../models/account/userAccountDetails";
import { FilterMembers } from "./../../models/requests/filterMembers";
import { api } from "./api";

// Account

export const resetPassword = async (request: any) => {
  return api.post("/auth/reset-password", request) as Promise<
    APIResponse<Account>
  >;
};

export const requestForgotPassword = async (
  request: RequestForgotPasswordRequest
) => {
  return api.post("/auth/request-forgot-password", request) as Promise<
    APIResponse<string>
  >;
};

export const forgotPassword = async (request: ForgotPasswordRequest) => {
  return api.post("/auth/forgot-password", request) as Promise<
    APIResponse<Account>
  >;
};

export const createNewAccount = async (account: UserAccountDetails) => {
  return api.post("/accounts", account) as Promise<
    APIResponse<UserAccountDetails>
  >;
};

export const updateAccountData = async (
  updateAccountRequest: UpdateAccountRequest
) => {
  return api.put("/accounts", updateAccountRequest) as Promise<
    APIResponse<UserAccountDetails>
  >;
};

export const getAccountByEmail = async (email: string) => {
  return api.get("/accounts/" + email) as Promise<
    APIResponse<UserAccountDetails>
  >;
};

// Card Number

export const isAvailableCardNumber = async (
  cardNumber: number,
  memberCardId: string | null
) => {
  return api.get(
    `/member-card/is-available-card-number?cardNumber=${cardNumber}${
      memberCardId !== null ? `&memberCardId=${memberCardId}` : ``
    }`
  ) as Promise<APIResponse<boolean>>;
};
export const getAvailableCardNumbers = async () => {
  return api.get("/member-card/available-card-numbers") as Promise<
    APIResponse<number[]>
  >;
};
export const getNextCardNumber = async () => {
  return api.get("/member-card/available_card_number") as Promise<
    APIResponse<number>
  >;
};

// Get Roles

export const getRoles = async () => {
  return api.get("/roles") as Promise<APIResponse<Role[]>>;
};

// Get Address

export const getCities = async () => {
  return api.get("/city") as Promise<APIResponse<City[]>>;
};

export const getStreetsByCityId = async (cityId: string) => {
  return api.get("/street/city/" + cityId) as Promise<APIResponse<Street[]>>;
};

export const getResidenceStreetsByCityId = async (cityId: string) => {
  return api.get("/street/city/" + cityId) as Promise<APIResponse<Street[]>>;
};

export const getStreets = async () => {
  return api.get("/street") as Promise<APIResponse<Street[]>>;
};

// Get Member

export const getMemberFullDetailsById = async (id: string) => {
  return api.get(`/member/details/${id}`) as Promise<APIResponse<Member>>;
};

export const getMemberDetailsDashboard = async (filter: FilterMembers) => {
  return api.post("/member/details-dashboard", filter) as Promise<
    APIResponse<MemberDetailsDashboard[]>
  >;
};

export const getMemberBasicDetails = async (
  filter: FilterMembersBasicDetails
) => {
  return api.post("/member/basic-details", filter) as Promise<
    APIResponse<MemberBasicDetails[]>
  >;
};

// Get Election Places
export const getElectionPlaceRegionsByCityId = async (cityId: string) => {
  return api.get(`/election-place-region/city-id?cityId=${cityId}`) as Promise<
    APIResponse<ElectionPlaceRegion[]>
  >;
};
export const getElectionPlaceById = async (id: string) => {
  return api.get(`/election-place/${id}`) as Promise<
    APIResponse<ElectionPlace>
  >;
};
export const getElectionPlacesByCityId = async (cityId: string) => {
  return api.get(`/election-place/city-id?cityId=${cityId}`) as Promise<
    APIResponse<ElectionPlace[]>
  >;
};
export const getElectionPlaceByAddress = async (
  filter: FindElectionPlaceRequest
) => {
  return api.post(
    "/election-place/find-by-city-id-and-region",
    filter
  ) as Promise<APIResponse<ElectionPlace>>;
};

// Create Member
export const saveMember = async (member: Member) => {
  return api.put("/member/save-member", member) as Promise<APIResponse<Member>>;
};

// Delete Member

export const deleteMember = async (id: string) => {
  return api.delete(`/member-personal-data/${id}`) as Promise<
    APIResponse<MemberPersonalData>
  >;
};

// Statistic

export const getStatistic = async () => {
  return api.get(`/statistic`) as Promise<StatisticModel>;
};
