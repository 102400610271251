import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import {
  getAccounts,
  getRoles,
  selectAccounts,
  selectAccountsStatus,
  selectRoles
} from "../../../slices/accounts/accountSlice";

export const useGetAccounts = () => {
  const dispatch = useAppDispatch();
  const accounts = useAppSelector(selectAccounts);
  const accountsStatus = useAppSelector(selectAccountsStatus);

  useEffect(() => {
    refetchAccounts();
  }, []);

  const refetchAccounts = async () => await dispatch(getAccounts());

  return { accounts, refetchAccounts, accountsStatus };
};

export const useGetRoles = () => {
  const dispatch = useAppDispatch();
  const roles = useAppSelector(selectRoles);

  useEffect(() => {
    refetch();
  }, []);

  const refetch = async () => await dispatch(getRoles());

  return { roles, refetch };
};
