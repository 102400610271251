import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogContent, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { User } from "../../../models/data/accounts/user";
import "./myProfileModal.css";
import { useMyProfileModalStyles } from "./myProfileModalStyles";

interface Props {
  open: any;
  modalClose: any;
  currentUser: User | null;
}

export default function MyProfileModal({
  open,
  modalClose,
  currentUser: currentProfile,
}: Props) {
  const { t } = useTranslation();
  const { classes } = useMyProfileModalStyles();

  return (
    <Dialog onClose={modalClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle id="myProfileModal" className={classes.dialogTitle}>
        <Grid className={classes.grid}>
          <CloseIcon onClick={(e) => modalClose(e)} />
        </Grid>
        <h3 style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
          {t("myProfileData.myProfile")}
        </h3>
      </DialogTitle>
      <DialogContent>
        <Box
          className="shadow p-3 mb-2 bg-white rounded"
          style={{ borderRadius: "5px" }}
        >
          <Grid container>
            <Grid item md={12} sm={12}>
              <InputGroup>
                <Grid item md={4}>
                  <Typography
                    variant="body1"
                    className={classes.typographyText}
                  >
                    {t("myProfileData.firstName")}:
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography className={classes.typographyText_content}>
                    {currentProfile?.firstName}
                  </Typography>
                </Grid>
              </InputGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12} sm={12}>
              <InputGroup>
                <Grid item md={4}>
                  <Typography
                    variant="body1"
                    className={classes.typographyText}
                  >
                    {t("myProfileData.lastName")}:
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography className={classes.typographyText_content}>
                    {currentProfile?.lastName}
                  </Typography>
                </Grid>
              </InputGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12} sm={12}>
              <InputGroup>
                <Grid item md={4}>
                  <Typography
                    variant="body1"
                    className={classes.typographyText}
                  >
                    {t("myProfileData.email")}:
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography className={classes.typographyText_content}>
                    {currentProfile?.email}
                  </Typography>
                </Grid>
              </InputGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12} sm={12}>
              <InputGroup>
                <Grid item md={4}>
                  <Typography
                    variant="body1"
                    className={classes.typographyText}
                  >
                    {t("myProfileData.phoneNumber")}:
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography className={classes.typographyText_content}>
                    {currentProfile?.phone}
                  </Typography>
                </Grid>
              </InputGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12} sm={12}>
              <InputGroup>
                <Grid item md={4}>
                  <Typography
                    variant="body1"
                    className={classes.typographyText}
                  >
                    {t("myProfileData.role")}:
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography className={classes.typographyText_content}>
                    {currentProfile?.role}
                  </Typography>
                </Grid>
              </InputGroup>
            </Grid>
          </Grid>
        </Box>
        <Row>
          <Grid item md={12} sm={12} style={{ textAlign: "center" }}>
            <Button className={classes.btnCloseModal} onClick={modalClose}>
              {t("myProfileData.close")}
            </Button>
          </Grid>
        </Row>
      </DialogContent>
    </Dialog>
  );
}
