import {
  AddCircleOutline,
  Circle,
  PersonAdd,
  PersonRemove,
  RemoveCircleOutline,
} from "@mui/icons-material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { LightTooltip } from "../../../component/tooltip/lightTooltip";
import { Role, RoleOrdinal } from "../../../models/enums/roles/roles";
import { SaveAccountModel } from "../../../models/requests/accounts/saveAccountModel";
import { AccountResponse } from "../../../models/Responses/accounts/accountResponse";
import { selectAccount } from "../../../slices/accounts/accountSlice";
import { selectRole } from "../../../slices/auth/authSlice";
import { UserFormState } from "../UserManagement";
import { useUserManagementStyle } from "../userManagementStyle";

type UserManagementTableProps = {
  setAccount: Dispatch<SetStateAction<SaveAccountModel>>;
  accounts: AccountResponse[] | null;
  setUserFormState: Dispatch<SetStateAction<UserFormState>>;
  onToggleIsActive: (acc: AccountResponse) => any;
  onToggleIsDeleted: (acc: AccountResponse) => any;
};

const UserManagementTable = (props: UserManagementTableProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { classes } = useUserManagementStyle();

  const role = useAppSelector(selectRole);

  const prefix = "userTablePage.userTableHeader";
  const tableHeadNames: string[] = [
    t(`${prefix}Action`),
    t(`${prefix}OridinalNumber`),
    t(`${prefix}FirstName`),
    t(`${prefix}LastName`),
    t(`${prefix}Email`),
    t(`${prefix}Phone`),
    t(`${prefix}Role`),
    t(`${prefix}IsActive`),
  ];

  return (
    <TableContainer>
      <Table id="user" size="small">
        <TableHead className={classes.tableHead}>
          {tableHeadNames.map((name, i) => (
            <TableCell key={i} className={classes.tableHeadTitle}>
              {name}
            </TableCell>
          ))}

          {role === RoleOrdinal.SuperUser && (
            <TableCell className={classes.tableHeadTitle}>
              {t("userTablePage.userTableHeaderIsDelete")}
            </TableCell>
          )}
        </TableHead>
        <TableBody>
          {props.accounts &&
            props.accounts?.map((acc: AccountResponse, i: number) => {
              return (
                <TableRow key={i}>
                  <TableCell>
                    <Grid
                      container
                      flexWrap={"nowrap"}
                      justifyContent={"center"}
                    >
                      <Grid
                        item
                        xs={3}
                        style={{
                          marginRight: "1rem",
                        }}
                      >
                        {((role === RoleOrdinal.SuperUser) || (role === RoleOrdinal.Administrator && acc.roleName !== Role.Administrator)) &&
                        <LightTooltip
                          title={t("tooltips.tableActionRowsUserEdit")}
                          placement="left"
                          arrow
                        >
                          <CreateOutlinedIcon
                            onClick={() => {
                              dispatch(selectAccount(acc));
                              props.setAccount(acc);
                              props.setUserFormState(UserFormState.Edit);
                            }}
                            name="createIcon"
                            style={{ color: "#00B2FF", cursor: "pointer" }}
                          />
                        </LightTooltip>}
                      </Grid>

                      <Grid
                        item
                        xs={3}
                        style={{
                          marginRight: "1rem",
                        }}
                      >
                        <LightTooltip
                          title={t("tooltips.tableActionRowsUserView")}
                          placement="top"
                          arrow
                        >
                          <VisibilityOutlinedIcon
                            style={{
                              color: "#3C7F9C",
                              cursor: "pointer",
                            }}
                            className="accountTable_visibility_icon"
                            onClick={() => {
                              dispatch(selectAccount(acc));
                              props.setAccount(acc);
                              props.setUserFormState(UserFormState.View);
                            }}
                          />
                        </LightTooltip>
                      </Grid>

                      <Grid
                        item
                        xs={3}
                        onClick={() => {
                          props.onToggleIsActive(acc);
                        }}
                        className={classes.icons}
                        style={{
                          marginRight:
                            role === RoleOrdinal.SuperUser ? "1rem" : "0rem",
                        }}
                      >
                        <LightTooltip
                          title={
                            acc.isActive
                              ? t("tooltips.tableActionRowsUserDeactivate")
                              : t("tooltips.tableActionRowsUserActivate")
                          }
                          placement="right"
                        >
                          {acc.isActive ? (
                            <>{((role === RoleOrdinal.SuperUser) || (role === RoleOrdinal.Administrator && acc.roleName !== Role.Administrator)) && <RemoveCircleOutline color={"error"} />}</>
                          ) : (
                            <AddCircleOutline color={"primary"} />
                          )}
                        </LightTooltip>
                      </Grid>

                      {role === RoleOrdinal.SuperUser && (
                        <Grid
                          item
                          xs={3}
                          onClick={() => {
                            props.onToggleIsDeleted(acc);
                          }}
                          className={classes.icons}
                        >
                          <LightTooltip
                            title={
                              acc.isDeleted
                                ? t("tooltips.tableActionRowsUserGetBack")
                                : t("tooltips.tableActionRowsUserRemove")
                            }
                            placement="right"
                          >
                            {acc.isDeleted ? (
                              <PersonAdd color={"primary"} />
                            ) : (
                              <PersonRemove color={"error"} />
                            )}
                          </LightTooltip>
                        </Grid>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>{acc.firstName}</TableCell>
                  <TableCell>{acc.lastName}</TableCell>
                  <TableCell>{acc.email}</TableCell>
                  <TableCell>{acc.phone}</TableCell>
                  <TableCell>{acc.roleName}</TableCell>
                  <TableCell className={classes.tableCellIsActive}>
                    {acc.isActive ? (
                      <Circle color="success" />
                    ) : (
                      <Circle color="error" />
                    )}
                  </TableCell>
                  {role === RoleOrdinal.SuperUser && (
                    <TableCell className={classes.tableCellIsActive}>
                      {acc.isDeleted ? (
                        <Circle color="success" />
                      ) : (
                        <Circle color="error" />
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserManagementTable;
