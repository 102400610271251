import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import BasicInput from "../../component/input/basic-input/BasicInput";
import { FilterMembers } from "../../models/requests/filterMembers";
import { APIStatus } from "../../models/types/api/APIStatus";
import { selectDisplayLanguage } from "../../slices/displayLanguages/displayLanguageSlice";
import {
  getMemberDetailsDashboard,
  getMemberFullDetailsById,
  selectMemberDetailsDashboard,
  selectNextCardNumber,
  selectStatusGetMemberDetailsDashboard,
} from "../../slices/members/memberSlice";
import TableViewModal from "../table/modal/viewTableDataModal/viewTableDataModal";
import MemberItemCard from "./MemberItemCard";
import { useMemberListStyles } from "./memberListStyles";

const INIT_FILTER_MEMBERS: FilterMembers = {
  firstName: "",
  lastName: "",
  jmbg: "",
  phone: "",
  cardNumber: "",
};

export default function Dashboard() {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { classes } = useMemberListStyles();

  const displayLanguage = useAppSelector(selectDisplayLanguage);
  const nextCardNumber = useAppSelector(selectNextCardNumber);
  const memberDetailsDashboard = useAppSelector(selectMemberDetailsDashboard);
  const statusGetMemberDetailsDashboard = useAppSelector(
    selectStatusGetMemberDetailsDashboard
  );

  const [memberDashboardFilter, setMemberDashboardFilter] =
    useState<FilterMembers>(INIT_FILTER_MEMBERS);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [currentlySelectedMemberId, setCurrentlySelectedMemberId] =
    useState("");

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const onChange = async (value: string, name: keyof FilterMembers) => {
    const updMemberDashboardFilter = {
      ...memberDashboardFilter,
      [name]: value,
    };
    setMemberDashboardFilter({ ...updMemberDashboardFilter });

    // If it's name == cardNumber
    // Filter immediately
    // Otherwise filter when number of characters is >= 3
    if (
      value.trim().length === 0 ||
      value.trim().length >= 3 ||
      name === "cardNumber"
    ) {
      await dispatch(
        getMemberDetailsDashboard({ ...updMemberDashboardFilter })
      );
    }
  };

  // Refetch on change display language
  useEffect(() => {
    dispatch(getMemberDetailsDashboard({ ...INIT_FILTER_MEMBERS }));
  }, [displayLanguage]);

  return (
    <>
      <Container>
        <Typography className={classes.cardNumber}>
          {t("dashboardPage.nextCardNumberLabel")}: {nextCardNumber}
        </Typography>
      </Container>

      <Container>
        <Accordion style={{ borderRadius: "0.5rem" }}>
          <AccordionSummary
            className={classes.header}
            expandIcon={<ExpandMore style={{ color: "white" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              {t("dashboardPage.accordionHeader")}
            </Typography>
          </AccordionSummary>

          <Grid container spacing={3} padding={"1rem"}>
            <Grid item md={6} sm={12}>
              <Typography variant="body1" className={classes.typographyText}>
                {t("dashboardPage.accordionTitle")}
              </Typography>
              <Typography variant="subtitle2">
                {t("dashboardPage.accordionSubtitle")}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={3} padding={"1rem"}>
            <Grid item md={6} sm={12} xs={12}>
              <BasicInput
                value={memberDashboardFilter.firstName}
                onChange={(e) => onChange(e.target.value, "firstName")}
                label={t("dashboardPage.accordionPlaceholderFirstName")}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <BasicInput
                value={memberDashboardFilter.lastName}
                onChange={(e) => onChange(e.target.value, "lastName")}
                label={t("dashboardPage.accordionPlaceholderLastName")}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} padding={"1rem"}>
            <Grid item md={6} sm={12} xs={12}>
              <BasicInput
                value={memberDashboardFilter.jmbg}
                onChange={(e) => onChange(e.target.value, "jmbg")}
                label={t("dashboardPage.accordionPlaceholderJmbg")}
              />
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <BasicInput
                value={memberDashboardFilter.phone}
                onChange={(e) => onChange(e.target.value, "phone")}
                label={t("dashboardPage.accordionPlaceholderPhone")}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <BasicInput
                value={memberDashboardFilter.cardNumber}
                onChange={(e) => onChange(e.target.value, "cardNumber")}
                label={t("dashboardPage.accordionPlaceholderCardNumber")}
              />
            </Grid>
          </Grid>
        </Accordion>
        <Row style={{ padding: "1rem" }}>
          {statusGetMemberDetailsDashboard === APIStatus.PENDING ? (
            <Grid style={{ padding: "20px" }}>
              <span>
                <CircularProgress
                  size={70}
                  className={classes.circularProgress}
                />
              </span>
            </Grid>
          ) : (
            <>
              {memberDetailsDashboard?.length === 0 ? (
                <p>{t("common.noResults")}.</p>
              ) : (
                memberDetailsDashboard?.map((item) => (
                  <Col
                    lg="4"
                    md="6"
                    sm="12"
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                    key={item.id}
                  >
                    <MemberItemCard
                      member={item}
                      onClick={() => {
                        item.id && setCurrentlySelectedMemberId(item.id);

                        if (item.id && item.id !== "") {
                          dispatch(getMemberFullDetailsById(item.id));
                        }

                        toggleModal();
                      }}
                    />
                  </Col>
                ))
              )}
            </>
          )}
        </Row>
      </Container>

      <TableViewModal handleClose={toggleModal} isOpen={isOpenModal} />
    </>
  );
}
