import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { Grid } from "@mui/material";
import { t } from "i18next";
import { useAppSelector } from "../../../app/redux/hooks";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../component/button/AppButton";
import { Member } from "../../../models/data/members/Member";
import { APIStatus } from "../../../models/types/api/APIStatus";
import {
  selectDeleteMemberStatus,
  selectSaveMemberStatus,
} from "../../../slices/members/memberSlice";
import { CanSaveMember, IsNewMember } from "../../../utilis/MemberValidations";
import { EmptyGuid } from "../initialData";
import { useMenageMmeberButtonStyles } from "./menageMemberButtonsStyles";

interface Props {
  onSave: any;
  onClear: any;
  onDelete: any;
  member: Member;
}

export const MenageMemberButtons = ({
  onSave,
  onDelete,
  onClear,
  member,
}: Props) => {
  const { classes } = useMenageMmeberButtonStyles();
  const saveMemberStatus = useAppSelector(selectSaveMemberStatus);
  const deleteMemberStatus = useAppSelector(selectDeleteMemberStatus);

  return (
    <Grid
      container
      component="div"
      style={{ marginBottom: "20px", marginTop: "20px" }}
    >
      <hr className={classes.divider} />
      <Grid container justifyContent="flex-start">
        <Grid item>
          <AppButton
            onClick={onClear}
            startIcon={<CleaningServicesIcon fontSize="small" />}
            color={colors.primary}
            hover={colors.primary}
            label={t("buttons.dashboardClearFields")}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item marginRight={"10px"}>
          <AppButton
            label={t("buttons.dashboardDeleteMember")}
            startIcon={<PersonRemoveIcon fontSize="small" />}
            onClick={onDelete}
            disabled={
              (member?.personalData?.id === EmptyGuid ? true : false) ||
              deleteMemberStatus === APIStatus.PENDING
            }
            color={colors.red}
            hover={colors.red}
          />
        </Grid>
        <Grid item>
          <AppButton
            disabled={
              !CanSaveMember(member) || saveMemberStatus === APIStatus.PENDING
            }
            startIcon={
              IsNewMember(member) ? (
                <PersonAddIcon fontSize="small" />
              ) : (
                <PersonIcon fontSize="small" />
              )
            }
            onClick={onSave}
            color={colors.green}
            hover={colors.green}
            label={
              IsNewMember(member)
                ? t("buttons.dashboardAddMember")
                : t("buttons.dashboardUpdateMember")
            }
          />
        </Grid>
      </Grid>
      <hr className={classes.divider} />
    </Grid>
  );
};
