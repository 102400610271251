import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RootState } from "../../app/redux/store";
import { ReportBy } from "../../models/enums/reportBy";
import { ReportType } from "../../models/enums/reportType";
import { APIError } from "../../models/types/api/APIError";
import { APIStatus } from "../../models/types/api/APIStatus";
import { CreateReportRequest } from "../../models/requests/reports/createReportRequest";
import { ReportApi } from "./reportApi";

type ReportState = {
  report: Blob | null;
  reportType: ReportType | null;
  reportBy: ReportBy | null;

  statuses: {
    reportStatus: string;
  };
};

const initialState: ReportState = {
  report: null,
  reportType: null,
  reportBy: null,

  statuses: {
    reportStatus: APIStatus.IDLE,
  },
};

type CreateReportWithToast = {
  request: CreateReportRequest;
  messages: { pending: string; error: string; success: string };
};
export const createReportWithToast = createAsyncThunk(
  "Report/Create",
  async (create: CreateReportWithToast, { rejectWithValue }) => {
    const {
      request,
      messages: { pending, error, success },
    } = create;

    try {
      const result = await toast.promise(
        ReportApi.CreateReport(request),
        {
          pending,
          error,
          success,
        },
        { position: toast.POSITION.BOTTOM_LEFT, autoClose: 4000 }
      );

      return result;
    } catch (error) {
      return rejectWithValue(error as APIError);
    }
  }
);

export const setReportTypeAndBy = createAsyncThunk(
  "Report/SetTypeAndBy",
  async ({ type, by }: { type: ReportType; by: ReportBy }) => {
    return { type, by };
  }
);

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create Report
      .addCase(createReportWithToast.pending, (state) => {
        state.statuses.reportStatus = APIStatus.PENDING;
      })
      .addCase(createReportWithToast.fulfilled, (state, action) => {
        state.statuses.reportStatus = APIStatus.FULFILLED;
        state.report = action.payload;
      })
      .addCase(createReportWithToast.rejected, (state) => {
        state.statuses.reportStatus = APIStatus.REJECTED;
      })

      .addCase(setReportTypeAndBy.fulfilled, (state, action) => {
        state.reportBy = action.payload.by;
        state.reportType = action.payload.type;
      });
  },
});

export const selectReport = (state: RootState) => state.report.report;
export const selectReportType = (state: RootState) => state.report.reportType;
export const selectReportBy = (state: RootState) => state.report.reportBy;

export const selectReportStatus = (state: RootState) =>
  state.report.statuses.reportStatus;

export default reportSlice.reducer;
