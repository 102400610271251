import { Container, Grid, Theme } from "@mui/material";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import Header from "../../features/header/Header";
import Statistic from "../../features/statistic/statistic";
import { getAllStatistic, selectStatistic } from "../../slices/members/memberSlice";
import { useEffect } from "react";
import { useAppDispatch } from "../../app/redux/hooks";

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: 0,
    marginTop: 70,
    [theme.breakpoints.down("xs")]: {
      marginTop: "125px",
    },
  },
  grid: {
    marginTop: "40px",
  },
  cardNumber: {
    textAlign: "right",
    color: "GrayText",
  },
}));

const StatisticPage = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const statisticDetails = useSelector(selectStatistic);

  useEffect(() => {
    if (statisticDetails) {
      return;
    }

    dispatch(getAllStatistic());
  }, [statisticDetails]);

  return (
    <Container className={classes.container}>
      <Grid>
        <Header />
      </Grid>
      <Grid style={{ marginTop: "20px" }} item xs={12} sm={12}>
        <Statistic statisticDetails={statisticDetails} />
      </Grid>
    </Container>
  );
};

export default StatisticPage;
