import { CleaningServices } from "@mui/icons-material";
import {
  Chip,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../app/theme/colors";
import AppButton from "../../component/button/AppButton";
import { initialDataTableContainer } from "../../INITIAL_DATA/table-container/initialDataTableContainer";
import { MemberDataManipulationRequest } from "../../models/requests/members/memberDataManipulationRequest";
import { MemberFilterDto } from "../../models/requests/members/memberFilterDto";
import { useActiveFillterStyles } from "./activeFilterStyles";

type Props = {
  filter: MemberFilterDto;
  setFilter: Dispatch<SetStateAction<MemberFilterDto>>;
  refetchMemberPagedList: (request: MemberDataManipulationRequest) => any;
  dataManipulation: MemberDataManipulationRequest;
  setDataManipulation: Dispatch<SetStateAction<MemberDataManipulationRequest>>;
  setFilterProperty: any;
  setFilterValue: any;
};

function isEmpty(obj: any) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }
  return true;
}

const ActiveFilters = ({
  filter,
  setFilter,
  refetchMemberPagedList,
  dataManipulation,
  setDataManipulation,
  setFilterValue,
  setFilterProperty,
}: Props) => {
  const { t } = useTranslation();
  const { classes } = useActiveFillterStyles();
  const [isListVisible, setIsListVisible] = useState(false);

  const onChipClick = () => {
    if (isListVisible) {
      setIsListVisible(false);
    } else {
      setIsListVisible(true);
    }
  };

  const handleListStringFilters = (
    filter: MemberFilterDto,
    filterName: string,
    filterList: string
  ) => {
    const handleDeleteOneStringFilter = (i: number) => {
      const updateFilter = { ...filter };

      if (updateFilter[filterName]) {
        if (updateFilter[filterName][filterList]) {
          updateFilter[filterName][filterList] = [
            ...updateFilter[filterName][filterList],
          ].filter((t, j) => i !== j);

          if (updateFilter[filterName][filterList].length === 0) {
            delete updateFilter[filterName][filterList];

            if (isEmpty(updateFilter[filterName])) {
              delete updateFilter[filterName];
            }
          }
        }
      }

      setFilter({
        ...updateFilter,
      });

      refetchMemberPagedList({
        ...dataManipulation,
        memberFilter: { ...updateFilter },
      });
      setDataManipulation({
        ...dataManipulation,
        memberFilter: { ...updateFilter },
      });
    };

    return filter?.[filterName][filterList].map((fn: any, i: number) => (
      <ListItem>
        <Chip
          variant="outlined"
          color="default"
          size="small"
          style={{ border: "0px solid black" }}
          label={fn}
          onDelete={() => handleDeleteOneStringFilter(i)}
        />
      </ListItem>
    ));
  };
  const multipleValuesFilter = (
    label: string,
    filterName: string,
    filterList: string
  ) => {
    const handleDeleteAllSelectedStringFilters = (
      filter: MemberFilterDto,
      filterName: string,
      filterList: string
    ) => {
      const newFilter = { ...filter };

      delete newFilter[filterName][filterList];

      if (isEmpty(newFilter[filterName])) {
        delete newFilter[filterName];
      }

      setFilter({
        ...newFilter,
      });

      refetchMemberPagedList({
        ...dataManipulation,
        memberFilter: { ...newFilter },
      });

      setDataManipulation({
        ...dataManipulation,
        memberFilter: { ...newFilter },
      });

      setIsListVisible(false);
    };
    return (
      <>
        {filter?.[filterName] && filter?.[filterName][filterList] && (
          <Grid item>
            <Chip
              variant="outlined"
              size="medium"
              label={label}
              onClick={onChipClick}
              onDelete={() =>
                handleDeleteAllSelectedStringFilters(
                  filter,
                  filterName,
                  filterList
                )
              }
            />
            {isListVisible && (
              <List className={classes.list}>
                {handleListStringFilters(filter, filterName, filterList)}
              </List>
            )}
          </Grid>
        )}
      </>
    );
  };

  const handleListDateOrNumberFilters = (
    filter: MemberFilterDto,
    filterName: string,
    minNumberOrDate: string,
    maxNumberOrDate: string,
    handleDeleteDateOrIntFilter: any
  ) => {
    return (
      <>
        {filter?.[filterName][minNumberOrDate] && (
          <ListItem>
            <Chip
              variant="outlined"
              color="default"
              size="small"
              style={{ border: "0px solid black" }}
              label={
                <Grid style={{ display: "flex" }}>
                  <Typography>
                    {t("common.from")}:&nbsp;
                    {filter[filterName][minNumberOrDate]}&nbsp;-
                  </Typography>
                  <Typography>
                    &nbsp;{t("common.to")}:&nbsp;
                    {filter[filterName][maxNumberOrDate]}
                  </Typography>
                </Grid>
              }
              onDelete={handleDeleteDateOrIntFilter}
            />
          </ListItem>
        )}
      </>
    );
  };
  const dateOrNumberFilter = (
    label: string,
    filterName: string,
    minNumberOrDate: string,
    maxNumberOrDate: string
  ) => {
    const handleDeleteDateOrIntFilter = () => {
      const updateFilter = { ...filter };

      if (updateFilter[filterName]?.[minNumberOrDate]) {
        delete updateFilter[filterName][minNumberOrDate];

        if (isEmpty(updateFilter[filterName])) {
          delete updateFilter[filterName];
        }
      }

      if (updateFilter[filterName]?.[maxNumberOrDate]) {
        delete updateFilter[filterName][maxNumberOrDate];

        if (isEmpty(updateFilter[filterName])) {
          delete updateFilter[filterName];
        }
      }

      setFilter({
        ...updateFilter,
      });

      refetchMemberPagedList({
        ...dataManipulation,
        memberFilter: { ...updateFilter },
      });

      setDataManipulation({
        ...dataManipulation,
        memberFilter: { ...updateFilter },
      });
    };

    return (
      <>
        {filter?.[filterName] &&
          filter[filterName][minNumberOrDate] &&
          filter[filterName][maxNumberOrDate] && (
            <Grid item>
              <Chip
                variant="outlined"
                size="medium"
                label={`${label} (${t("common.between")})`}
                onClick={onChipClick}
                onDelete={handleDeleteDateOrIntFilter}
              />
              {isListVisible && (
                <List className={classes.list}>
                  {handleListDateOrNumberFilters(
                    filter,
                    filterName,
                    minNumberOrDate,
                    maxNumberOrDate,
                    handleDeleteDateOrIntFilter
                  )}
                </List>
              )}
            </Grid>
          )}
      </>
    );
  };

  return (
    <>
      <Container>
        <Grid
          container
          spacing={1}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {(filter.hasOwnProperty("personalDataFilter") ||
            filter.hasOwnProperty("addressFilter") ||
            filter.hasOwnProperty("cardFilter") ||
            filter.hasOwnProperty("contactFilter") ||
            filter.hasOwnProperty("educationFilter") ||
            filter.hasOwnProperty("employmentFilter") ||
            filter.hasOwnProperty("languageFilter") ||
            filter.hasOwnProperty("otherDataFilter")) && (
            <Grid item xs={12}>
              <Typography variant="body1">Filteri:</Typography>
            </Grid>
          )}

          <Grid item xs display={"flex"} gap={1}>
            {/* Personal data filters */}
            {multipleValuesFilter(
              t("memberTablePage.tableFirstNameTitle"),
              "personalDataFilter",
              "firstNames"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableLastNameTitle"),
              "personalDataFilter",
              "lastNames"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableLastNameParentName"),
              "personalDataFilter",
              "parentNames"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableUinTitle"),
              "personalDataFilter",
              "jmbgs"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableBirthDateTitle"),
              "personalDataFilter",
              "birthDates"
            )}
            {dateOrNumberFilter(
              t("memberTablePage.tableBirthDateTitle"),
              "personalDataFilter",
              "minBirthDate",
              "maxBirthDate"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableAgeTitle"),
              "personalDataFilter",
              "ages"
            )}
            {dateOrNumberFilter(
              t("memberTablePage.tableAgeTitle"),
              "personalDataFilter",
              "minAge",
              "maxAge"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableBirthPlaceTitle"),
              "personalDataFilter",
              "birthPlaces"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableIdNumberTitle"),
              "personalDataFilter",
              "idNumbers"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableGenderTitle"),
              "personalDataFilter",
              "genders"
            )}

            {/* Address */}
            {multipleValuesFilter(
              t("memberTablePage.tableStreetTitle"),
              "addressFilter",
              "streetNames"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableCityTitle"),
              "addressFilter",
              "cityNames"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableHomeNumberTitle"),
              "addressFilter",
              "homeNumbers"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableResidenceStreetTitle"),
              "addressFilter",
              "streetResidenceNames"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableResidenceCityTitle"),
              "addressFilter",
              "cityResidenceNames"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableResidenceHomeNumberTitle"),
              "addressFilter",
              "homeNumberResidences"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableLocalCommunityNameTitle"),
              "addressFilter",
              "localCommunityNames"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableElectionPlaceNameTitle"),
              "addressFilter",
              "electionPlaceNames"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableElectionPlaceAddressTitle"),
              "addressFilter",
              "electionPlaceAddresses"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableElectionPlaceNumberTitle"),
              "addressFilter",
              "electionPlaceNumbers"
            )}
            {dateOrNumberFilter(
              t("memberTablePage.tableElectionPlaceNumberTitle"),
              "addressFilter",
              "minElectionPlaceNumber",
              "maxElectionPlaceNumber"
            )}

            {/* Card */}
            {multipleValuesFilter(
              t("memberTablePage.tableIdCardNumberTitle"),
              "cardFilter",
              "cardNumbers"
            )}
            {dateOrNumberFilter(
              t("memberTablePage.tableIdCardNumberTitle"),
              "cardFilter",
              "minCardNumber",
              "maxCardNumber"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableMemberCardDateTitle"),
              "cardFilter",
              "establishedDates"
            )}
            {dateOrNumberFilter(
              t("memberTablePage.tableMemberCardDateTitle"),
              "cardFilter",
              "minEstablishedDate",
              "maxEstablishedDate"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableMemberCardCityTitle"),
              "cardFilter",
              "publishingCities"
            )}

            {/* Contact */}
            {multipleValuesFilter(
              t("memberTablePage.tableLandingPhoneTitle"),
              "contactFilter",
              "landPhones"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableMobilePhoneTitle"),
              "contactFilter",
              "mobilePhones"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableBusinessPhoneTitle"),
              "contactFilter",
              "workPhones"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableEmailTitle"),
              "contactFilter",
              "emails"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableFacebookTitle"),
              "contactFilter",
              "facebooks"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableInstagramTitle"),
              "contactFilter",
              "instagrams"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableTwitterTitle"),
              "contactFilter",
              "twiters"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableOtherContactTitle"),
              "contactFilter",
              "others"
            )}

            {/* Education */}
            {multipleValuesFilter(
              t("memberTablePage.tableElementarySchoolTitle"),
              "educationFilter",
              "elementarySchools"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableHighSchoolTitle"),
              "educationFilter",
              "highSchools"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableHighSchoolLevelTitle"),
              "educationFilter",
              "highSchoolYears"
            )}
            {dateOrNumberFilter(
              t("memberTablePage.tableHighSchoolLevelTitle"),
              "educationFilter",
              "minHighSchoolYear",
              "maxHighSchoolYear"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableVocationalStudiesTitle"),
              "educationFilter",
              "basicVocationalStudies"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableSpecialistVocationalStudiesTitle"),
              "educationFilter",
              "specialVocationalStudies"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableBasicAcademicStudiesTitle"),
              "educationFilter",
              "academicStudies"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableBasicAcademicStudiesLevelTitle"),
              "educationFilter",
              "academicStudiesYears"
            )}
            {dateOrNumberFilter(
              t("memberTablePage.tableBasicAcademicStudiesLevelTitle"),
              "educationFilter",
              "minAcademicStudiesYear",
              "maxAcademicStudiesYear"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableMasterAcademicStudiesTitle"),
              "educationFilter",
              "masterAcademicStudies"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableMasterAcademicStudiesLevelTitle"),
              "educationFilter",
              "masterAcademicStudiesYears"
            )}
            {dateOrNumberFilter(
              t("memberTablePage.tableMasterAcademicStudiesLevelTitle"),
              "educationFilter",
              "minMasterAcademicStudiesYear",
              "maxMasterAcademicStudiesYear"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableSpecialAcademicStudiesTitle"),
              "educationFilter",
              "specialAcademicStudies"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableDoctoralAcademicStudiesTitle"),
              "educationFilter",
              "doctoralAcademicStudies"
            )}

            {/* Employment */}
            {multipleValuesFilter(
              t("memberTablePage.tableCompanyTitle"),
              "employmentFilter",
              "companies"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableWorkPlaceTitle"),
              "employmentFilter",
              "workPlaces"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableOtherEmployment"),
              "employmentFilter",
              "others"
            )}

            {/* Language */}
            {multipleValuesFilter(
              t("memberTablePage.tableLanguageSubtitleLanguage"),
              "languageFilter",
              "languages"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableLanguageSubtitleLevel"),
              "languageFilter",
              "levels"
            )}

            {/* OtherData */}
            {multipleValuesFilter(
              t("memberTablePage.tableRecommendationTitle"),
              "otherDataFilter",
              "recommendations"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableHobbyTitle"),
              "otherDataFilter",
              "hobbies"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableSaintDay"),
              "otherDataFilter",
              "saintDays"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableSaintDayDate"),
              "otherDataFilter",
              "saintDayDates"
            )}
            {dateOrNumberFilter(
              t("memberTablePage.tableSaintDayDate"),
              "otherDataFilter",
              "minSaintDayDate",
              "maxSaintDayDate"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableNoteFirstTitle"),
              "otherDataFilter",
              "notes1"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableNoteSecondTitle"),
              "otherDataFilter",
              "notes2"
            )}
            {multipleValuesFilter(
              t("memberTablePage.tableNoteThirdTitle"),
              "otherDataFilter",
              "notes3"
            )}
          </Grid>

          {!isEmpty(filter) && (
            <Grid item xs>
              <AppButton
                label={t("common.cancel")}
                onClick={() => {
                  refetchMemberPagedList({
                    ...dataManipulation,
                    memberFilter: {},
                  });
                  setDataManipulation({
                    ...dataManipulation,
                    memberFilter: {},
                  });
                  setFilter({});
                  setFilterProperty(null);
                  setFilterValue(initialDataTableContainer.initFilterValue);
                }}
                color={colors.primary}
                hover={colors.primary}
                float="right"
                startIcon={<CleaningServices fontSize="small" />}
              />
            </Grid>
          )}
        </Grid>
      </Container>
      {(filter.hasOwnProperty("personalDataFilter") ||
        filter.hasOwnProperty("addressFilter") ||
        filter.hasOwnProperty("cardFilter") ||
        filter.hasOwnProperty("contactFilter") ||
        filter.hasOwnProperty("educationFilter") ||
        filter.hasOwnProperty("employmentFilter") ||
        filter.hasOwnProperty("languageFilter") ||
        filter.hasOwnProperty("otherDataFilter")) && (
        <hr className={classes.divider} />
      )}
    </>
  );
};

export default ActiveFilters;
