import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BasicInput from "../../../component/input/basic-input/BasicInput";
import CustomTooltip from "../../../component/tooltip/CustomTooltip";
import { Member } from "../../../models/data/members/Member";
import MemberSectionType from "../../../models/types/memberSectionTypes/memberSectionType";

interface Props {
  member: Member;
  onChange: any;
}

export const MemberOtherData = ({ member, onChange }: Props) => {
  const { t } = useTranslation();
  const formattedDate = () =>
    member?.otherData?.saintDayDate &&
    member?.otherData?.saintDayDate.substring(0, 10);

  return (
    <Grid container spacing={2}>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.otherDataHobby")}
          value={member?.otherData?.hobby || ""}
          onChange={(e) =>
            onChange(e.target.value, "hobby", MemberSectionType.memberOtherData)
          }
          width="91%"
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.otherDataSaintDay")}
          value={member?.otherData?.saintDay || ""}
          onChange={(e) =>
            onChange(
              e.target.value,
              "saintDay",
              MemberSectionType.memberOtherData
            )
          }
          width="90.5%"
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.otherDataSaintDayDate")}
          type="date"
          value={formattedDate() || ""}
          onChange={(e) =>
            onChange(
              e.target.value,
              "saintDayDate",
              MemberSectionType.memberOtherData
            )
          }
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12} display="flex">
        <BasicInput
          label={t("memberManagementPage.otherDataRecommendation")}
          value={member?.otherData?.recommendation || ""}
          onChange={(e) =>
            onChange(
              e.target.value,
              "recommendation",
              MemberSectionType.memberOtherData
            )
          }
        />
        <CustomTooltip
          infoText={t("tooltips.memberRecommendationOtherText")}
          infoTitle={t("tooltips.tooltipTitle")}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12} display="flex">
        <BasicInput
          label={t("memberManagementPage.otherDataNoteOne")}
          value={member?.otherData?.note1 || ""}
          onChange={(e) =>
            onChange(e.target.value, "note1", MemberSectionType.memberOtherData)
          }
        />
        <CustomTooltip
          infoText={t("tooltips.memberNoteOtherText")}
          infoTitle={t("tooltips.tooltipTitle")}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.otherDataNoteSecond")}
          value={member?.otherData?.note2 || ""}
          onChange={(e) =>
            onChange(e.target.value, "note2", MemberSectionType.memberOtherData)
          }
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.otherDataNoteThird")}
          value={member?.otherData?.note3 || ""}
          onChange={(e) =>
            onChange(e.target.value, "note3", MemberSectionType.memberOtherData)
          }
          width="91%"
        />
      </Grid>
    </Grid>
  );
};
