import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/redux/store";
import { DisplayLanguage } from "../../models/enums/displayLanguage";

type DisplayLanguageState = {
  displayLanguage: DisplayLanguage | null;
};

const initialState: DisplayLanguageState = {
  displayLanguage: null,
};

const displayLanguageSlice = createSlice({
  name: "displayLanguage",
  initialState,
  reducers: {
    setDisplayLanguage: (state, action) => {
      state.displayLanguage = action.payload;
    },
  },
});

export const { setDisplayLanguage } = displayLanguageSlice.actions;

export const selectDisplayLanguage = createSelector(
  (state: RootState) => state.displayLanguage.displayLanguage,
  (displayLanguage) => displayLanguage
);

export default displayLanguageSlice.reducer;
