import { Dispatch, SetStateAction, useState } from "react";
import { useGetMemberProperties } from "../features/table-fiters/hooks/tableHooks";
import { initialDataTableContainer } from "../INITIAL_DATA/table-container/initialDataTableContainer";
import { FilterValue } from "../models/app/table-container/filterValue";
import { PropertyDefinition } from "../models/app/table-container/propertyDefinition";
import { MemberFilterDto } from "../models/requests/members/memberFilterDto";

type Props = {
  dataManipulation: any;
  setDataManipulation: any;
  refetchMemberPagedList: any;
  setPage: Dispatch<SetStateAction<number>>;
};
export const useMemberFilter = ({
  dataManipulation,
  setDataManipulation,
  refetchMemberPagedList,
  setPage,
}: Props) => {
  const { memberParents, memberProperties } = useGetMemberProperties();
  const { initDataManipulationRequest, initFilterValue } =
    initialDataTableContainer;
  const [filterValue, setFilterValue] = useState<FilterValue>(initFilterValue);
  const [filter, setFilter] = useState<MemberFilterDto>({});
  const [filterProperty, setFilterProperty] =
    useState<PropertyDefinition | null>(null);

  const addFilter = () => {
    let updatedFilter = { ...filter };

    const stringFilter = (filterName: string, str: string) => {
      let arr = updatedFilter?.[filterName]
        ? updatedFilter?.[filterName]?.[str]
        : [];

      if (!arr) {
        arr = [];
      }

      if (filterValue.string) {
        if (!arr.includes(filterValue.string.trim())) {
          arr.push(filterValue.string.trim());
        }
      }

      updatedFilter = {
        ...filter,
        [filterName]: {
          ...filter[filterName],
          [str]: [...arr],
        },
      };
    };

    const numberOrDateFilter = (
      filterName: string,
      listName: string,
      min: string,
      max: string,
      minDateOrNumber: string,
      maxDateOrNumber: string
    ) => {
      let arr = updatedFilter?.[filterName]
        ? updatedFilter?.[filterName]?.[listName]
        : [];

      if (!filterValue[minDateOrNumber] && filterValue[minDateOrNumber] !== 0) {
        // do:
        // less than
        if (
          !updatedFilter[filterName]?.[max] &&
          updatedFilter[filterName]?.[min] !== 0
        ) {
          updatedFilter = {
            ...filter,
            [filterName]: {
              ...filter[filterName],
              [max]: filterValue[maxDateOrNumber],
            },
          };
        }
      } else if (
        filterValue[minDateOrNumber] &&
        !filterValue[maxDateOrNumber] &&
        filterValue[maxDateOrNumber] !== 0
      ) {
        // do:
        // equals
        if (!arr) {
          arr = [];
        }

        if (!arr.includes(filterValue[minDateOrNumber])) {
          arr.push(filterValue[minDateOrNumber]);

          updatedFilter = {
            ...filter,
            [filterName]: {
              ...filter[filterName],
              [listName]: arr,
            },
          };
        }
      } else if (filterValue[minDateOrNumber] && filterValue[maxDateOrNumber]) {
        // do:
        // range
        updatedFilter = {
          ...filter,
          [filterName]: {
            ...filter[filterName],
            [max]: filterValue[maxDateOrNumber],
            [min]: filterValue[minDateOrNumber],
          },
        };
      }
    };

    if (filterProperty?.parent) {
      if (filterProperty?.child) {
        switch (filterProperty.parent + "." + filterProperty.child) {
          // Personal data filters

          case "PersonalData.FirstName":
            stringFilter("personalDataFilter", "firstNames");
            break;

          case "PersonalData.LastName":
            stringFilter("personalDataFilter", "lastNames");
            break;

          case "PersonalData.ParentName":
            stringFilter("personalDataFilter", "parentNames");
            break;

          case "PersonalData.Jmbg":
            stringFilter("personalDataFilter", "jmbgs");
            break;

          case "PersonalData.BirthDate":
            numberOrDateFilter(
              "personalDataFilter",
              "birthDates",
              "minBirthDate",
              "maxBirthDate",
              "minDate",
              "maxDate"
            );
            break;

          case "PersonalData.Age":
            numberOrDateFilter(
              "personalDataFilter",
              "ages",
              "minAge",
              "maxAge",
              "minNumber",
              "maxNumber"
            );
            break;

          case "PersonalData.BirthPlace":
            stringFilter("personalDataFilter", "birthPlaces");
            break;

          case "PersonalData.IdNumber":
            stringFilter("personalDataFilter", "idNumbers");
            break;

          case "PersonalData.Gender":
            stringFilter("personalDataFilter", "genders");
            break;

          // Address filters

          case "Address.StreetName":
            stringFilter("addressFilter", "streetNames");
            break;

          case "Address.CityName":
            stringFilter("addressFilter", "cityNames");
            break;

          case "Address.HomeNumber":
            stringFilter("addressFilter", "homeNumbers");
            break;

          case "Address.StreetResidenceName":
            stringFilter("addressFilter", "streetResidenceNames");
            break;

          case "Address.CityResidenceName":
            stringFilter("addressFilter", "cityResidenceNames");
            break;

          case "Address.HomeNumberResidence":
            stringFilter("addressFilter", "homeNumberResidences");
            break;

          case "Address.ElectionPlaceName":
            stringFilter("addressFilter", "electionPlaceNames");
            break;

          case "Address.ElectionPlaceNumber":
            numberOrDateFilter(
              "addressFilter",
              "electionPlaceNumbers",
              "minElectionPlaceNumber",
              "maxElectionPlaceNumber",
              "minNumber",
              "maxNumber"
            );
            break;

          case "Address.LocalCommunityName":
            stringFilter("addressFilter", "localCommunityNames");
            break;

          // Card filters

          case "Card.Number":
            numberOrDateFilter(
              "cardFilter",
              "cardNumbers",
              "minCardNumber",
              "maxCardNumber",
              "minNumber",
              "maxNumber"
            );
            break;

          case "Card.PublishingCity":
            stringFilter("cardFilter", "publishingCities");
            break;

          case "Card.EstablishedDate":
            stringFilter("cardFilter", "establishedDates");
            break;

          // Contact filters

          case "Contact.LandPhone":
            stringFilter("contactFilter", "landPhones");
            break;

          case "Contact.MobilePhone":
            stringFilter("contactFilter", "mobilePhones");
            break;

          case "Contact.WorkPhone":
            stringFilter("contactFilter", "workPhones");
            break;

          case "Contact.Email":
            stringFilter("contactFilter", "emails");
            break;

          case "Contact.Facebook":
            stringFilter("contactFilter", "facebooks");
            break;

          case "Contact.Instagram":
            stringFilter("contactFilter", "instagrams");
            break;

          case "Contact.Twiter":
            stringFilter("contactFilter", "twiters");
            break;

          case "Contact.Other":
            stringFilter("contactFilter", "others");
            break;

          // Education filters

          case "Education.ElementarySchool":
            stringFilter("educationFilter", "elementarySchools");
            break;

          case "Education.HighSchool":
            stringFilter("educationFilter", "highSchools");
            break;

          case "Education.HighSchoolYear":
            numberOrDateFilter(
              "educationFilter",
              "highSchoolYears",
              "minHighSchoolYear",
              "maxHighSchoolYear",
              "minNumber",
              "maxNumber"
            );
            break;

          case "Education.BasicVocationalStudies":
            stringFilter("educationFilter", "basicVocationalStudies");
            break;

          case "Education.SpecialVocationalStudies":
            stringFilter("educationFilter", "specialVocationalStudies");
            break;

          case "Education.AcademicStudies":
            stringFilter("educationFilter", "academicStudies");
            break;

          case "Education.AcademicStudiesYear":
            numberOrDateFilter(
              "educationFilter",
              "academicStudiesYears",
              "minAcademicStudiesYear",
              "maxAcademicStudiesYear",
              "minNumber",
              "maxNumber"
            );
            break;

          case "Education.MasterAcademicStudies":
            stringFilter("educationFilter", "masterAcademicStudies");
            break;

          case "Education.MasterAcademicStudiesYear":
            numberOrDateFilter(
              "educationFilter",
              "masterAcademicStudiesYears",
              "minMasterAcademicStudiesYear",
              "maxMasterAcademicStudiesYear",
              "minNumber",
              "maxNumber"
            );
            break;

          case "Education.SpecialAcademicStudies":
            stringFilter("educationFilter", "specialAcademicStudies");
            break;

          case "Education.DoctoralAcademicStudies":
            stringFilter("educationFilter", "doctoralAcademicStudies");
            break;

          // Employment filters

          case "Employment.Company":
            stringFilter("employmentFilter", "companies");
            break;

          case "Employment.WorkPlace":
            stringFilter("employmentFilter", "workPlaces");
            break;

          case "Employment.Other":
            stringFilter("employmentFilter", "others");
            break;

          // Language filters

          case "Languages.Language":
            stringFilter("languageFilter", "languages");
            break;

          case "Languages.Level":
            stringFilter("languageFilter", "levels");
            break;

          // Other data filters

          case "OtherData.Recommendation":
            stringFilter("otherDataFilter", "recommendations");
            break;

          case "OtherData.Hobby":
            stringFilter("otherDataFilter", "hobbies");
            break;

          case "OtherData.SaintDay":
            stringFilter("otherDataFilter", "saintDays");
            break;

          case "OtherData.SaintDayDate":
            numberOrDateFilter(
              "otherDataFilter",
              "saintDayDates",
              "minSaintDayDate",
              "maxSaintDayDate",
              "minDate",
              "maxDate"
            );
            break;

          case "OtherData.Note1":
            stringFilter("otherDataFilter", "notes1");
            break;

          case "OtherData.Note2":
            stringFilter("otherDataFilter", "notes2");
            break;

          case "OtherData.Note3":
            stringFilter("otherDataFilter", "notes3");
            break;

          default:
            break;
        }
      }
    }

    setPage(1);
    setDataManipulation({
      ...dataManipulation,
      memberFilter: { ...updatedFilter },
      metaData: {
        ...dataManipulation.metaData,
        currentPage: 1,
      },
    });
    setFilter({ ...updatedFilter });
    setFilterValue({ ...initFilterValue });
    refetchMemberPagedList({
      ...dataManipulation,
      memberFilter: { ...updatedFilter },
      metaData: {
        ...dataManipulation.metaData,
        currentPage: 1,
      },
    });
  };

  return {
    memberParents,
    memberProperties,
    initDataManipulationRequest,
    initFilterValue,
    filterValue,
    setFilterValue,
    filter,
    setFilter,
    filterProperty,
    setFilterProperty,
    addFilter,
  };
};
