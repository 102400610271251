import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../app/theme/colors";

export const useLanguageSwitcherStyles = makeStyles()((theme: Theme) => ({
  flagImage: {
    width: "30px",
    height: "20px",
  },
  menuItemButton: {
    border: "none",
    background: "transparent",
    color: colors.primary,
  },
  title: {
    color: colors.primary,
  },
  divider: {
    height: "0.50px!important",
  },
  menuItem: {
    padding: "6px 12px",
    "&:not(:last-child)": {
      marginBottom: 8,
    },
  },
}));
