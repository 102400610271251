import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../../app/redux/hooks";
import { tableInitialData } from "../../data/initialData";
import { formatResult } from "../../helpers/helperMethods";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useTableModalStyles } from "../styles/tableModalStyles";

interface Props {
  isOpen: boolean;
  handleClose: any;
}

type MemberPropDataProps = {
  propDisplayName: string;
  propParentName: string;
  propChildName: string;
};

const TableViewModal = ({ handleClose, isOpen }: Props) => {
  const { t } = useTranslation();
  const { classes } = useTableModalStyles();
  const { memberResult, parentPropertyNames } = tableInitialData;
  const selectedMember = useAppSelector(
    (state) => state.members.selectedMember
  );
  const keys = Object.keys(memberResult);
  const values = Object.values(memberResult);
  const memberById = useAppSelector(
    (state) => state.members.membersFullDetails
  );

  const MemberPropData = (props: MemberPropDataProps): JSX.Element => {
    const result = (
      <Grid container>
        <Grid item xs={6} style={{ textAlign: "left" }}>
          {t(`memberTablePage.${props?.propDisplayName}`)}:
        </Grid>
        <Grid item xs={6} style={{ textAlign: "left" }}>
          {memberById?.data &&
          memberById?.data[props.propParentName] &&
          memberById?.data[props.propParentName][props.propChildName]
            ? formatResult(
                memberById?.data[props.propParentName][props.propChildName]
              )
            : formatResult(null)}
        </Grid>
      </Grid>
    );

    return <>{result}</>;
  };

  const getData = (): JSX.Element => {
    const sections: JSX.Element[] = [];

    for (let i = 0; i < keys.length; i++) {
      const parent = keys[i];
      const childPropNames = Object.keys(values[i]);
      const displayPropNames = Object.values(values[i]);

      let items: JSX.Element[] = [];

      if (parent === "languages") {
        items.push(
          <Grid container key={i}>
            {memberById?.data?.languages?.map((lang, j) => (
              <Grid container mt={j > 0 ? 2 : 0} key={`${i}-${j}`}>
                <Grid item xs={12}>
                  <Grid container>
                    {childPropNames.map((childPropName, k) => (
                      <React.Fragment key={`${i}-${j}-${k}`}>
                        <Grid item xs={6} style={{ textAlign: "left" }}>
                          {t(
                            `memberTablePage.${displayPropNames[k] as string}`
                          )}
                          :
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "left" }}>
                          {formatResult(lang[childPropName])}
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        );
      } else {
        childPropNames.forEach((childPropName, j) =>
          items.push(
            <MemberPropData
              propDisplayName={displayPropNames[j] as string}
              propParentName={parent}
              propChildName={childPropName}
              key={`${i}${j}`}
            />
          )
        );
      }

      sections.push(
        <React.Fragment
          key={`${i}${`${t(`memberTablePage.${parentPropertyNames[i]}`)}`}`}
        >
          <Grid item xs={12} mt={i > 0 ? 4 : 0} style={{ textAlign: "left" }}>
            <h4 className={classes.h4}>
              {t(`memberTablePage.${parentPropertyNames[i]}`)}:
            </h4>
          </Grid>
          <Grid item xs={12}>
            {items}
          </Grid>
        </React.Fragment>
      );
    }

    return <>{sections}</>;
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} className="text-center">
      <DialogTitle className={classes.dialogTitle}>
        <Grid className={classes.grid}>
          <CloseIcon onClick={(e) => handleClose(e)} />
        </Grid>
        <h3 style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
          {t("dashboardPage.viewTableModalTitle")}{" "}
          {selectedMember?.personalData.firstName}{" "}
          {selectedMember?.personalData.lastName}
        </h3>
      </DialogTitle>
      <DialogContent>
        <div style={{ padding: "2rem" }}>
          <Grid container spacing={2} justifyContent="center">
            {getData()}
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TableViewModal;
