import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// @collapse
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "rs",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          common: {
            selectMemberLabel: "Select member",
            cardNumberInputManual: "Manually choose",
            from: "From",
            to: "To",
            selectProperty: "Select property",
            value: "Value",
            equals: "Equals",
            update: "Update",
            add: "Add",
            cancel: "Cancel",
            remove: "Remove",
            between: "Between",
            noResults: "No results",
          },
          messages: {
            reports: {
              pdfReport: {
                pending: "Creating PDF report",
                success: "Successfully created PDF report",
                error: "Error with creating PDF report",
              },
              excelReport: {
                pending: "Creating Excel report",
                success: "Successfully created Excel report",
                error: "Error with creating Excel report",
              },
            },
            login: {
              pending: "Login in progress",
              success: "Successfully logged in",
            },
            resetPasswordOnFirstLogin: {
              pending: "Setting new password in progress",
              success: "Successfully changed password",
            },
          },
          header: {
            headerTitle: "Narodni pokret Dinara-Drina-Dunav",
          },
          sidebar: {
            sidebarDashboard: "Dashboard",
            sidebarAddMembers: "Member management",
            sidebarMemberTable: "Member table",
            sidebarLogout: "Logout",
            sidebarStatistic: "Statistic",
            userManagement: "User management",
          },
          myProfile: {
            myProfileButton: "My profile",
            userManagementButton: "User management",
            logoutButton: "Logout",
            chooseLanguage: "Choose language",
            serbianLatinLanguage: "Serbian (latin)",
            englishLagnuage: "English",
            serbianCyrlLanguage: "Serbian (cyrillic)",
          },
          myProfileData: {
            myProfile: "My profile",
            firstName: "First name",
            lastName: "Last name",
            email: "Email address",
            phoneNumber: "Phone number",
            role: "Role",
            close: "Close",
          },
          accountManagement: {
            accountManagementText: "User account management",
            close: "Close",
            selectAccount: "Select existing user account",
            account: "User account",
            aboutAccount: "User account data",
            firstName: "First name",
            lastName: "Last name",
            email: "Email",
            phoneNumber: "Phone number",
            role: "Role",
            cancelSelection: "Cancel",
            create: "Create",
            update: "Update",
          },
          loginPage: {
            loginHeader: "Login",
            loginEmail: "E-mail",
            loginPassword: "Password",
            loginForgotPassword: "Forgot password?",
            loginHelperEmail: "Enter e-mail",
            loginHelperPassowrd: "Enter password",
          },
          resetPasswordOnFirstLoginModal: {
            title: "Set new password",
            label: "New password",
            submit: "Set password",
          },
          forgotPasswordModal: {
            forgotPasswordTitle: "FORGOT YOUR PASSWORD?",
            forgotPasswordEmail: "E-mail address",
            forgotPasswordEmailHelper: "Enter your account email address.",
            codeTitle: "Code",
            codeHelper: "Enter the code you received.",
            newPasswordTitle: "New Password",
            newPasswordHelper: "Enter the new desired password.",
            confirmNewPassword: "Confirm your new password",
            confirmNewPasswordHelper: "Re-enter the new desired password.",
            minimumCharacters: "Minimum 8 characters",
            minimumCapital: "minimum 1 capital (uppercase) letter",
            minimumNumber: "minimum 1 number",
            minimumSpecialCharacter: "minimum 1 special character",
            passwordConfrimed: "password confrimed",
          },
          dashboardPage: {
            accordionHeader: "Search",
            accordionTitle: "Search fields:",
            accordionSubtitle: "Enter at least 3 characters",
            accordionPlaceholderFirstName: "First name",
            accordionPlaceholderLastName: "Last name",
            accordionPlaceholderPhone: "Phone",
            accordionPlaceholderJmbg: "JMBG",
            nextCardNumberLabel: "Next card number",
            accordionPlaceholderCardNumber: "Card number",
            viewTableModalTitle: "Member's details:",
            editTableModalTitle: "Edit member information:",
          },
          memberManagementPage: {
            searchExistingMember: "Search for existing members",
            personalDataTitle: "Personal data",
            personalDataFirstName: "First name",
            personalDataParentName: "Father name",
            personalDataLastName: "Last name",
            personalDataBirthDate: "Date of birth",
            personalDataBirthPlace: "Birth place",
            personalDataIdNumber: "ID number",
            personalDataUin: "JMBG",
            personalDataMale: "Male",
            personalDataFemale: "Female",
            personalDataErrorMessageFirstName:
              "The name must contain at least two letters",
            personalDataErrorMessageLastName:
              "The last name must contain at least two letters",
            personalDataErrorMessageDateofbirth: "Date of birth is required",
            addressTitle: "Residence information",
            addressesCity: "City",
            addressesStreet: "Street",
            addressesHomeNumber: "Home number.",
            addressesElectionPlaceArea: "Election place",
            addressesElectionPlaceRegionArea: "Election place region",
            addressesResidential: "Residential address is the same",
            addressesElectionPlaceRegionNumber: "Election place region no.",
            addressesElectionPlaceRegionName:
              "Name of the election place region",
            addressesElectionPlaceCheckboxLabel:
              "Select an election place manually",
            addressesElectionPlaceRegionCheckboxLabel:
              "Select an election place region manually",
            addressesElectionPlaceDetailsModalTitle: "Election place details",
            addressesElectionPlaceDetailsModalNumber: "Number:",
            addressesElectionPlaceDetailsModalName: "Name:",
            addressesElectionPlaceDetailsModalAddress: "Address:",
            addressesElectionPlaceDetailsModalPlace: "Place:",
            addressesDataErrorMessageCity: "City of residence is mandatory",
            addressesDataErrorMessageStreet:
              "The street of residence is mandatory",
            addressesDataErrorHomeNumber: "Home number is mandatory",
            contactTitle: "Contact data",
            contactMobilePhone: "Mobile phone",
            contactLandingPhone: "Landline phone",
            contactBussinesPhone: "Business phone",
            contactEmail: "E-mail address",
            contactTwitter: "Twitter",
            contactInstagram: "Instagram",
            contactFacebook: "Facebook",
            contactOther: "Other",
            cardDataTitle: "Membership card information",
            cardDataNumber: "Membership card number",
            cardDataIssued: "Membership card issued",
            cardDataPlaceOfIssue: "Place of publishing",
            cardDataDateOfIssue: "Publishing date",
            cardDataManual: "Manually select",
            cardDataErrorMessageCardNumber: "The membership number is not free",
            employmentDataTitle: "Employment data",
            employmentDataIsEmplyed: "Employed",
            employmentDataIsEmplyedYes: "Yes",
            employmentDataIsEmplyedNo: "No",
            employmentDataIsEmplyedOther: "Other",
            employmentDataCompany: "Other",
            employmentDataNameOfCompany: "Name of company, institution and hq",
            employmentDataWorkPlace: "Workplace",
            employmentDataTemporary: "Employed permanently",
            educationTitle: "Education data",
            educationElementarySchool: "Elementary school",
            educationHighSchool: "High school (course)",
            educationBasicVocationalSchool: "Basic vocational studies",
            educationSpecialistVocationalSchool:
              "Specialist vocational studies",
            educationAcademicStudies: "Academic studies",
            educationMasterAcademicStudies: "Master of academic studies",
            educationSpecialistAcademicStudies: "Specialist academic studies",
            educationDoctoralAcademicStudies: "Doctoral studies",
            educationComputerSkills: "Ability to work on a computer",
            educationNo: "No",
            educationYes: "Yes",
            educationLevelThird: "III degree",
            educationLevelFourth: "IV degree",
            languageTitle: "Member languages",
            languageLanguage: "Language",
            languageLevel: "Level",
            languageActive: "Active",
            languagePassive: "Passive",
            otherDataTitle: "Other data",
            otherDataHobby: "Hobby",
            otherDataSaintDay: "Saint day",
            otherDataSaintDayDate: "Saint day date",
            otherDataRecommendation: "Member's recommendation",
            otherDataNoteOne: "Note 1",
            otherDataNoteSecond: "Note 2",
            otherDataNoteThird: "Note 3",
          },
          memberTablePage: {
            personalData: "Personal data",
            address: "Address data",
            card: "Card data",
            contact: "Contact data",
            education: "Education data",
            employment: "Employment data",
            languages: "Languages data",
            otherData: "Other data",
            allMember: "All personal data",
            allAddress: "All address data",
            allCard: "All card data",
            allContact: "All contact data",
            allEducation: "All education data",
            allEmployment: "All employment data",
            allLanguages: "All languages data",
            allOtherData: "All other data",
            predefinedReports: "Predefined reports of members",
            predefinedReportByLocalCommunity: "Report by local community",
            predefinedReportByCity: "Report by city",
            predefinedReportByElectionPlace: "Report by election place",
            predefinedReportDefault: "Predefined report",
            exportExcelTitle: "Export excel",
            settingsTitle: "Settings",
            exportExcel: "Export excel",
            exportPdf: "Export pdf",
            selectionOfTableColumns: "Selection of table columns",
            tableActionTitle: "Action",
            tableOrdinalNumberTitle: "Ordinal no.",
            tableFirstNameTitle: "First name",
            tableLastNameTitle: "Last name",
            tableLastNameParentName: "Parent name",
            tableUinTitle: "Jmbg",
            tableBirthDateTitle: "Date of birth",
            tableAgeTitle: "Years of age",
            tableBirthPlaceTitle: "Birth place",
            tableIdNumberTitle: "ID number",
            tableGenderTitle: "Gender",
            tableCityTitle: "City",
            tableStreetTitle: "Street",
            tableHomeNumberTitle: "Home number",
            tableResidenceCityTitle: "City of residence",
            tableResidenceStreetTitle: "Street of residence",
            tableResidenceHomeNumberTitle: "Home number of residence",
            tableElectionPlaceNameTitle: "Election place",
            tableElectionPlaceNumberTitle: "Election place number",
            tableElectionPlaceAddressTitle: "Address of election place",
            tableLocalCommunityNameTitle: "Local community",
            tableIdCardNumberTitle: "Membership card number",
            tableIsCardPublishedTitle: "Membership card published",
            tableMemberCardCityTitle: "City of membership card publishing",
            tableMemberCardDateTitle: "Date of membership card publishing",
            tableLandingPhoneTitle: "Landline phone",
            tableMobilePhoneTitle: "Mobile phone",
            tableBusinessPhoneTitle: "Business phone",
            tableEmailTitle: "E-mail",
            tableFacebookTitle: "Facebook",
            tableInstagramTitle: "Instagram",
            tableTwitterTitle: "Twitter",
            tableOtherContactTitle: "Other contact",
            tableElementarySchoolTitle: "Elementary school",
            tableHighSchoolTitle: "High school",
            tableHighSchoolLevelTitle: "High school degree",
            tableVocationalStudiesTitle: "Vocational studies",
            tableSpecialistVocationalStudiesTitle: "Special vocational studies",
            tableBasicAcademicStudiesTitle: "Basic academic studies",
            tableBasicAcademicStudiesLevelTitle:
              "Level of basic academic studies",
            tableMasterAcademicStudiesTitle: "Master of academic studies",
            tableMasterAcademicStudiesLevelTitle:
              "Master's degree in academic studies",
            tableSpecialAcademicStudiesTitle: "Special academic studies",
            tableDoctoralAcademicStudiesTitle: "Doctoral studies",
            tableWorkOnComputerTitle: "Work on computers",
            tableIsEmployedTitle: "Employed",
            tableCompanyTitle: "Company",
            tableWorkPlaceTitle: "Work place",
            tableTemporaryEmployed: "Permanently employed",
            tableOtherEmployment: "Other employment",
            tableLanguagesTitle: "Languages",
            tableLanguageSubtitleLanguage: "Language",
            tableLanguageSubtitleLevel: "Level",
            tableLanguageSubtitleActive: "Actively uses",
            tableHobbyTitle: "Hobby",
            tableSaintDay: "Saint day",
            tableSaintDayDate: "Date of saint day",
            tableRecommendationTitle: "Member's recommendation",
            tableNoteТitle: "Note",
            tableNoteFirstTitle: "Note 1",
            tableNoteSecondTitle: "Note 2",
            tableNoteThirdTitle: "Note 3",
            detailOfExcelFileTitle: "Detail of excel file",
            detailOfExcelFileGeneral: "General",
            detailOfExcelFileNameOfDocument: "Name of document",
            detailOfExcelFileHeader: "Header of document",
            detailOfExcelFileFirstLine: "First line",
            detailOfExcelFileSecondLine: "Second line",
            filterTitle: "Filter",
            filterNoRequirement: "No requirement",
            filterEquals: "Equal to",
            filterNotEquals: "Not equal to",
            filterEmpty: "Empty",
            filterNotEmpty: "Not empty",
            filterLessThan: "Less than",
            filterLessThanOrEqualTo: "Less than or equal to",
            filterGreaterThan: "Greater than",
            filterGreaterThanOrEqualTo: "Greater than or equal to",
            filterStartsWith: "Starts with",
            filterNotStartsWith: "Not starts with",
            filterEndsWith: "EndsWith",
            filterNotEndsWith: "Not ends with",
            filterContains: "Contains",
            filterNotContains: "Not contains",
            filterFieldPropName: "Field",
            filterFieldFirstRequirement: "First requirement",
            filterFieldSecondRequirement: "Second requirement",
            filterLogicalOperatorAnd: "and",
            filterLogicalOperatorOr: "or",
            filterFieldFirstData: "First data",
            filterFieldSecondData: "Second data",
            resultsPerPage: "Results per page",
            tableFiltersTitle: "Filters",
            showingResults: "Showing",
            showingOf: "of",
            results: "results",
            rowsPerPage: "Rows per page",

            getExcelReportCurrentDataFileName: "Members - Excel",
            getPdfReportCurrentDataFileName: "Members - PDF",

            appliedFiltersLabel: "Applied filters",
          },
          userTablePage: {
            userTableHeaderAction: "Action",
            userTableHeaderOridinalNumber: "Ordinal number",
            userTableHeaderId: "Id",
            userTableHeaderFirstName: "First name",
            userTableHeaderLastName: "Last name",
            userTableHeaderEmail: "Email",
            userTableHeaderPhone: "Phone",
            userTableHeaderRole: "Role",
            userTableHeaderIsActive: "Is active",
            userTableHeaderIsDelete: "Is deleted",
            userTableAlert: "Are you sure you want to",
            userTableAlertDeactivate: "deactivate",
            userTableAlertActivate: "activate",
            userTableAlertRemove: "remove",
            userTableAlertGetBack: "get back",
            userTableAlertUser: "user",
            createNewUserIsDeleted: "Is deleted",
            account: "account",
            userFormStateCreate: "Create",
            userFormStateEdit: "Edit",
            userFormStateView: "View",
            userFormStateNone: "",
          },
          statisticPage: {
            ageStatistic: "Age statistic",
            statisticByCity: "City statistic",
            totalMembers: "Total members: ",
            ageOfMembers: "Age of members and gender",
          },
          alerts: {
            statisticPageAlert: "Copied!",
          },
          buttons: {
            loginButton: "Login",
            dashboardAddMember: "create",
            dashboardUpdateMember: "update",
            dashboardClearFields: "clear all fields",
            dashboardDeleteMember: "delete",
            close: "Close",
            selectionOfTableColumns: "selection of table columns",
            save: "Save",
            electionPlaceDetails: "Election place details",
            cancelFilter: "Cancel filter",
            submitFilter: "Submit filter",
            menageDocumentData: "Menage document data",
            sendRequest: "Send a request",
            resetPassword: "Reset password",
            createNewUser: "create new user",
          },
          tooltips: {
            predefinedReports: "Predefined reports of members",
            pdfReport: "PDF report of members",
            excelReport: "Excel report of members",
            tableActionRowsEdit: "Edit member information",
            tableActionRowsView: "View member information",
            tableActionRowsDelete: "Delete the member",
            tableActionRowsUserEdit: "Edit user information",
            tableActionRowsUserView: "View user data",
            tableActionRowsUserDeactivate: "Deactivate user",
            tableActionRowsUserActivate: "Activate user",
            tableActionRowsUserRemove: "Remove user",
            tableActionRowsUserGetBack: "Get back user",
            tooltipsStatistic:
              "By clicking on the text, you copy the content to the clipboard memory",
            manuallySelectCardDataText:
              "Manually select a free member card number.",
            isAddressSameAddressSectionText:
              "If the residential address is not the same as the address on the ID card, you can also add the residential address for records.",
            electionPlaceRegionAddressSectionText:
              "If the region of the polling station is not set automatically, you can manually choose from the offered regions from the specified city.",
            otherEmploymentText:
              "Additional information about employment if there are any specifics.",
            memberRecommendationOtherText:
              "Name and lastname of the member who gave the recommendation.",
            memberNoteOtherText: "Additional information about the member.",
            tooltipTitle: "INFORMATION",
            languageLevelLanguageSection:
              "Language level according to the CEFR standard.",
            contactOtherContactSectionText:
              "Additional contact networks (Skype, LinkedIn ...)",
          },
          icons: {
            filters: "Select filters",
          },
          notifications: {
            createPersonalDataPending: "Creating member...",
            createPersonalDataFulfilled: "Member is successfully created!",
            updatePersonalDataPending: "Updating member...",
            updatePersonalDataFulfilled: "Member is successfully updated!",
            deleteMemberPending: "Deleting member...",
            deleteMemberFulfilled: "Member has been successfully deleted!",

            loginPending: "Login is in progress...",
            loginFulfilled: "You have successfully logged in.",

            createAccountPending: "Creating account",
            createAccountFulfilled:
              "Account created, еmail has been sent successfully",
            updateAccountPending: "Updating account",
            updateAccountFulfilled: "Account updated",

            getExcelReportCurrentDataPending:
              "Creating Excel report of members in table...",
            getExcelReportCurrentDataFulfilled:
              "Creating Excel report of members in table finished.",
            getPdfReportCurrentDataPending:
              "Creating PDF report of members in table...",
            getPdfReportCurrentDataFulfilled:
              "Creating PDF report of members in table finished.",
          },
          errorNotifications: {
            undefined: "Internal server error.",
            "-500": "Internal server error.",
            0: "Internal server error.",
            1: "Member address doesn't exist.",
            2: "Member card doesn't exist.",
            3: "Member contact doesn't exist.",
            4: "Member education doesn't exist.",
            5: "Member employment doesn't exist.",
            6: "Member language doesn't exist.",
            7: "Member other data doesn't exist.",
            8: "Member personal data doesn't exist.",
            9: "Member with this data already exists.",
            10: "Account doesn't exist.",
            11: "Account with this email doesn't exist.",
            12: "Email address is already in use.",
            13: "Role with this name doesn't exist.",
            14: "Account is not active. Please contact administrator.",
            15: "Wrong password.",
            16: "Invalid refresh token.",
            17: "Your session expired.",
            18: "City municipality doesn't exist.",
            19: "City region doesn't exist.",
            20: "City doesn't exist.",
            21: "Country doesn't exist.",
            22: "Local community doesn't exist.",
            23: "Local community street doesn't exist.",
            24: "Municipality doesn't exist.",
            25: "State doesn't exist.",
            26: "Street doesn't exist.",
            27: "Election place region doesn't exist.",
            28: "Election place doesn't exist.",
            29: "Request reset password time expired.",
            30: "Role doesn't exist.",
          },
          switch: {
            isActive: "Active",
          },
          reportHeaderTitleModal: {
            title: "Member report",
            titleInReport: "Title in report",
            closeModal: "Close",
            getTheReport: "Get the report",
          },
          toastMessages: {
            requestResetPasswordEmailError:
              "Entered data is not valid, please check it and try again!",
            requestResetPasswordEmailSuccess:
              "You have successfully sent a request!",
            requestResetPasswordSuccess:
              "You have successfully reset a password!",

            createdAccountError:
              "Entered data is not valid, please check it and try again!",
          },
        },
      },
      rs: {
        translation: {
          common: {
            selectMemberLabel: "Pretraga članova",
            cardNumberInputManual: "Ručno odaberi",
            from: "Od",
            to: "Do",
            selectProperty: "Izaberite polje",
            value: "Vrednost",
            equals: "Isto",
            update: "Ažuriraj",
            add: "Dodaj",
            cancel: "Poništi",
            remove: "Obriši",
            between: "Između",
            noResults: "Nema rezultata",
          },
          messages: {
            reports: {
              pdfReport: {
                pending: "Kreiranje PDF izveštaja",
                success: "Uspešno je kreiran PDF izveštaj",
                error: "Greška prilikom kreiranja PDF izveštaja",
              },
              excelReport: {
                pending: "Kreiranje Excel izveštaja",
                success: "Uspešno je kreiran Excel izveštaj",
                error: "Greška prilikom kreiranja Excel izveštaja",
              },
            },
            login: {
              pending: "Prijavljivanje u toku",
              success: "Uspešno ste se prijavili",
            },
            resetPasswordOnFirstLogin: {
              pending: "Postavljanje nove lozinke u toku",
              success: "Uspešno ste postavili novu lozinku",
            },
          },
          header: {
            headerTitle: "Narodni pokret Dinara-Drina-Dunav",
          },
          sidebar: {
            sidebarDashboard: "Kontrolna tabla",
            sidebarAddMembers: "Upravljanje članovima",
            sidebarMemberTable: "Tabela članova",
            sidebarLogout: "Odjavi se",
            sidebarStatistic: "Statistika",
            userManagement: "Upravljanje korisnicima",
          },
          myProfile: {
            myProfileButton: "Moj profil",
            userManagementButton: "Upravljanje kor.",
            logoutButton: "Odjavi se",
            chooseLanguage: "Izaberite jezik",
            serbianLatinLanguage: "Srpski (latinica)",
            englishLagnuage: "Engleski",
            serbianCyrlLanguage: "Srpski (ćirilica)",
          },
          myProfileData: {
            myProfile: "Moj profil",
            firstName: "Ime",
            lastName: "Prezime",
            email: "Email adresa",
            phoneNumber: "Telefon",
            role: "Rola",
            close: "Zatvori",
          },
          accountManagement: {
            accountManagementText: "Upravljanje korisnicima",
            close: "Zatvori",
            selectAccount: "Izaberite postojećeg korisnika",
            account: "Korisnik",
            aboutAccount: "Podaci o korisniku",
            firstName: "Ime",
            lastName: "Prezime",
            email: "Email",
            phoneNumber: "Telefon",
            role: "Rola",
            cancelSelection: "Poništi odabir",
            create: "Kreiraj",
            update: "Ažuriraj",
          },
          loginPage: {
            loginHeader: "Prijavite se",
            loginEmail: "E-pošta",
            loginPassword: "Lozinka",
            loginForgotPassword: "Zaboravili ste lozinku?",
            loginHelperEmail: "Unesite e-poštu",
            loginHelperPassowrd: "Unesite lozinku",
          },
          resetPasswordOnFirstLoginModal: {
            title: "Postavite novu lozinku",
            label: "Nova lozinka",
            submit: "Postavite lozinku",
          },
          forgotPasswordModal: {
            forgotPasswordTitle: "ZABORAVILI STE LOZINKU?",
            forgotPasswordEmail: "Email adresa",
            forgotPasswordEmailHelper: "Unesite email adresu naloga.",
            codeTitle: "Kod",
            codeHelper: "Unesite kod koji ste dobili.",
            newPasswordTitle: "Nova Lozinka",
            newPasswordHelper: "Unesite novu željenu lozinku.",
            confirmNewPassword: "Potvrdite novu lozinku",
            confirmNewPasswordHelper: "Unesite ponovo novu željenu lozinku.",
            minimumCharacters: "najmanje 8 znakova",
            minimumCapital: "najmanje 1 veliko slovo",
            minimumNumber: "najmanje 1 broj",
            minimumSpecialCharacter: "najmanje 1 specijalni znak",
            passwordConfirmed: "lozinka potvrdjena",
          },
          dashboardPage: {
            accordionHeader: "Pretraga",
            accordionTitle: "Polja za pretragu:",
            accordionSubtitle: "Unesite barem 3 karaktera",
            accordionPlaceholderFirstName: "Ime",
            accordionPlaceholderLastName: "Prezime",
            accordionPlaceholderPhone: "Telefon",
            accordionPlaceholderJmbg: "JMBG",
            nextCardNumberLabel: "Sledeći čl. broj",
            accordionPlaceholderCardNumber: "Broj članske karte",
            viewTableModalTitle: "Detalji člana:",
            editTableModalTitle: "Izmenite podatke člana:",
          },
          memberManagementPage: {
            searchExistingMember: "Pretraga postojećih članova",
            personalDataTitle: "Lični podaci",
            personalDataFirstName: "Ime",
            personalDataParentName: "Očevo ime",
            personalDataLastName: "Prezime",
            personalDataBirthDate: "Datum rođenja",
            personalDataBirthPlace: "Mesto rođenja",
            personalDataIdNumber: "Broj lične karte",
            personalDataUin: "JMBG",
            personalDataMale: "Muško",
            personalDataFemale: "Žensko",
            personalDataErrorMessageFirstName:
              "Ime mora sadržati makar dva slova",
            personalDataErrorMessageLastName:
              "Prezime mora sadržati makar dva slova",
            personalDataErrorMessageDateofbirth: "Datum rođenja je obavezan",
            addressTitle: "Podaci prebivališta",
            addressesCity: "Mesto",
            addressesStreet: "Ulica",
            addressesHomeNumber: "Kućni br.",
            addressesResidential: "Adresa stanovanja je ista",
            addressesElectionPlaceArea: "Biračko mesto",
            addressesElectionPlaceRegionArea: "Područje biračkog mesta",
            addressesElectionPlaceRegionNumber: "Broj br. mesta",
            addressesElectionPlaceRegionName: "Naziv bir. mesta",
            addressesElectionPlaceCheckboxLabel: "Ručno izaberite bir. mesto",
            addressesElectionPlaceRegionCheckboxLabel:
              "Ručno izaberite regiju bir. mesta",
            addressesElectionPlaceDetailsModalTitle: "Detalji biračkog mesta",
            addressesElectionPlaceDetailsModalNumber: "Broj:",
            addressesElectionPlaceDetailsModalName: "Naziv:",
            addressesElectionPlaceDetailsModalAddress: "Adresa:",
            addressesElectionPlaceDetailsModalPlace: "Mesto:",
            addressesDataErrorMessageCity: "Grad prebivališta je obavezan",
            addressesDataErrorMessageStreet: "Ulica prebivališta je obavezna",
            addressesDataErrorHomeNumber: "Kućni broj je obavezan",
            contactTitle: "Kontakt podaci",
            contactMobilePhone: "Mobilni telefon",
            contactLandingPhone: "Fiksni telefon",
            contactBussinesPhone: "Poslovni telefon",
            contactEmail: "E-mail adresa",
            contactTwitter: "Twitter",
            contactInstagram: "Instagram",
            contactFacebook: "Facebook",
            contactOther: "Drugo",
            cardDataTitle: "Podaci članske karte",
            cardDataNumber: "Broj članske karte",
            cardDataIssued: "Članska karta izdata",
            cardDataPlaceOfIssue: "Mesto izdavanja",
            cardDataDateOfIssue: "Datum izdavanja",
            cardDataManual: "Manuelno odaberi",
            cardDataErrorMessageCardNumber: "Članski broj nije slobodan",
            employmentDataTitle: "Podaci o zaposlenju",
            employmentDataIsEmplyed: "Zaposlen/a",
            employmentDataIsEmplyedYes: "Da",
            employmentDataIsEmplyedNo: "Ne",
            employmentDataIsEmplyedOther: "Ostalo",
            employmentDataCompany: "Ostalo",
            employmentDataNameOfCompany:
              "Naziv firme, preduzeća, ustanove i sedišta",
            employmentDataWorkPlace: "Radno mesto",
            employmentDataTemporary: "Zaposlen/a za stalno",
            educationTitle: "Podaci o školovanju",
            educationElementarySchool: "Osnovna škola",
            educationHighSchool: "Srednja škola (smer)",
            educationBasicVocationalSchool: "Osnovne strukovne studije",
            educationSpecialistVocationalSchool:
              "Specijalističke strukovne studije",
            educationAcademicStudies: "Akademske studije",
            educationMasterAcademicStudies: "Master akademske studije",
            educationSpecialistAcademicStudies:
              "Specijalističke akademske studije",
            educationDoctoralAcademicStudies: "Doktorske akademske studije",
            educationComputerSkills: "Osposobljenost za rad na računaru",
            educationNo: "Ne",
            educationYes: "Da",
            educationLevelThird: "III stepen",
            educationLevelFourth: "IV stepen",
            languageTitle: "Jezici člana",
            languageLanguage: "Jezik",
            languageLevel: "Nivo",
            languageActive: "Aktivno",
            languagePassive: "Pasivno",
            otherDataTitle: "Ostali podaci",
            otherDataHobby: "Hobi",
            otherDataSaintDay: "Krsna slava",
            otherDataSaintDayDate: "Datum krsne slave",
            otherDataRecommendation: "Preporuka člana",
            otherDataNoteOne: "Beleška 1",
            otherDataNoteSecond: "Beleška 2",
            otherDataNoteThird: "Beleška 3",
          },
          memberTablePage: {
            personalData: "Opšti podaci člana",
            address: "Prebivalište člana",
            card: "Podaci članske karte",
            contact: "Kontakt podaci člana",
            education: "Obrazovanje člana",
            employment: "Zaposlenje člana",
            languages: "Jezici člana",
            otherData: "Ostali podaci člana",
            allMember: "Svi opšti podaci",
            allAddress: "Svi podaci prebivališta",
            allCard: "Svi podaci članske karte",
            allContact: "Svi kontakt podaci",
            allEducation: "Svi podaci obrazovanja",
            allEmployment: "Svi podaci zaposlenja",
            allLanguages: "Svi podaci jezika",
            allOtherData: "Svi ostali podaci",
            predefinedReports: "Predefinisani izveštaji članova",
            predefinedReportByLocalCommunity: "Izveštaj po mesnoj zajednici",
            predefinedReportByCity: "Izveštaj po gradu",
            predefinedReportByElectionPlace: "Izveštaj po biračkom mestu",
            predefinedReportDefault: "Predefinisan izveštaj",
            exportExcelTitle: "Izvoz excel",
            settingsTitle: "Podešavanja",
            exportExcel: "Izvezi excel",
            exportPdf: "Izvezi pdf",
            selectionOfTableColumns: "Izbor kolona tabele",
            tableActionTitle: "Akcija",
            tableOrdinalNumberTitle: "R. br.",
            tableFirstNameTitle: "Ime",
            tableLastNameTitle: "Prezime",
            tableLastNameParentName: "Ime roditelja",
            tableUinTitle: "Jmbg",
            tableBirthDateTitle: "Datum rođenja",
            tableAgeTitle: "Godine starosti",
            tableBirthPlaceTitle: "Mesto rođenja",
            tableIdNumberTitle: "Broj lične karte",
            tableGenderTitle: "Pol",
            tableResidenceCityTitle: "Grad",
            tableResidenceStreetTitle: "Ulica",
            tableResidenceHomeNumberTitle: "Kućni broj",
            tableCityTitle: "Grad stanovanja",
            tableStreetTitle: "Ulica stanovanja",
            tableHomeNumberTitle: "Kućni broj stanovanja",
            tableElectionPlaceNameTitle: "Biračko mesto",
            tableElectionPlaceNumberTitle: "Broj biračkog mesta",
            tableElectionPlaceAddressTitle: "Adresa biračkog mesta",
            tableLocalCommunityNameTitle: "Mesna zajednica",
            tableIdCardNumberTitle: "Broj članske karte",
            tableIsCardPublishedTitle: "Članska karta izdata",
            tableMemberCardCityTitle: "Grad izdavanja članske karte",
            tableMemberCardDateTitle: "Datum izdavanja članske karte",
            tableLandingPhoneTitle: "Fiksni telefon",
            tableMobilePhoneTitle: "Mobilni telefon",
            tableBusinessPhoneTitle: "Poslovni telefon",
            tableEmailTitle: "E-mail",
            tableFacebookTitle: "Facebook",
            tableInstagramTitle: "Instagram",
            tableTwitterTitle: "Twitter",
            tableOtherContactTitle: "Ostalo kontakt",
            tableElementarySchoolTitle: "Osnovna škola",
            tableHighSchoolTitle: "Srednja škola",
            tableHighSchoolLevelTitle: "Stepen srednje škole",
            tableVocationalStudiesTitle: "Strukovne studije",
            tableSpecialistVocationalStudiesTitle:
              "Specijalne strukovne studije",
            tableBasicAcademicStudiesTitle: "Osnovne akademske studije",
            tableBasicAcademicStudiesLevelTitle:
              "Stepen osnovnih akademskih studija",
            tableMasterAcademicStudiesTitle: "Master akademske studije",
            tableMasterAcademicStudiesLevelTitle:
              "Stepen master akademskih studija",
            tableSpecialAcademicStudiesTitle: "Specijalne akademske studije",
            tableDoctoralAcademicStudiesTitle: "Doktorske akademske studije",
            tableWorkOnComputerTitle: "Rad na računaru",
            tableIsEmployedTitle: "Zaposlen/a",
            tableCompanyTitle: "Firma",
            tableWorkPlaceTitle: "Radno mesto",
            tableTemporaryEmployed: "Zaposlen/a za stalno",
            tableOtherEmployment: "Ostalo zaposlenje",
            tableLanguagesTitle: "Jezici",
            tableLanguageSubtitleLanguage: "Jezik",
            tableLanguageSubtitleLevel: "Nivo",
            tableLanguageSubtitleActive: "Aktivno koristi",
            tableHobbyTitle: "Hobi",
            tableSaintDay: "Krsna slava",
            tableSaintDayDate: "Datum krsne slave",
            tableRecommendationTitle: "Preporuka člana",
            tableNoteТitle: "Beleška",
            tableNoteFirstTitle: "Beleška 1",
            tableNoteSecondTitle: "Beleške 2",
            tableNoteThirdTitle: "Beleške 3",
            detailOfExcelFileTitle: "Detalji excel fajla",
            detailOfExcelFileGeneral: "Generalno",
            detailOfExcelFileNameOfDocument: "Naziv dokumenta",
            detailOfExcelFileHeader: "Zaglavlje dokumenta",
            detailOfExcelFileFirstLine: "Prva linija",
            detailOfExcelFileSecondLine: "Druga linija",
            filterTitle: "Filteri",
            filterNoRequirement: "Bez uslova",
            filterEquals: "Jednako",
            filterNotEquals: "Nije jednako",
            filterEmpty: "Prazno",
            filterNotEmpty: "Nije prazno",
            filterLessThan: "Manje od",
            filterLessThanOrEqualTo: "Manje od ili jednako",
            filterGreaterThan: "Veće od",
            filterGreaterThanOrEqualTo: "Veće od ili jednako",
            filterStartsWith: "Počinje sa",
            filterNotStartsWith: "Ne počinje sa",
            filterEndsWith: "Zavrašava se sa",
            filterNotEndsWith: "Ne završava se sa",
            filterContains: "Sadrži",
            filterNotContains: "Ne sadrži",
            filterFieldPropName: "Polje",
            filterFieldFirstRequirement: "Prvi uslov",
            filterFieldSecondRequirement: "Drugi uslov",
            filterLogicalOperatorAnd: "i",
            filterLogicalOperatorOr: "ili",
            filterFieldFirstData: "Prvi podatak",
            filterFieldSecondData: "Drugi podatak",
            resultsPerPage: "Rezultata po stranici",
            tableFiltersTitle: "Filteri tabele",
            showingResults: "Prikazuje",
            showingOf: "od",
            results: "rezultata",
            rowsPerPage: "Rezultata po stranici",

            getExcelReportCurrentDataFileName: "Članovi - Excel",
            getPdfReportCurrentDataFileName: "Članovi - PDF",

            appliedFiltersLabel: "Primenjeni filteri",
          },
          userTablePage: {
            userTableHeaderAction: "Akcija",
            userTableHeaderOridinalNumber: "Redni broj",
            userTableHeaderId: "Id",
            userTableHeaderFirstName: "Ime",
            userTableHeaderLastName: "Prezime",
            userTableHeaderEmail: "Email",
            userTableHeaderPhone: "Telefon",
            userTableHeaderRole: "Rola",
            userTableHeaderIsActive: "Aktivan",
            userTableHeaderIsDelete: "Obrisan",
            userTableAlert: "Da li sigurno želite da",
            userTableAlertDeactivate: "deaktivirate",
            userTableAlertActivate: "aktivirate",
            userTableAlertRemove: "obrišete",
            userTableAlertGetBack: "vratite",
            userTableAlertUser: "korisnika",
            createNewUserIsDeleted: "Izbrisano je",
            account: "nalog",
            userFormStateCreate: "Kreiraj",
            userFormStateEdit: "Izmeni",
            userFormStateView: "Pregledaj",
            userFormStateNone: "",
          },
          statisticPage: {
            ageStatistic: "Starosna statistika",
            statisticByCity: "Statistika po mestu",
            totalMembers: "Ukupno članova: ",
            ageOfMembers: "Godine članova i pol: ",
          },
          alerts: {
            statisticPageAlert: "Kopirano!",
          },
          buttons: {
            loginButton: "Prijavite se",
            dashboardAddMember: "kreiraj",
            dashboardUpdateMember: "ažuriraj",
            dashboardClearFields: "očisti polja",
            dashboardDeleteMember: "obriši",
            close: "Zatvori",
            selectionOfTableColumns: "izbor kolona tabele",
            save: "Sačuvaj",
            electionPlaceDetails: "Detalji biračkog mesta",
            cancelFilter: "Poništite filtere",
            submitFilter: "Primenite filtere",
            menageDocumentData: "Upravljajte podacima dokumenta",
            sendRequest: "Pošaljite zahtev",
            resetPassword: "Resetujte lozinku",
            createNewUser: "kreirajte novog korisnika",
          },
          tooltips: {
            predefinedReports: "Predefinisani izveštaji članova",
            pdfReport: "PDF izveštaj članova",
            excelReport: "Excel izveštaj članova",
            tableActionRowsEdit: "Izmenite informacije o članovima",
            tableActionRowsView: "Pogledajte podatke člana",
            tableActionRowsDelete: "Obrišite člana ",
            tableActionRowsUserEdit: "Izmenite informacije o korisnicima",
            tableActionRowsUserView: "Pogledajte podatke korisnika",
            tableActionRowsUserDeactivate: "Deaktivirajte korisnika",
            tableActionRowsUserActivate: "Aktivirajte korisnika",
            tableActionRowsUserRemove: "Obrišite korisnika",
            tableActionRowsUserGetBack: "Vratite korisnika",
            tooltipsStatistic:
              "Klikom na tekst kopirate sadržaj u memoriju klipborda",
            manuallySelectCardDataText:
              "Manuelno odaberite slobodan broj članske karte.",
            isAddressSameAddressSectionText:
              "Ukoliko adresa stanovanja nije ista kao i adresa iz lične karte, možete dodati i adresu stanovanja zbog evidencije.",
            electionPlaceRegionAddressSectionText:
              "Ukoliko regija biračkog mesta nije postavljena automatski, možete ručno izabrati od ponuđenih regija iz navedenog grada.",
            otherEmploymentText:
              "Dodatne informacije o zaposlenju ukoliko ima nekih specifičnosti.",
            memberRecommendationOtherText:
              " Ime i prezime člana koji je dao preporuku.",
            memberNoteOtherText: "Dodatne informacije o članu",
            tooltipTitle: "INFORMACIJE",
            languageLevelLanguageSection: "Nivo jezika po CEFR standardu.",
            contactOtherContactSectionText:
              "Dodatne mreže za kontakt (Skype, LinkedIn ...)",
          },
          icons: {
            filters: "Izaberite filtere",
          },
          notifications: {
            createPersonalDataPending: "Kreiranje člana...",
            createPersonalDataFulfilled: "Uspešno je kreiran član!",
            updatePersonalDataPending: "Ažuriranje člana...",
            updatePersonalDataFulfilled: "Uspešno je ažuriran član!",
            deleteMemberPending: "Brisanje člana...",
            deleteMemberFulfilled: "Član je uspešno obrisan!",

            loginPending: "Prijavljivanje na sistem...",
            loginFulfilled: "Uspešno ste se prijavili na sistem.",

            createAccountPending: "Kreiranje korisničkog naloga",
            createAccountFulfilled:
              "Korisnički nalog kreiran, e-mail je poslat",
            updateAccountPending: "Ažuriranje korisničkog naloga",
            updateAccountFulfilled: "Korisnički nalog ažuriran",

            getExcelReportCurrentDataPending:
              "Kreiranje Excel izveštaja članova iz tabele...",
            getExcelReportCurrentDataFulfilled:
              "Kreiranje Excel izveštaja članova iz tabele završeno.",
            getPdfReportCurrentDataPending:
              "Kreiranje PDF izveštaja članova iz tabele...",
            getPdfReportCurrentDataFulfilled:
              "Kreiranje PDF izveštaja članova iz tabele završeno.",
          },
          errorNotifications: {
            undefined: "Dogodila se greška na serveru.",
            "-500": "Dogodila se greška na serveru.",
            0: "Dogodila se greška na serveru.",
            1: "Adresa člana ne postoji.",
            2: "Članska karta člana ne postoji.",
            3: "Kontakt podaci člana ne postoje.",
            4: "Podaci o obrazovanju člana ne postoje.",
            5: "Podaci o zaposlenju člana ne postoje.",
            6: "Strani jezik člana ne postoji.",
            7: "Ostali podaci člana ne postoje.",
            8: "Lični podaci člana ne postoje.",
            9: "Član sa ovim podacima već postoji.",
            10: "Korisnički nalog ne postoji.",
            11: "Korisnički nalog sa ovim email-om ne postoji.",
            12: "Email adresa je već u upotrebi.",
            13: "Korisnička rola sa ovim nazivom ne postoji.",
            14: "Korisnička nalog nije aktivan. Molimo Vas kontaktirajte administratora.",
            15: "Pogrešna lozinka.",
            16: 'Neispravan "refresh token".',
            17: "Vaša sesija je istekla.",
            18: "Opština ne postoji.",
            19: "Regija ne postoji.",
            20: "Grad ne postoji.",
            21: "Zemlja ne postoji.",
            22: "Mesna zajednica ne postoji.",
            23: "Mesna zajednica ne postoji.",
            24: "Opština ne postoji.",
            25: "Država ne postoji.",
            26: "Ulica ne postoji.",
            27: "Regija biračkog mesta ne postoji.",
            28: "Biračko mesto ne postoji.",
            29: "Isteklo je vreme za resetovanje Vaše lozinke.",
            30: "Rola ne postoji.",
          },
          switch: {
            isActive: "Aktivan",
          },
          reportHeaderTitleModal: {
            title: "Izveštaj članova",
            titleInReport: "Naslov u izveštaju",
            closeModal: "Zatvori",
            getTheReport: "Preuzmi izveštaj",
          },
          toastMessages: {
            requestResetPasswordEmailError:
              "Uneti podaci nisu validni, proverite ih i pokušajte ponovo!",
            requestResetPasswordEmailSuccess: "Uspešno ste poslali zahtev!",
            requestResetPasswordSuccess: "Uspešno ste resetovali lozinku!",
            createdAccountError:
              "Uneti podaci nisu validni, proverite ih i pokušajte ponovo!",
          },
        },
      },
      rsCyrl: {
        translation: {
          common: {
            selectMemberLabel: "Претрага чланова",
            cardNumberInputManual: "Ручно одабери",
            from: "Од",
            to: "До",
            selectProperty: "Изаберите поље",
            value: "Вредност",
            equals: "Исто",
            update: "Ажурирај",
            add: "Додај",
            cancel: "Поништи",
            remove: "Обриши",
            between: "Између",
            noResults: "Нема резултата",
          },
          messages: {
            reports: {
              pdfReport: {
                pending: "Креирање PDF извештаја",
                success: "Успешно је креиран PDF извештај",
                error: "Грешка приликом креирања PDF извештаја",
              },
              excelReport: {
                pending: "Креирање Excel извештаја",
                success: "Успешно је креиран Excel извештај",
                error: "Грешка приликом креирања Excel извештаја",
              },
            },
            login: {
              pending: "Пријављивање у току",
              success: "Успешно сте се пријавили",
            },
            resetPasswordOnFirstLogin: {
              pending: "Постављање нове лозинке у току",
              success: "Успешно сте поставили нову лозинку",
            },
          },
          header: {
            headerTitle: "Народни покрет Динара-Дрина-Дунав",
          },
          sidebar: {
            sidebarDashboard: "Контролна табла",
            sidebarAddMembers: "Управљање члановима",
            sidebarMemberTable: "Табела чланова",
            sidebarLogout: "Одјави се",
            sidebarStatistic: "Статистика",
            userManagement: "Управљање корисницима",
          },
          myProfile: {
            myProfileButton: "Мој профил",
            userManagementButton: "Управљање кор.",
            logoutButton: "Одјави се",
            chooseLanguage: "Изаберите језик",
            serbianLatinLanguage: "Српски (латиница)",
            englishLagnuage: "Енглески",
            serbianCyrlLanguage: "Српски (ћирилица)",
          },
          myProfileData: {
            myProfile: "Мој профил",
            firstName: "Име",
            lastName: "Презиме",
            email: "Имејл адреса",
            phoneNumber: "Телефон",
            role: "Рола",
            close: "Затвори",
          },
          accountManagement: {
            accountManagementText: "Управљање корисницима",
            close: "Затвори",
            selectAccount: "Изаберите постојећег корисника",
            account: "Корисник",
            aboutAccount: "Подаци о кориснику",
            firstName: "Име",
            lastName: "Презиме",
            email: "Имејл",
            phoneNumber: "Телефон",
            role: "Рола",
            cancelSelection: "Поништи одабир",
            create: "Креирај",
            update: "Ажурирај",
          },
          loginPage: {
            loginHeader: "Пријавите се",
            loginEmail: "Е-пошта",
            loginPassword: "Лозинка",
            loginForgotPassword: "Заборавили сте лозинку?",
            loginHelperEmail: "Унесите е-пошту",
            loginHelperPassowrd: "Унесите лозинку",
          },
          resetPasswordOnFirstLoginModal: {
            title: "Поставите нову лозинку",
            label: "Нова лозинка",
            submit: "Поставите лозинку",
          },
          forgotPasswordModal: {
            forgotPasswordTitle: "ЗАБОРАВИЛИ СТЕ ЛОЗИНКУ?",
            forgotPasswordEmail: "Email адреса",
            forgotPasswordEmailHelper: "Унесите email адресу налога.",
            codeTitle: "Код",
            codeHelper: "Унесите код који сте добили.",
            newPasswordTitle: "Нова Лозинка",
            newPasswordHelper: "Унесите нову жељену лозинку.",
            confirmNewPassword: "Потврдите нову лозинку",
            confirmNewPasswordHelper: "Унесите поново нову жељену лозинку.",
            minimumCharacters: "најмање 8 знакова",
            minimumCapital: "најмање 1 велико слово",
            minimumNumber: "најмање 1 број",
            minimumSpecialCharacter: "најмање 1 специјални знак",
            passwordConfirmed: "лозинка потврдјена",
          },
          dashboardPage: {
            accordionHeader: "Претрага",
            accordionTitle: "Поља за претрагу:",
            accordionSubtitle: "Унесите барем 3 карактера",
            accordionPlaceholderFirstName: "Име",
            accordionPlaceholderLastName: "Презиме",
            accordionPlaceholderJmbg: "ЈМБГ",
            nextCardNumberLabel: "Следећи чл. број",
            accordionPlaceholderCardNumber: "Број чланске карте",
            viewTableModalTitle: "Детаљи члана:",
            editTableModalTitle: "Измените податке члана:",
          },
          memberManagementPage: {
            searchExistingMember: "Претрага постојећих чланова:",
            personalDataTitle: "Лични подаци",
            personalDataFirstName: "Име",
            personalDataParentName: "Очево име",
            personalDataLastName: "Презиме",
            personalDataBirthDate: "Датум рођења",
            personalDataBirthPlace: "Место рођења",
            personalDataIdNumber: "Број личне карте",
            personalDataUin: "ЈМБГ",
            personalDataMale: "Мушко",
            personalDataFemale: "Женско",
            personalDataErrorMessageFirstName:
              "Име мора садржати макар два слова",
            personalDataErrorMessageLastName:
              "Презиме мора садржати макар два слова",
            personalDataErrorMessageDateofbirth: "Датум рођења је обавезан ",
            addressTitle: "Подаци пребивалишта",
            addressesCity: "Место",
            addressesStreet: "Улица",
            addressesHomeNumber: "Кућни бр.",
            addressesResidential: "Адреса становања је иста",
            addressesElectionPlaceArea: "Бирачко место",
            addressesElectionPlaceRegionArea: "Подручје бирачког места",
            addressesElectionPlaceRegionNumber: "Број бр. места",
            addressesElectionPlaceRegionName: "Назив бир. места",
            addressesElectionPlaceCheckboxLabel: "Ручно изаберите бир. место",
            addressesElectionPlaceRegionCheckboxLabel:
              "Ручно изаберите регију бир. места",
            addressesElectionPlaceDetailsModalTitle: "Детаљи бирачког места",
            addressesElectionPlaceDetailsModalNumber: "Број:",
            addressesElectionPlaceDetailsModalName: "Назив:",
            addressesElectionPlaceDetailsModalAddress: "Адреса:",
            addressesElectionPlaceDetailsModalPlace: "Место:",
            addressesDataErrorMessageCity: "Град пребивалишта је обавезан",
            addressesDataErrorMessageStreet: "Улица пребивалишта је обавезна",
            addressesDataErrorHomeNumber: "Кућни број је обавезан",
            contactTitle: "Контакт подаци",
            contactMobilePhone: "Мобилни телефон",
            contactLandingPhone: "Фиксни телефон",
            contactBussinesPhone: "Пословни телефон",
            contactEmail: "Е-маил адреса",
            contactTwitter: "Twitter",
            contactInstagram: "Instagram",
            contactFacebook: "Facebook",
            contactOther: "Друго",
            cardDataTitle: "Подаци чланске карте",
            cardDataNumber: "Број чланске карте",
            cardDataIssued: "Чланска карта издата",
            cardDataPlaceOfIssue: "Место издавања",
            cardDataDateOfIssue: "Датум издавања",
            cardDataManual: "Мануелно одабери",
            cardDataErrorMessageCardNumber: "Члански број није слободан",
            employmentDataTitle: "Подаци о запослењу",
            employmentDataIsEmplyed: "Запослен/а",
            employmentDataIsEmplyedYes: "Да",
            employmentDataIsEmplyedNo: "Не",
            employmentDataIsEmplyedOther: "Остало",
            employmentDataCompany: "Остало",
            employmentDataNameOfCompany:
              "Назив фирме, предузећа, установе и седишта",
            employmentDataWorkPlace: "Радно место",
            employmentDataTemporary: "Запослен/а за стално",
            educationTitle: "Подаци о школовању",
            educationElementarySchool: "Основна школа",
            educationHighSchool: "Средња школа (смер)",
            educationBasicVocationalSchool: "Основне струковне студије",
            educationSpecialistVocationalSchool:
              "Специјалистичке струковне студије",
            educationAcademicStudies: "Академске студије",
            educationMasterAcademicStudies: "Мастер академске студије",
            educationSpecialistAcademicStudies:
              "Специјалистичке академске студије",
            educationDoctoralAcademicStudies: "Докторске академске студије",
            educationComputerSkills: "Оспособљеност за рад на рачунару",
            educationNo: "Не",
            educationYes: "Да",
            educationLevelThird: "III степен",
            educationLevelFourth: "IV степен",
            languageTitle: "Језици члана",
            languageLanguage: "Језик",
            languageLevel: "Ниво",
            languageActive: "Активно",
            languagePassive: "Пасивно",
            otherDataTitle: "Остали подаци",
            otherDataHobby: "Хоби",
            otherDataSaintDay: "Крсна слава",
            otherDataSaintDayDate: "Датум крсне славе",
            otherDataRecommendation: "Препорука члана",
            otherDataNoteOne: "Белешка 1",
            otherDataNoteSecond: "Белешка 2",
            otherDataNoteThird: "Белешка 3",
          },
          memberTablePage: {
            personalData: "Општи подаци члана",
            address: "Пребивалиште члана",
            card: "Подаци чланске карте",
            contact: "Контакт подаци члана",
            education: "Образовање члана",
            employment: "Запослење члана",
            languages: "Језици члана",
            otherData: "Остали подаци члана",
            allMember: "Сви општи подаци",
            allAddress: "Сви подаци пребивалишта",
            allCard: "Сви подаци чланске карте",
            allContact: "Сви контакт подаци",
            allEducation: "Сви подаци образовања",
            allEmployment: "Сви подаци запослења",
            allLanguages: "Сви подаци језика",
            allOtherData: "Сви остали подаци",
            predefinedReports: "Предефинисани извештаји чланова",
            predefinedReportByLocalCommunity: "Извештај по месној заједници",
            predefinedReportByCity: "Извештај по граду",
            predefinedReportByElectionPlace: "Извештај по бирачком месту",
            predefinedReportDefault: "Предефинисан извештај",
            exportExcelTitle: "Извоз excel",
            settingsTitle: "Подешавања",
            exportExcel: "Извези excel",
            exportPdf: "извези pdf",
            selectionOfTableColumns: "Избор колона табеле",
            tableActionTitle: "Акција",
            tableOrdinalNumberTitle: "Р. бр.",
            tableFirstNameTitle: "Име",
            tableLastNameTitle: "Презиме",
            tableLastNameParentName: "Име родитеља",
            tableUinTitle: "Јмбг",
            tableBirthDateTitle: "Датум рођења",
            tableAgeTitle: "Године старости",
            tableBirthPlaceTitle: "Место рођења",
            tableIdNumberTitle: "Број личне карте",
            tableGenderTitle: "Пол",
            tableResidenceCityTitle: "Град",
            tableResidenceStreetTitle: "Улица",
            tableResidenceHomeNumberTitle: "Кућни број",
            tableCityTitle: "Град становања",
            tableStreetTitle: "Улица становања",
            tableHomeNumberTitle: "Кућни број становања",
            tableElectionPlaceNameTitle: "Бирачко место",
            tableElectionPlaceNumberTitle: "Број бирачког места",
            tableElectionPlaceAddressTitle: "Адреса бирачког места",
            tableLocalCommunityNameTitle: "Месна заједница",
            tableIdCardNumberTitle: "Број чланске карте",
            tableIsCardPublishedTitle: "Чланска карта издата",
            tableMemberCardCityTitle: "Град издавања чланске карте",
            tableMemberCardDateTitle: "Датум издавања чланске карте",
            tableLandingPhoneTitle: "Фиксни телефон",
            tableMobilePhoneTitle: "Мобилни телефон",
            tableBusinessPhoneTitle: "Пословни телефон",
            tableEmailTitle: "Е-маил",
            tableFacebookTitle: "Facebook",
            tableInstagramTitle: "Instagram",
            tableTwitterTitle: "Twitter",
            tableOtherContactTitle: "Остало контакт",
            tableElementarySchoolTitle: "Основна школа",
            tableHighSchoolTitle: "Средња школа",
            tableHighSchoolLevelTitle: "Степен средње школе",
            tableVocationalStudiesTitle: "Струковне студије",
            tableSpecialistVocationalStudiesTitle:
              "Специјалне струковне студије",
            tableBasicAcademicStudiesTitle: "Основне академске студије",
            tableBasicAcademicStudiesLevelTitle:
              "Степен основних академских студија",
            tableMasterAcademicStudiesTitle: "Мастер академске студије",
            tableMasterAcademicStudiesLevelTitle:
              "Степен мастер академских студија",
            tableSpecialAcademicStudiesTitle: "Специјалне академске студије",
            tableDoctoralAcademicStudiesTitle: "Докторске академске студије",
            tableWorkOnComputerTitle: "Рад на рачунару",
            tableIsEmployedTitle: "Запослен/а",
            tableCompanyTitle: "Фирма",
            tableWorkPlaceTitle: "Радно место",
            tableTemporaryEmployed: "Запослен/а за стално",
            tableOtherEmployment: "Остало запослење",
            tableLanguagesTitle: "Језици",
            tableLanguageSubtitleLanguage: "Језик",
            tableLanguageSubtitleLevel: "Ниво",
            tableLanguageSubtitleActive: "Активно користи",
            tableHobbyTitle: "Хоби",
            tableSaintDay: "Крсна слава",
            tableSaintDayDate: "Датум крсне славе",
            tableRecommendationTitle: "Препорука члана",
            tableNoteТitle: "Белешка",
            tableNoteFirstTitle: "Белешка 1",
            tableNoteSecondTitle: "Белешка 2",
            tableNoteThirdTitle: "Белешка 3",
            detailOfExcelFileTitle: "Детаљи excel фајла",
            detailOfExcelFileGeneral: "Генерално",
            detailOfExcelFileNameOfDocument: "Назив документа",
            detailOfExcelFileHeader: "Заглавље документа",
            detailOfExcelFileFirstLine: "Прва линија",
            detailOfExcelFileSecondLine: "Друга линија",
            filterTitle: "Филтери",
            filterNoRequirement: "Без услова",
            filterEquals: "Једнако",
            filterNotEquals: "Није једнако",
            filterEmpty: "Празно",
            filterNotEmpty: "Није празно",
            filterLessThan: "Мање од",
            filterLessThanOrEqualTo: "Мање од или једнако",
            filterGreaterThan: "Веће од",
            filterGreaterThanOrEqualTo: "Веће од или једнако",
            filterStartsWith: "Почиње са",
            filterNotStartsWith: "Не почиње са",
            filterEndsWith: "Заврашава се са",
            filterNotEndsWith: "Не завршава се са",
            filterContains: "Садржи",
            filterNotContains: "Не садржи",
            filterFieldPropName: "Поље",
            filterFieldFirstRequirement: "Први услов",
            filterFieldSecondRequirement: "Други услов",
            filterLogicalOperatorAnd: "и",
            filterLogicalOperatorOr: "или",
            filterFieldFirstData: "Први податак",
            filterFieldSecondData: "Други податак",
            resultsPerPage: "Резултата по страници",
            tableFiltersTitle: "Филтери табеле",
            showingResults: "Приказује",
            showingOf: "од",
            results: "резултата",
            rowsPerPage: "Резултата по страници",

            getExcelReportCurrentDataFileName: "Чланови - Excel",
            getPdfReportCurrentDataFileName: "Чланови - PDF",

            appliedFiltersLabel: "Примењени филтери",
          },
          userTablePage: {
            userTableHeaderAction: "Акција",
            userTableHeaderOridinalNumber: "Редни бр.",
            userTableHeaderId: "Ид",
            userTableHeaderFirstName: "Име",
            userTableHeaderLastName: "Презиме",
            userTableHeaderEmail: "Емаил",
            userTableHeaderPhone: "Телефон",
            userTableHeaderRole: "Рола",
            userTableHeaderIsActive: "Активан",
            userTableHeaderIsDelete: "Обрисан",
            userTableAlert: "Да ли сигурно желите да",
            userTableAlertDeactivate: "деактивирате",
            userTableAlertActivate: "активирате",
            userTableAlertRemove: "обришете",
            userTableAlertGetBack: "вратите",
            userTableAlertUser: "корисника",
            createNewUserIsDeleted: "Избрисан",
            account: "налог",
            userFormStateCreate: "Креирај",
            userFormStateEdit: "Измени",
            userFormStateView: "Прегледај",
            userFormStateNone: "",
          },
          statisticPage: {
            ageStatistic: "Старосна статистика",
            statisticByCity: "Статистика по месту ",
            totalMembers: "Укупно чланова: ",
            ageOfMembers: "Године чланова и пол: ",
          },
          alerts: {
            statisticPageAlert: "Копирано!",
          },
          buttons: {
            loginButton: "Пријавите се",
            dashboardAddMember: "креирај",
            dashboardUpdateMember: "ажурирај",
            dashboardClearFields: "очисти поља",
            dashboardDeleteMember: "обриши",
            close: "Затвори",
            selectionOfTableColumns: "избор колона табеле",
            save: "Сачувај",
            electionPlaceDetails: "Детаљи бир. места",
            cancelFilter: "Поништите филтере",
            submitFilter: "Примените филтере",
            menageDocumentData: "Управљајте подацима документа",
            sendRequest: "Пошаљите захтев",
            resetPassword: "Ресетујте лозинку",
            createNewUser: "креирајте новог корисника",
          },
          tooltips: {
            predefinedReports: "Предефинисани извештаји чланова",
            pdfReport: "PDF извештај чланова",
            excelReport: "Excel извештај чланова",
            tableActionRowsEdit: "Измените информације о члановима",
            tableActionRowsView: "Погледајте податке члана",
            tableActionRowsDelete: "Обришите члана",
            tableActionRowsUserEdit: "Измените информације о корисницима",
            tableActionRowsUserView: "Погледајте податке корисника",
            tableActionRowsUserDeactivate: "Деактивирајте корисника",
            tableActionRowsUserActivate: "Aктивирајте корисника",
            tableActionRowsUserRemove: "Обришите корисника",
            tableActionRowsUserGetBack: "Вратите корисника",
            tooltipsStatistic:
              "Кликом на текст копирате садржај у меморију клипборда",
            manuallySelectCardDataText:
              "Мануелно изаберите слободан број чланских карата.",
            isAddressSameAddressSectionText:
              "Уколико адреса становања није иста као адреса на личној карти, можете додати и адресу становања због евиденције.",
            electionPlaceRegionAddressSectionText:
              " Уколико регија бирачког места није постављена аутоматски, можете ручно изабрати од понуђених регија из наведеног града.",
            otherEmploymentText:
              "Додатне информације о запослењу ако постоје неке специфичности.",
            memberRecommendationOtherText:
              "Име и презиме члана који је дао препоруку.",
            memberNoteOtherText: "Додатне информације о члану.",
            tooltipTitle: "ИНФОРМАЦИЈЕ",
            languageLevelLanguageSection: "Ниво језика по ЦЕФР стандарду.",
            contactOtherContactSectionText:
              "Додатне мреже за контакт (Skype, LinkedIn ...)",
          },
          icons: {
            filters: "Изаберите филтере",
          },
          notifications: {
            createPersonalDataPending: "Креирање члана...",
            createPersonalDataFulfilled: "Успешно је креиран члан!",
            updatePersonalDataPending: "Ажурирање члана...",
            updatePersonalDataFulfilled: "Успешно је ажуриран члан!",
            deleteMemberPending: "Брисање члана...",
            deleteMemberFulfilled: "Члан је успешно обрисан!",

            loginPending: "Пријављивање на систем...",
            loginFulfilled: "Успешно сте се пријавили на систем.",

            createAccountPending: "Креирање корисничког налога",
            createAccountFulfilled: "Кориснички налог креиран, имејл је послат",
            updateAccountPending: "Ажурирање корисничког налога",
            updateAccountFulfilled: "Кориснички налог је ажуриран",

            getExcelReportCurrentDataPending:
              "Креирање Excel извештаја чланова из табеле...",
            getExcelReportCurrentDataFulfilled:
              "Креирање Excel извештаја чланова из табеле завршено.",
            getPdfReportCurrentDataPending:
              "Креирање PDF извештаја чланова из табеле...",
            getPdfReportCurrentDataFulfilled:
              "Креирање PDF извештаја чланова из табеле завршено.",
          },
          errorNotifications: {
            undefined: "Догодила се грешка на серверу.",
            "-500": "Догодила се грешка на серверу.",
            0: "Догодила се грешка на серверу.",
            1: "Адреса члана не постоји.",
            2: "Чланска карта члана не постоји.",
            3: "Контакт подаци члана не постоје.",
            4: "Подаци о образовању члана не постоје.",
            5: "Подаци о запослењу члана не постоје.",
            6: "Страни језик члана не постоји.",
            7: "Остали подаци члана не постоје.",
            8: "Лични подаци члана не постоје.",
            9: "Члан са овим подацима већ постоји.",
            10: "Кориснички налог не постоји.",
            11: "Кориснички налог са овим емаил-ом не постоји.",
            12: "Имејл адреса је већ у употреби.",
            13: "Корисничка рола са овим називом не постоји.",
            14: "Корисничка налог није активан. Молимо Вас контактирајте администратора.",
            15: "Погрешна лозинка.",
            16: 'Неисправан "рефреш токен".',
            17: "Ваша сесија је истекла.",
            18: "Општина не постоји.",
            19: "Регија не постоји.",
            20: "Град не постоји.",
            21: "Земља не постоји.",
            22: "Месна заједница не постоји.",
            23: "Месна заједница не постоји.",
            24: "Општина не постоји.",
            25: "Држава не постоји.",
            26: "Улица не постоји.",
            27: "Регија бирачког места не постоји.",
            28: "Бирачко место не постоји.",
            29: "Истекло је време за ресетовање Ваше лозинке.",
            30: "Рола не постоји.",
          },
          switch: {
            isActive: "Активан",
          },
          reportHeaderTitleModal: {
            title: "Извештај чланова",
            titleInReport: "Наслов у извештају",
            closeModal: "Затвори",
            getTheReport: "Преузми извештај",
          },
          toastMessages: {
            requestResetPasswordEmailError:
              "Унети подаци нису валидни, проверите их и покушајте поново!",
            requestResetPasswordEmailSuccess: "Успешно сте послали захтев!",
            requestResetPasswordSuccess: "Успешно сте ресетовали лозинку!",
            createdAccountError:
              "Унети подаци нису валидни, проверите их и покушајте поново!",
          },
        },
      },
    },
  });

export default i18n;
