import { Close, Lock } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import BasicInput from "../../../component/input/basic-input/BasicInput";
import { useEffect, useState } from "react";
import AppButton from "../../../component/button/AppButton";
import { colors } from "../../../app/theme/colors";
import { ResetPasswordRequest } from "../../../models/requests/auth/resetPasswordRequest";
import {
  resetPasswordOnFirstLogin,
  setTokens,
} from "../../../slices/auth/authSlice";
import { useAppDispatch } from "../../../app/redux/hooks";
import { LoginResponse } from "../../../models/Responses/accounts/loginResponse";
import { useResetPasswordOnFirstLoginModalStyles } from "./ResetPasswordOnFirstLoginModalStyles";
import CheckCircleOutlineTwoToneIcon from "@mui/icons-material/CheckCircleOutlineTwoTone";
import RadioButtonUncheckedTwoToneIcon from "@mui/icons-material/RadioButtonUncheckedTwoTone";
import { Visibility, VisibilityOff } from "@mui/icons-material";

type Props = {
  isOpen: boolean;
  handleClose: any;
  email: string;
  oldPass: string;
  loginResponse: LoginResponse | undefined;
};

const ResetPasswordOnFirstLoginModal = (props: Props) => {
  const { t } = useTranslation();
  const { classes } = useResetPasswordOnFirstLoginModalStyles();
  const dispatch = useAppDispatch();

  const [password, setPassword] = useState({
    passwordNew: "",
    passwordConfirm: "",
  });

  const [showNewPasswordState, setShowNewPasswordState] =
    useState<boolean>(false);
  const [showConfirmNewPasswordState, setShowConfirmNewPasswordState] =
    useState<boolean>(false);

  const [newPass, setNewPass] = useState<string>("");
  const [confirmNewPass, setConfirmNewPass] = useState<string>("");

  const [validLength, setValidLength] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [match, setMatch] = useState(false);
  const [requiredLength, setRequiredLength] = useState(8);
  const [validLenghtIcon, setValidLenghtIcon] = useState(false);
  const [validHasNumberIcon, setValidHasNumbertIcon] = useState(false);
  const [validUpperCaseIcon, setValidUpperCaseIcon] = useState(false);
  const [validSpecialCharIcon, setValidSpecialCharIcon] = useState(false);
  const [validMatchIcon, setValidMatchIcon] = useState(false);

  const handleShowNewPassword = (): void =>
    setShowNewPasswordState(!showNewPasswordState);
  const handleShowConfirmNewPassword = (): void =>
    setShowConfirmNewPasswordState(!showConfirmNewPasswordState);

  const onResetPass = async () => {
    if (!props.loginResponse) {
      return;
    }

    const request: ResetPasswordRequest = {
      oldPassword: props.oldPass,
      email: props.email,
      newPassword: newPass,
    };

    // console.log("request: ", request);

    const response = await dispatch(resetPasswordOnFirstLogin(request));

    const isError = (response as any).error;

    // If reset password is success

    if (isError) {
      return;
    }

    // Set tokens
    // And it will automatically navigate to dashboard

    await dispatch(
      setTokens({
        accessToken: props.loginResponse.accessToken,
        refreshToken: props.loginResponse.refreshToken,
      })
    );
  };

  useEffect(() => {
    setValidLength(newPass.length >= requiredLength ? true : false);
    setValidLenghtIcon(newPass.length >= requiredLength ? true : false);
    setUpperCase(newPass.toLowerCase() !== password.passwordNew);
    setValidUpperCaseIcon(newPass.toLowerCase() !== password.passwordNew);
    setHasNumber(/\d/.test(newPass));
    setValidHasNumbertIcon(/\d/.test(newPass));
    setMatch(!!newPass && newPass === confirmNewPass);
    setValidMatchIcon(!!newPass && newPass === confirmNewPass);
    setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(newPass));
    setValidSpecialCharIcon(
      /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(newPass)
    );
  }, [newPass, requiredLength, confirmNewPass]);

  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={props.handleClose}
        className="text-center"
        maxWidth="xs"
      >
        <DialogTitle
          style={{
            cursor: "pointer",
            background: colors.primary,
            color: "white",
          }}
        >
          <Grid
            style={{
              position: "sticky",
              background: colors.primary,
              textAlign: "end",
              border: "none",
              outline: "none",
            }}
          >
            <Close onClick={(e) => props.handleClose(e)} />
          </Grid>
          <h3
            style={{ color: "white", fontWeight: "bold", textAlign: "center" }}
          >
            {t("resetPasswordOnFirstLoginModal.title")}
          </h3>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} justifyContent="center" mt={2}>
            <Grid item xs={12}>
              <BasicInput
                name="newPassword"
                type={showNewPasswordState ? "text" : "password"}
                value={newPass}
                onChange={(e) => setNewPass(e.target.value)}
                label={t("resetPasswordOnFirstLoginModal.label")}
                height="35px"
                endAdornment={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowNewPassword}
                  >
                    {showNewPasswordState ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <BasicInput
                name="confirmNewPassword"
                type={showConfirmNewPasswordState ? "text" : "password"}
                value={confirmNewPass}
                onChange={(e) => setConfirmNewPass(e.target.value)}
                label={t("forgotPasswordModal.confirmNewPassword")}
                height="35px"
                endAdornment={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowConfirmNewPassword}
                  >
                    {showConfirmNewPasswordState ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                }
              />
            </Grid>
            <Grid item md={12} style={{ padding: "20px", marginTop: "-20px" }}>
              <div className={classes.passwordValidationWrapper}>
                {validLenghtIcon ? (
                  <CheckCircleOutlineTwoToneIcon
                    id="resetPassword_icon-passwordValidationSuccess"
                    fontSize="small"
                    className={classes.passwordValidationSuccessIcon}
                  />
                ) : (
                  <RadioButtonUncheckedTwoToneIcon
                    id="resetPassword_icon-passwordValidationError"
                    fontSize="small"
                    className={classes.passwordValidationErrorIcon}
                  />
                )}
                {validLength ? (
                  <Typography
                    id="resetPassword_validationSuccess-minimumCharacters"
                    className={classes.passwordValidationSuccess}
                  >
                    {t("forgotPasswordModal.minimumCharacters")}
                  </Typography>
                ) : (
                  <Typography
                    id="resetPassword_validationError-minimumCharacters"
                    className={classes.passwordValidationError}
                  >
                    {t("forgotPasswordModal.minimumCharacters")}
                  </Typography>
                )}
              </div>
              <div className={classes.passwordValidationWrapper}>
                {validUpperCaseIcon ? (
                  <CheckCircleOutlineTwoToneIcon
                    id="resetPassword_icon-passwordValidationSuccess"
                    fontSize="small"
                    className={classes.passwordValidationSuccessIcon}
                  />
                ) : (
                  <RadioButtonUncheckedTwoToneIcon
                    id="resetPassword_icon-passwordValidationError"
                    fontSize="small"
                    className={classes.passwordValidationErrorIcon}
                  />
                )}
                {upperCase ? (
                  <Typography
                    id="resetPassword_validationSuccess-capitalLetter"
                    className={classes.passwordValidationSuccess}
                  >
                    {t("forgotPasswordModal.minimumCapital")}
                  </Typography>
                ) : (
                  <Typography
                    id="resetPassword_validationError-capitalLetter"
                    className={classes.passwordValidationError}
                  >
                    {t("forgotPasswordModal.minimumCapital")}
                  </Typography>
                )}
              </div>
              <div className={classes.passwordValidationWrapper}>
                {validHasNumberIcon ? (
                  <CheckCircleOutlineTwoToneIcon
                    id="resetPassword_icon-passwordValidationSuccess"
                    fontSize="small"
                    className={classes.passwordValidationSuccessIcon}
                  />
                ) : (
                  <RadioButtonUncheckedTwoToneIcon
                    id="resetPassword_icon-passwordValidationError"
                    fontSize="small"
                    className={classes.passwordValidationErrorIcon}
                  />
                )}
                {hasNumber ? (
                  <Typography
                    id="resetPassword_validationSuccess-minimumNumber"
                    className={classes.passwordValidationSuccess}
                  >
                    {t("forgotPasswordModal.minimumNumber")}
                  </Typography>
                ) : (
                  <Typography
                    id="resetPassword_validationError-minimumNumber"
                    className={classes.passwordValidationError}
                  >
                    {t("forgotPasswordModal.minimumNumber")}
                  </Typography>
                )}
              </div>
              <div className={classes.passwordValidationWrapper}>
                {validSpecialCharIcon ? (
                  <CheckCircleOutlineTwoToneIcon
                    id="resetPassword_icon-passwordValidationSuccess"
                    fontSize="small"
                    className={classes.passwordValidationSuccessIcon}
                  />
                ) : (
                  <RadioButtonUncheckedTwoToneIcon
                    id="resetPassword_icon-passwordValidationError"
                    fontSize="small"
                    className={classes.passwordValidationErrorIcon}
                  />
                )}
                {specialChar ? (
                  <Typography
                    id="resetPassword_validationSuccess-specialCharacter"
                    className={classes.passwordValidationSuccess}
                  >
                    {t("forgotPasswordModal.minimumSpecialCharacter")}
                  </Typography>
                ) : (
                  <Typography
                    id="resetPassword_validationError-specialCharacter"
                    className={classes.passwordValidationError}
                  >
                    {t("forgotPasswordModal.minimumSpecialCharacter")}
                  </Typography>
                )}
              </div>
              <div className={classes.passwordValidationWrapper}>
                {validMatchIcon ? (
                  <CheckCircleOutlineTwoToneIcon
                    id="resetPassword_icon-passwordValidationSuccess"
                    fontSize="small"
                    className={classes.passwordValidationSuccessIcon}
                  />
                ) : (
                  <RadioButtonUncheckedTwoToneIcon
                    id="resetPassword_icon-passwordValidationError"
                    fontSize="small"
                    className={classes.passwordValidationErrorIcon}
                  />
                )}
                {match ? (
                  <Typography
                    id="resetPassword_validationSuccess-passwordConfirm"
                    className={classes.passwordValidationSuccess}
                  >
                    {t("forgotPasswordModal.passwordConfirmed")}
                  </Typography>
                ) : (
                  <Typography
                    id="resetPassword_validationError-passwordConfirm"
                    className={classes.passwordValidationError}
                  >
                    {t("forgotPasswordModal.passwordConfirmed")}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              {match ? (
                <AppButton
                  label={t("resetPasswordOnFirstLoginModal.submit")}
                  startIcon={<Lock />}
                  color={colors.green}
                  onClick={onResetPass}
                  hover={colors.green}
                />
              ) : (
                <AppButton
                  label={t("resetPasswordOnFirstLoginModal.submit")}
                  startIcon={<Lock />}
                  color={colors.green}
                  onClick={onResetPass}
                  hover={colors.green}
                  disabled={true}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ResetPasswordOnFirstLoginModal;
