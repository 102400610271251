import { AxiosResponse } from "axios";
import { Token } from "../../models/enums/auth/token";
import { DisplayLanguage } from "../../models/enums/displayLanguage";
import { logout, refresh, setTokens } from "../../slices/auth/authSlice";
import { api } from "./api";

let calledRefreshToken: boolean = false;

const setup = (store: any) => {
  const { dispatch } = store;

  // Request interceptor
  api.interceptors.request.use(
    (config) => {
      let displayLanguage = DisplayLanguage.SerbianLatin;

      const localStorageTranslation = localStorage.getItem("i18nextLng");

      switch (localStorageTranslation) {
        case "rs":
          displayLanguage = DisplayLanguage.SerbianLatin;
          break;

        case "rsCyrl":
          displayLanguage = DisplayLanguage.SerbianCyrillic;
          break;

        case "en":
          displayLanguage = DisplayLanguage.English;
          break;

        case "en-US":
          displayLanguage = DisplayLanguage.English;
          break;

        default:
          displayLanguage = DisplayLanguage.SerbianLatin;
          break;
      }

      config.headers["displaylanguage"] = displayLanguage;

      const token = localStorage.getItem(Token.AccessToken);

      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptor
  api.interceptors.response.use(
    (res: AxiosResponse) => {
      return res.data;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "auth/login" && err.response) {
        console.log("Access token expired");

        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            if (!calledRefreshToken) {
              console.log("Calling refresh token.");

              calledRefreshToken = true;

              const rs = await dispatch(
                refresh({
                  currentRefreshToken:
                    localStorage.getItem(Token.RefreshToken) ?? "",
                })
              );

              // console.log("rs: ", rs);

              if (!rs.payload) {
                console.log("Logout...");
                dispatch(logout());
                return;
              }

              const { refreshToken, accessToken } = rs.payload;

              await dispatch(setTokens({ accessToken, refreshToken }));

              if (originalConfig) {
                originalConfig.headers.Authorization = `Bearer ${accessToken}`;
              }

              return api(originalConfig); // Repeat previous request which returned 401
            }
          } catch (_error) {
            return Promise.reject(_error);
          } finally {
            calledRefreshToken = false;
          }
        }
      }

      return Promise.reject(err?.response?.data);
    }
  );
};

export default setup;
