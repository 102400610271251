import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BasicInput from "../../../component/input/basic-input/BasicInput";
import CustomTooltip from "../../../component/tooltip/CustomTooltip";
import { Member } from "../../../models/data/members/Member";
import MemberSectionType from "../../../models/types/memberSectionTypes/memberSectionType";

interface Props {
  member: Member;
  onChange: any;
}

export const MemberContactData = ({ member, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.contactMobilePhone")}
          value={member?.contact?.mobilePhone}
          onChange={(e) =>
            onChange(
              e.target.value,
              "mobilePhone",
              MemberSectionType.memberContact
            )
          }
        />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.contactLandingPhone")}
          value={member?.contact?.landPhone}
          onChange={(e) =>
            onChange(
              e.target.value,
              "landPhone",
              MemberSectionType.memberContact
            )
          }
        />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.contactBussinesPhone")}
          value={member?.contact?.workPhone}
          onChange={(e) =>
            onChange(
              e.target.value,
              "workPhone",
              MemberSectionType.memberContact
            )
          }
        />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.contactEmail")}
          value={member?.contact?.email}
          onChange={(e) =>
            onChange(e.target.value, "email", MemberSectionType.memberContact)
          }
          width="87.5%"
        />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.contactTwitter")}
          value={member?.contact?.twiter}
          onChange={(e) =>
            onChange(e.target.value, "twiter", MemberSectionType.memberContact)
          }
        />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.contactInstagram")}
          value={member?.contact?.instagram}
          onChange={(e) =>
            onChange(
              e.target.value,
              "instagram",
              MemberSectionType.memberContact
            )
          }
        />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.contactFacebook")}
          value={member?.contact?.facebook}
          onChange={(e) =>
            onChange(
              e.target.value,
              "facebook",
              MemberSectionType.memberContact
            )
          }
        />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <Grid item md={12} xs={12} style={{ display: "flex" }}>
          <BasicInput
            label={t("memberManagementPage.contactOther")}
            value={member?.contact?.other}
            onChange={(e) =>
              onChange(e.target.value, "other", MemberSectionType.memberContact)
            }
          />
          <CustomTooltip
            infoText={t("tooltips.contactOtherContactSectionText")}
            infoTitle={t("tooltips.tooltipTitle")}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
