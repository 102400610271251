import React from "react";
import { Container, CssBaseline } from "@mui/material";
import { Row } from "react-bootstrap";

interface Props {
  children?: React.ReactNode;
}

export default function Layout({ children }: Props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="xl">
        <Row id="row-layout">{children}</Row>
      </Container>
    </React.Fragment>
  );
}
