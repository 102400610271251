import { api } from "../../app/redux/api";
import { Role } from "../../models/data/accounts/Role";
import { SaveAccountModel } from "../../models/requests/accounts/saveAccountModel";
import { SetAccountStatus } from "../../models/requests/accounts/setAccountStatus";
import { SetIsDeletedAccount } from "../../models/requests/accounts/setIsDeletedAccount";
import { AccountResponse } from "../../models/Responses/accounts/accountResponse";
import { APIResponse } from "../../models/types/api/APIResponse";

const getAccounts = async () => {
  return api.get("/accounts") as Promise<APIResponse<AccountResponse[]>>;
};

const saveAccount = async (request: SaveAccountModel) => {
  return api.put("/accounts/save-account", request) as Promise<
    APIResponse<AccountResponse>
  >;
};

const setStatus = async (request: SetAccountStatus) => {
  return api.put("/accounts/set-status", request) as Promise<
    APIResponse<SetAccountStatus>
  >;
};

const getRoles = async () => {
  return api.get("/roles") as Promise<APIResponse<Role[]>>;
};

const setIsDeleted = async (request: SetIsDeletedAccount) => {
  return api.put("/accounts/set-is-deleted", request) as Promise<
    APIResponse<AccountResponse>
  >;
};

export const AccountApi = {
  GetAllAccounts: getAccounts,
  SaveAccount: saveAccount,
  SetStatus: setStatus,
  GetAllRoles: getRoles,
  SetIsDeleted: setIsDeleted,
};
