import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { AddUpdateMembers } from "../../../smart-membership/add-update-member/addUpdateMember";
import { useAppSelector } from "../../../../app/redux/hooks";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTableModalStyles } from "../styles/tableModalStyles";

interface Props {
  isOpen: boolean;
  handleClose: any;
}

const TableEditModal = ({ handleClose, isOpen }: Props) => {
  const { t } = useTranslation();
  const { classes } = useTableModalStyles();
  const selectedMember = useAppSelector(
    (state) => state.members.selectedMember
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className="text-center"
      maxWidth="lg"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Grid className={classes.grid}>
          <CloseIcon onClick={(e) => handleClose(e)} />
        </Grid>
        <h3 style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
          {t("dashboardPage.editTableModalTitle")}{" "}
          {selectedMember?.personalData.firstName}{" "}
          {selectedMember?.personalData.lastName}
        </h3>
      </DialogTitle>
      <DialogContent className="tableEdit_modal_body">
        {<AddUpdateMembers />}
      </DialogContent>
    </Dialog>
  );
};

export default TableEditModal;
