import { Navigate } from "react-router";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { Role, RoleOrdinal } from "../../models/enums/roles/roles";
import DashboardPage from "../../pages/dashboard/DashboardPage";
import LoginPage from "../../pages/login/LoginPage";
import NotFoundPage from "../../pages/notFound/NotFoundPage";
import SmartMembershipPage from "../../pages/smart-membership/SmartMembershipPage";
import StatisticPage from "../../pages/statisticPage/statisticPage";
import TablePage from "../../pages/table/TablePage";
import UserManagementPage from "../../pages/user-management/UserManagementPage";
import {
  selectEmail,
  selectLoggedInUser,
  selectRole,
} from "../../slices/auth/authSlice";
import { useAppSelector } from "../redux/hooks";
import { appRoutes } from "./appRoutes";

type GuardedRouteProps = {
  element: JSX.Element;
  path: string;
  isLoginPage?: boolean;
  forbiddenRoles?: RoleOrdinal[];
};

const GuardedRoute = (props: GuardedRouteProps) => {
  const {} = useLoggedInUser();
  const email = useAppSelector(selectEmail);
  const role = useAppSelector(selectRole);

  if (email) {
    if (props.isLoginPage) {
      return <Navigate to={appRoutes.dashboard} />;
    } else {
      const isAllowedAccess = role
        ? !props.forbiddenRoles?.includes(role)
        : false;

      console.log("isAllowedAccess: ", isAllowedAccess);

      if (isAllowedAccess) {
        console.log("render result");
        return props.element;
      } else {
        console.log("NotFoundPage");
        return <NotFoundPage />;
      }
    }
  } else {
    return <LoginPage />;
  }
};

const AppRouter = () => {
  const loggedInUser = useAppSelector(selectLoggedInUser);
  const role = useAppSelector(selectRole);

  const loginPage = (
    <GuardedRoute
      path={appRoutes.login}
      isLoginPage={true}
      element={<LoginPage />}
    />
  );

  const dashboardPage = (
    <GuardedRoute path={appRoutes.dashboard} element={<DashboardPage />} />
  );

  const tablePage = (
    <GuardedRoute
      path={appRoutes.reviewExportPrintTable}
      element={<TablePage />}
    />
  );

  const smartMembershipPage = (
    <GuardedRoute
      path={appRoutes.smartMembership}
      element={<SmartMembershipPage />}
    />
  );

  const statisticsPage = (
    <GuardedRoute
      path={appRoutes.statisticPage}
      element={<StatisticPage />}
      forbiddenRoles={[RoleOrdinal.User]}
    />
  );

  const userManagementPage = (
    <GuardedRoute
      path={appRoutes.userManagement}
      element={<UserManagementPage />}
      forbiddenRoles={[RoleOrdinal.User]}
    />
  );

  const notFoundPage = <NotFoundPage />;

  return (
    <Router>
      <Routes>
        <Route path={appRoutes.login} element={loginPage} />
        <Route path={appRoutes.dashboard} element={dashboardPage} />
        <Route path={appRoutes.reviewExportPrintTable} element={tablePage} />
        <Route path={appRoutes.smartMembership} element={smartMembershipPage} />
        {role !== RoleOrdinal.User && (
          <Route path={appRoutes.statisticPage} element={statisticsPage} />
        )}
        {role !== RoleOrdinal.User && (
          <Route path={appRoutes.userManagement} element={userManagementPage} />
        )}
        <Route path="*" element={notFoundPage} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
