import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/redux/hooks";
import BasicInput from "../../../component/input/basic-input/BasicInput";
import CustomTooltip from "../../../component/tooltip/CustomTooltip";
import MemberSectionType from "../../../models/types/memberSectionTypes/memberSectionType";
import { selectNextCardNumber } from "../../../slices/members/memberSlice";
import { useCardSectionStyles } from "./cardSectionStyles";

interface Props {
  onChange: any;
  member: any;
}

export const MemberCardData = ({ member, onChange }: Props) => {
  const { t } = useTranslation();
  const { classes } = useCardSectionStyles();

  const [
    enableCardNumberManualEnterState,
    setEnableCardNumberManualEnterState,
  ] = useState<boolean>(false);
  const nexCardNumber = useAppSelector(selectNextCardNumber);

  const formattedDate = () =>
    member?.card?.establishedDate &&
    member?.card?.establishedDate.substring(0, 10);

  return (
    <Grid container spacing={2}>
      <Grid item lg={3} md={6} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.cardDataNumber")}
          value={
            member?.card?.number !== 0
              ? member?.card?.number
              : nexCardNumber || 0
          }
          disabled={enableCardNumberManualEnterState === false}
          onChange={(e) =>
            onChange(+e.target.value, "number", MemberSectionType.memberCard)
          }
          errorMessage={t(
            "memberManagementPage.cardDataErrorMessageCardNumber"
          )}
        />
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <FormGroup>
            <FormControlLabel
              className={classes.checkboxLabel}
              control={
                <Checkbox
                  disabled
                  checked={enableCardNumberManualEnterState === true}
                  onChange={(e) =>
                    setEnableCardNumberManualEnterState(e.target.checked)
                  }
                  size="small"
                  className={classes.checkbox}
                />
              }
              label={
                <>
                  {t("memberManagementPage.cardDataManual")}
                  <CustomTooltip
                    infoText={t("tooltips.manuallySelectCardDataText")}
                    infoTitle={t("tooltips.tooltipTitle")}
                  />
                </>
              }
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid item lg={3} md={6} sm={12} xs={12} textAlign={"start"}>
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <Typography variant="body1" className={classes.inputTitle}>
              {t("memberManagementPage.cardDataIssued")}:
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <FormControlLabel
              value={
                member.member !== null && member?.card?.isPublished === false
                  ? true
                  : false
              }
              checked={
                member?.card?.isPublished !== null &&
                member?.card?.isPublished === false
                  ? true
                  : false
              }
              control={
                <Radio
                  className={classes.radioButton}
                  onClick={(e) =>
                    onChange(false, "isPublished", MemberSectionType.memberCard)
                  }
                />
              }
              label={
                <Typography variant="body1" color="textSecondary">
                  {t("memberManagementPage.employmentDataIsEmplyedNo")}
                </Typography>
              }
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 14,
                },
              }}
            />
            <FormControlLabel
              value={
                member?.card?.isPublished !== null &&
                member?.card?.isPublished === true
                  ? true
                  : false
              }
              checked={
                member?.card?.isPublished !== null &&
                member?.card?.isPublished === true
                  ? true
                  : false
              }
              control={
                <Radio
                  className={classes.radioButton}
                  onClick={(e) =>
                    onChange(true, "isPublished", MemberSectionType.memberCard)
                  }
                />
              }
              label={
                <Typography variant="body1" color="textSecondary">
                  {t("memberManagementPage.employmentDataIsEmplyedYes")}
                </Typography>
              }
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 14,
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={3} md={6} sm={12} xs={12}>
        <BasicInput
          label={t("memberManagementPage.cardDataPlaceOfIssue")}
          value={member?.card?.publishingCity}
          onChange={(e) =>
            onChange(
              e.target.value,
              "publishingCity",
              MemberSectionType.memberCard
            )
          }
        />
      </Grid>
      <Grid item lg={3} md={6} sm={12} xs={12}>
        <BasicInput
          type="date"
          label={t("memberManagementPage.cardDataDateOfIssue")}
          value={formattedDate()}
          onChange={(e) =>
            onChange(
              e.target.value,
              "establishedDate",
              MemberSectionType.memberCard
            )
          }
        />
      </Grid>
    </Grid>
  );
};
