import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../../app/theme/colors";

export const useMenageMmeberButtonStyles = makeStyles()((theme: Theme) => ({

    saveButton: {
        textTransform: "none",
        backgroundColor: "#fff",
        borderRadius: "5px",
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
        float: "right",
        color: colors.green,
        marginBottom: "5px",
        fontWeight: "bold",
        "&:hover": {
            background: colors.green,
            color: "white",
        },
        "&:disabled": {
            boxShadow: "none",
        },
    },
    updateButton: {
        textTransform: "none",
        backgroundColor: "#fff",
        borderRadius: "5px",
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
        float: "right",
        color: colors.lightBlue,
        marginBottom: "5px",
        fontWeight: "bold",
        "&:hover": {
            background: colors.lightBlue,
            color: "#fff",
        },
        "&:disabled": {
            boxShadow: "none",
        },
    },
    deleteButton: {
        textTransform: "none",
        backgroundColor: "#fff",
        borderRadius: "5px",
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
        float: "right",
        color: colors.validationErrorMessage,
        marginBottom: "5px",
        "&:hover": {
            background: colors.validationErrorMessage,
            color: "white",
        },
        "&:disabled": {
            boxShadow: "none",
        },
    },
    clearButton: {
        textTransform: "none",
        backgroundColor: "#fff",
        borderRadius: "5px",
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
        float: "right",
        color: colors.primary,
        marginBottom: "5px",
        "&:hover": {
            background: colors.primary,
            color: "white",
        },
        "&:disabled": {
            boxShadow: "none",
        },
    },
    gridContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    divider: {
        width: '100%',
        height: '1px',
        backgroundColor: '#ccc',
        margin: '10px 10px',
        alignContent: 'center'
    }
}));
