import { PictureAsPdf } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../../../app/theme/colors";
import AppButton from "../../../../component/button/AppButton";
import BasicInput from "../../../../component/input/basic-input/BasicInput";
import { useTableModalStyles } from "../../../table/modal/styles/tableModalStyles";

type Props = {
  isOpen: boolean;
  onClose: () => any;
  reportHeaderLabel: string;
  setReportHeaderLabel: Dispatch<SetStateAction<string>>;
  onSubmit: () => any;
};

const SetReportHeaderTitleModal = (props: Props) => {
  const { t } = useTranslation();
  const { classes } = useTableModalStyles();

  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={props.onClose}
        scroll="paper"
        fullWidth={true}
      >
        <DialogTitle onClick={props.onClose} className={classes.dialogTitle}>
          <h3 className={classes.title}>{t("reportHeaderTitleModal.title")}</h3>
        </DialogTitle>

        <DialogContent style={{ marginTop: "1rem", marginBottom: 0 }}>
          <BasicInput
            type="text"
            label={t("reportHeaderTitleModal.titleInReport")}
            value={props.reportHeaderLabel}
            onChange={(e) => props.setReportHeaderLabel(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Grid container justifyContent={"end"} spacing={1}>
            <Grid item>
              <AppButton
                label={t("reportHeaderTitleModal.closeModal")}
                onClick={props.onClose}
                color={colors.sectionTitle}
              />
            </Grid>

            <Grid item>
              <AppButton
                label={t("reportHeaderTitleModal.getTheReport")}
                color={colors.green}
                onClick={props.onSubmit}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SetReportHeaderTitleModal;
