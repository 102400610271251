import { Theme } from "@mui/material";
import { color } from "d3-color";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../../app/theme/colors";

export const useTableStyles = makeStyles()((theme: Theme) => ({
  tableCell: {
    background: "rgba(47, 68, 84, 0.5)",
    color: "white",
    textAlign: "center",
    marginLeft: "5px",
  },
  selectionOfTableColumnsButton: {
    marginTop: "5px",
    marginRight: "5px",
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    float: "right",
    color: colors.primary,
    marginBottom: "5px",
    "&:hover": {
      background: colors.primary,
      color: "white",
    },
  },
  circularProgress: {
    color: colors.primary,
    display: "block",
    margin: "auto",
  },
  circularProgressWrapper: {
    padding: "20px",
  },
  select: {
    outline: "none",
    border: "none",
    width: "40px",
    cursor: "pointer",
    "&:hover": {
      background: "lightGray",
    },
  },
  sortIcon: {
    marginLeft: "5px",
  },
  pagination: {
    marginBottom: "5px",
  },
  filterAltIcon: {
    color: colors.primary,
  },
}));
