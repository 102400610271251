import { APIResponse } from "../../models/types/api/APIResponse";
import { api } from "../../app/redux/api";
import { LoginRequest } from "../../models/requests/auth/loginRequest";
import { RefreshTokenRequest } from "../../models/requests/auth/refreshTokenRequest";
import { LoginResponse } from "../../models/Responses/accounts/loginResponse";
import { User } from "../../models/data/accounts/user";
import { ResetPasswordRequest } from "../../models/requests/auth/resetPasswordRequest";
import { Account } from "../../models/auth/account";

const login = async (
  request: LoginRequest
): Promise<APIResponse<LoginResponse>> => {
  return api.post("auth/login", request);
};

const refresh = async (
  request: RefreshTokenRequest
): Promise<APIResponse<LoginResponse>> => {
  return api.post("auth/refresh-token", request);
};

const getUserByEmail = async (email: string): Promise<APIResponse<User>> => {
  return api.get(`accounts/${email}`);
};

const resetPassword = async (
  request: ResetPasswordRequest
): Promise<APIResponse<Account>> => {
  return api.post("auth/reset-password", request);
};

export const AuthApi = {
  Login: login,
  RefreshToken: refresh,
  GetUserByEmail: getUserByEmail,
  ResetPassword: resetPassword,
};
