import { makeStyles } from "tss-react/mui";
import { colors } from "../../../app/theme/colors";

export const useUserManagementSaveModalStyles = makeStyles()(() => ({
  title: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  textCenter: { textAlign: "center" },
  closeBtnWrapper: { textAlign: "right", marginTop: "10px" },
  closeBtn: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    color: colors.primary,
    "&:hover": {
      background: colors.primary,
      color: "white",
    },
  },
  saveBtn: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    color: colors.green,
    "&:hover": {
      background: colors.green,
      color: "white",
    },
  },
  autocompleteTextField: {
    "& .MuiInputBase-input": {
      marginTop: "-0.2rem",
    },

    "& .MuiInputBase-root": {
      borderRadius: "5px",
      height: "2rem",
    },
    backgroundColor: "white",
  },
  inputTitle: { color: colors.inputLabel, textAlign: "start" },
  circularProgressIcon: {
    color: colors.primary,
  },
  dialogTitle: {
    cursor: "pointer",
    background: colors.primary,
  },
}));
