import { makeStyles } from "tss-react/mui";
import { colors } from "../../../app/theme/colors";
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';

export const useReportsButtonsStyle = makeStyles()(() => ({
    pdfButton: {
        textTransform: "none",
        backgroundColor: "#fff",
        borderRadius: "5px",
        backgroundImage:"",
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
        float: "right",
        color: colors.green,
        marginBottom: "5px",
        fontWeight: "bold",
        "&:hover": {
            background: colors.green,
            color: "white",
        },
        "&:disabled": {
            boxShadow: "none",
        },
    },
    icon: {
        width: '4em',
        height: 'auto',
        fill: colors.primary,
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
        
        "&:hover": {
            background: colors.primary,
            fill: "white",

        }
    },
    divider: {
        width: '100%',
        height: '1px',
        backgroundColor: '#ccc',
        margin: '10px 10px',
        alignContent: 'center'
    }
}));
