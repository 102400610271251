import { Grid, Pagination } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { colors } from "../../../app/theme/colors";
import { useTableStyles } from "../tableComponent/useTableStyles";

type Props = {
  totalCountMembers: number;
  page: number;
  handleChangePage: any;
};

const TablePagination = (props: Props) => {
  const { classes } = useTableStyles()
  const theme = createTheme({
    palette: {
      primary: {
        main: colors.primary,
      },
    },
  });

  return (
    <Grid md={12} xs={12}>
      <ThemeProvider theme={theme}>
        <Pagination
          className={classes.pagination}
          count={props.totalCountMembers}
          page={props.page}
          onChange={props.handleChangePage}
          variant="outlined"
          color="primary"
          showFirstButton
          showLastButton
        />
      </ThemeProvider>
    </Grid>
  );
};

export default TablePagination;
