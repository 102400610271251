import {
  FormControl,
  FormHelperText,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { ChangeEvent } from "react";
import { useBasicInputStyles } from "./basicInputStyles";
type Props = {
  label?: any;
  value?: any;
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  type?: string;
  error?: boolean;
  errorMessage?: any;
  disabled?: boolean;
  inputComponent?: any;
  name?: string;
  endAdornment?: any;
  id?: string;
  autofocus?: boolean;
  helperText?: any;
  height?: any;
  width?: string;
  readOnly?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => any;
};
const BasicInput = (props: Props) => {
  const { classes } = useBasicInputStyles();
  return (
    <FormControl
      variant="outlined"
      className={
        props.error === true ? classes.formControlError : classes.formControl
      }
      fullWidth
    >
      {" "}
      <Typography variant="body1" className={classes.inputTitle}>
        {" "}
        {props.label}
        {props.error !== undefined && (
          <span className={props.error ? classes.requiredSymbolError : ""}>
            {" "}
            *{" "}
          </span>
        )}
        :
      </Typography>{" "}
      <OutlinedInput
        autoFocus={props.autofocus}
        id={props.id}
        autoComplete="new-password"
        disabled={props.disabled}
        fullWidth
        type={props.type ? props.type : "text"}
        value={props.value}
        onChange={props.onChange}
        className={classes.input}
        style={{
          background: props.disabled ? "lightgrey" : "white",
          height: props.height,
          width: props.width,
        }}
        inputComponent={props.inputComponent}
        name={props.name}
        endAdornment={props.endAdornment}
        readOnly={props.readOnly}
        onKeyDown={props.onKeyDown}
      />{" "}
      <FormHelperText>{props.helperText}</FormHelperText>{" "}
      {props.error === true && (
        <span className={classes.errorMessage}>{props.errorMessage}</span>
      )}
    </FormControl>
  );
};
export default BasicInput;
