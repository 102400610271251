import { Add, Clear } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import BasicInput from "../../../component/input/basic-input/BasicInput";
import { INIT_LANGUAGE } from "../../../INITIAL_DATA/initialData";
import { Member } from "../../../models/data/members/Member";
import { MemberLanguage } from "../../../models/data/members/MemberLanguage";

type Props = {
  member: Member;
  setMember: Dispatch<SetStateAction<Member>>;
};

export default function LanguageSection(props: Props) {
  console.log("languages: ", props.member.languages);

  const { t } = useTranslation();

  const onChangeValue = (
    value: string | boolean,
    name: keyof MemberLanguage,
    index: number
  ) => {
    if (!props?.member?.languages) {
      return;
    }

    const updatedLanguages = [...props.member.languages].map((language, i) => {
      if (i === index) {
        return { ...language, [name]: value };
      }

      return language;
    });

    props.setMember({ ...props.member, languages: [...updatedLanguages] });
  };

  const onAddEmptyLanguage = () => {
    props.setMember({
      ...props.member,
      languages: [...(props.member.languages ?? []), { ...INIT_LANGUAGE }],
    });
  };

  const onRemoveLanguage = (index: number) => {
    const updatedLanguages = [...props.member.languages].filter(
      (language, i) => i !== index
    );

    props.setMember({ ...props.member, languages: [...updatedLanguages] });
  };

  // Can add language when:
  // - last is not empty
  // - we have no language in array at all
  const canAddLanguage = (): boolean => {
    const languages = props.member.languages;

    if (!languages || languages.length === 0) {
      return true;
    }

    const lastLanguage = languages[languages.length - 1];

    if (lastLanguage.language || lastLanguage.level) {
      return true;
    }

    return false;
  };

  return (
    <>
      {props.member.languages?.map((language, i) => (
        <Grid container spacing={3} mb={3} key={i}>
          <Grid item md={4}>
            <BasicInput
              value={language.language}
              onChange={(e) => onChangeValue(e.target.value, "language", i)}
              label={t("memberTablePage.tableLanguageSubtitleLanguage")}
            />
          </Grid>

          <Grid item md={4}>
            <BasicInput
              value={language.level}
              onChange={(e) => onChangeValue(e.target.value, "level", i)}
              label={t("memberTablePage.tableLanguageSubtitleLevel")}
            />
          </Grid>

          <Grid
            item
            md={3}
            display={"flex"}
            justifyContent={"center"}
            alignSelf={"end"}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={language.activeSpeaking === true}
                  onChange={(e) =>
                    onChangeValue(e.target.checked, "activeSpeaking", i)
                  }
                  name="activeSpeaking"
                  color="primary"
                />
              }
              label={t("memberTablePage.tableLanguageSubtitleActive")}
            />
          </Grid>

          <Grid
            item
            md={1}
            display={"flex"}
            justifyContent={"center"}
            alignSelf={"end"}
          >
            <Button
              variant="text"
              color="error"
              onClick={() => onRemoveLanguage(i)}
            >
              <Clear />
            </Button>
          </Grid>
        </Grid>
      ))}

      {canAddLanguage() && (
        <Button variant="text" onClick={onAddEmptyLanguage}>
          <Add />
        </Button>
      )}
    </>
  );
}
