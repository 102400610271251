import { colors } from "../../../app/theme/colors";
import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useEducationSectionStyles = makeStyles()((theme: Theme) => ({
  card: {
    boxShadow: "0 0 10px rgb(0 0 0 / 0.3)",
    borderRadius: "5px",
  },
  sectionTitle: {
    textAlign: "start",
    color: colors.sectionTitle,
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: "1rem",
  },
  inputContainer: {
    marginBottom: "1rem",
  },
  inputTitle: { color: colors.inputLabel, textAlign: "start" },
  radioButton: {
    marginLeft: "15px",
    "&.MuiRadio-root": {
      color: colors.primary,
    },
    "&.Mui-checked": {
      color: colors.primary,
    },
  },
  radioButtonTitle: {
    color: colors.inputLabel,
  },
  input: {
    borderRadius: "5px",
    height: "2rem",
    marginRight: "5px",
    backgroundColor: "white"
  },
  formControl: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: colors.sectionTitle,
      },
    },
  },
}));
