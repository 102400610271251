import { Member } from "../models/data/members/Member";
import { MemberAddress } from "../models/data/members/MemberAddress";
import { MemberCard } from "../models/data/members/MemberCard";
import { MemberContact } from "..//models/data/members/MemberContact";
import { MemberEducation } from "../models/data/members/MemberEducation";
import { MemberEmployment } from "../models/data/members/MemberEmployment";
import { MemberLanguage } from "../models/data/members/MemberLanguage";
import { MemberOtherData } from "../models/data/members/MemberOtherData";
import { MemberPersonalData } from "../models/data/members/MemberPersonalData";

const defaultId = "00000000-0000-0000-0000-000000000000";
export const EmptyGuid = "00000000-0000-0000-0000-000000000000";

export const INIT_PERSONAL_DATA: MemberPersonalData = {
  id: defaultId,
  firstName: "",
  lastName: "",
  parentName: "",
  jmbg: "",
  birthDate: "",
  birthPlace: "",
  idNumber: "",
  gender: "",
};

export const INIT_ADDRESS: MemberAddress = {
  id: defaultId,
  streetId: null,
  streetName: "",
  cityId: null,
  cityName: "",
  homeNumber: "",
  streetResidenceId: null,
  streetResidenceName: "",
  cityResidenceId: null,
  cityResidenceName: "",
  homeNumberResidence: "",
  electionPlaceId: null,
  electionPlaceName: "",
  electionPlaceAddress: "",
  isAddressSame: true,
  memberId: null,
};

export const INIT_CONTACT: MemberContact = {
  id: defaultId,
  email: "",
  facebook: "",
  instagram: "",
  landPhone: "",
  memberId: null,
  mobilePhone: "",
  other: "",
  twiter: "",
  workPhone: "",
};

export const INIT_CARD: MemberCard = {
  id: defaultId,
  establishedDate: `${new Date().getUTCFullYear().toString()}-${('0'+((new Date().getUTCMonth() as number)+1).toString()).slice(-2)}-${new Date().getUTCDate().toString().slice(-2)}T00:00:00`,
  isPublished: false,
  number: 0,
  publishingCity: "",
  memberId: null,
};

export const INIT_EMPLOYMENT: MemberEmployment = {
  id: defaultId,
  company: "",
  isEmployed: null,
  other: "",
  permanentEmployed: false,
  workPlace: "",
  memberId: null,
};

export const INIT_EDUCATION: MemberEducation = {
  id: defaultId,
  highSchool: "",
  academicStudies: "",
  academicStudiesYear: 0,
  basicVocationalStudies: "",
  doctoralAcademicStudies: "",
  elementarySchool: "",
  highSchoolYear: 0,
  masterAcademicStudies: "",
  masterAcademicStudiesYear: 0,
  specialAcademicStudies: "",
  specialVocationalStudies: "",
  workOnComputer: null,
  memberId: null,
};

export const INIT_LANGUAGE: MemberLanguage = {
  id: defaultId,
  language: '',
  level: '',
  activeSpeaking: false,
  memberId: null,
};

export const INIT_OTHER_DATA: MemberOtherData = {
  id: defaultId,
  hobby: "",
  saintDay: "",
  saintDayDate: null,
  note1: "",
  note2: "",
  note3: "",
  recommendation: "",
  memberId: null,
};

export const INIT_MEMBER: Member = {
  personalData: INIT_PERSONAL_DATA,
  address: INIT_ADDRESS,
  card: INIT_CARD,
  contact: INIT_CONTACT,
  education: INIT_EDUCATION,
  employment: INIT_EMPLOYMENT,
  languages: [INIT_LANGUAGE],
  otherData: INIT_OTHER_DATA
};