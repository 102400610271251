import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../../app/theme/colors";

export const useMyProfileModalStyles = makeStyles()((theme: Theme) => ({
  paper: {
    borderRadius: "5px",
    padding: "20px",
  },
  typographyText_label: {
    color: "#5a5a5a",
  },
  typographyText_content: {
    color: "#5a5a5a",
    [theme.breakpoints.up("xs")]: {
      marginLeft: "5px",
    },
  },
  title: {
    textAlign: "center",
    color: "#5a5a5a",
    textTransform: "uppercase",
  },
  btn: {
    fontSize: "12px",
    border: "1px solid lightGrey",
    marginTop: "10px",
    borderRadius: "5px",
    height: "20px",
    padding: "13px",
    textAlign: "center",
    color: "#5a5a5a",
  },
  btnCloseModal: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    border: "1px solid #2f4454",
    color: colors.primary,
    marginBottom: "5px",
    width: "40%",
    "&:hover": {
      background: colors.primary,
      color: "white",
    },
  },
  typographyText: {
    color: colors.inputLabel,
    fontWeight: "bold",
    border: "none",
    background: "transparent",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  dialogTitle: {
    cursor: "pointer",
    background: colors.primary,
  },
  grid: {
    position: "sticky",
    background: colors.primary,
    textAlign: "end",
    border: "none",
    outline: "none",
    color: "white",
  },
}));
